import React from "react";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Person2OutlinedIcon from "@mui/icons-material/PersonOutline";
import ApartmentOutlinedIcon from "@mui/icons-material/ApartmentOutlined";
import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined";
import { Skeleton } from "@mui/material";

const ProfileDetail = ({ data, departmentList, dataLoading }) => {
  const DataofAnalytics = [];
  // const DataofAnalytics = [
  //   {
  //     id: 4,
  //     icon: <ApartmentOutlinedIcon />,
  //     title: "Organization",
  //     value: data?.organization || "-",
  //     AvatarBg: "#d1d9eb",
  //   },
  // ];

  if (data?.organization) {
    DataofAnalytics.push({
      id: 4,
      icon: <ApartmentOutlinedIcon />,
      title: "Organization",
      value: data?.organization || "-",
      AvatarBg: "#d1d9eb",
    });
  }

  if (data?.organization_url) {
    DataofAnalytics.push({
      id: 1,
      icon: <LanguageOutlinedIcon />,
      title: "Website",
      value: data?.organization_url || "-",
      AvatarBg: "#f6d2cf",
      isWebsite: true,
    });
  }
  if (data?.role) {
    DataofAnalytics.push({
      id: 2,
      icon: <Person2OutlinedIcon />,
      title: "Role",
      value: data.role,
      AvatarBg: "#e9c6d5",
    });
  }

  if (departmentList && departmentList?.length > 0) {
    DataofAnalytics.push({
      id: 3,
      icon: <ApartmentOutlinedIcon />,
      title: "Department",
      value: departmentList[0]?.category || "-",
      AvatarBg: "#fbe6c5",
    });
  }

  return (
    <Box>
      {DataofAnalytics.map((data) => (
        <Box className="analytics-bar" sx={{ border: 0 }} key={data.id}>
          <Box className="d-md-flex justify-content-between align-items-center">
            <Box className="d-flex align-items-center">
              <Avatar
                sx={{
                  width: 40,
                  height: 40,
                  background: data.AvatarBg,
                }}
                className="avatar"
              >
                {data.icon}
              </Avatar>
              {dataLoading ? (
                <Skeleton variant="text" width={100} height={30} />
              ) : (
                <>
                  <h6 className="title mb-0">{data.title}</h6>
                </>
              )}
            </Box>
            <span>
              {/* {dataLoading ? (
                <Skeleton variant="text" width={100} height={30} />
              ) : (
                <>
                  <h6 className="value" style={{ fontSize: "18px" }}>
                    {data.value}
                  </h6>
                </>
              )} */}
              {dataLoading ? (
                <Skeleton variant="text" width={100} height={30} />
              ) : data?.isWebsite ? (
                <h6 className="value">
                  {/* <a href={data.value} target="_blank" rel="noreferrer">
                    {data.value}
                  </a> */}

                  <a
                    href={
                      data?.value.startsWith("http")
                        ? data?.value
                        : `http://${data?.value}`
                    }
                    target="_blank"
                    rel="noreferrer"
                  >
                    {data?.value}
                  </a>
                </h6>
              ) : (
                <h6 className="value" style={{ fontSize: "18px" }}>
                  {data.value}
                </h6>
              )}
            </span>
          </Box>
        </Box>
      ))}
      {/* Additional content if needed */}
    </Box>
  );
};

export default ProfileDetail;
