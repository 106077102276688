import React, { useState } from "react";
import {
  Box,
  Checkbox,
  Chip,
  FormControlLabel,
  FormGroup,
  List,
  ListItem,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
} from "@mui/material";
import {
  BrowserIcon,
  PadelockCheckIcon,
  SensorAlertIcon,
  ShieldKeyholeIcon,
  SiteIcon,
} from "../../../Components/Common/SvgIcons";
import { Card, Col, Row } from "react-bootstrap";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import SimpleBar from "simplebar-react";
import common from "../../../Components/Common/common";

const CONSTANT = {
  SummaryCardOneComponent: ({ summaryData, dataLoading }) => {
    const SummaryCardDataOne = [
      {
        id: 1,
        title: "MALWARE",
        subtitle: "Software Security Test",
        icon: <SensorAlertIcon />,
        issue: (
          <>
            {dataLoading ? (
              <>
                <Box className="d-flex justify-content-center align-items-center">
                  <Skeleton variant="rectangular" width={100} height={10} />
                </Box>
              </>
            ) : summaryData?.malware?.description != null ? (
              `${summaryData.malware?.description}`
            ) : (
              "-"
            )}
          </>
        ),
        topBgColor: summaryData?.malware?.class
          ? `top-${summaryData.malware.class}`
          : "top-green",
        bottomBgColor: summaryData?.malware?.class
          ? `bottom-${summaryData.malware.class}`
          : "bottom-green",
      },
      {
        id: 2,
        title: "EU GDPR",
        subtitle: "Compliance Test",
        icon: <PadelockCheckIcon />,
        issue: (
          <>
            {dataLoading ? (
              <Box className="d-flex justify-content-center align-items-center">
                <Skeleton variant="rectangular" width={100} height={10} />
              </Box>
            ) : summaryData?.eu_gdpr?.description != null ? (
              `${summaryData?.eu_gdpr?.description}`
            ) : (
              "-"
            )}
          </>
        ),
        topBgColor: summaryData?.eu_gdpr?.class
          ? `top-${summaryData?.eu_gdpr?.class}`
          : "top-green",
        bottomBgColor: summaryData?.eu_gdpr?.class
          ? `bottom-${summaryData?.eu_gdpr?.class}`
          : "bottom-green",
      },
    ];

    const renderSummaryCardOne = SummaryCardDataOne?.map((data) => {
      return (
        <Box key={data.id} className="col-12 col-md-6 col-lg-6 col-xxxl-6">
          <Box className="summary-card">
            <Box className={`body-top ${data.topBgColor}`}>
              <div className="title">{data.title}</div>
              <div className="subtitle">{data.subtitle}</div>
            </Box>
            <div className={`body-bottom ${data.bottomBgColor}`}>
              {data.issue}
            </div>
            <Box className={`icon-box ${data.bottomBgColor}`}>{data.icon}</Box>
          </Box>
        </Box>
      );
    });

    return <>{renderSummaryCardOne}</>;
  },

  SummaryCardTwoComponent: ({ summaryData, dataLoading }) => {
    const SummaryCardDataTwo = [
      {
        id: 1,
        title: "PCI DSS",
        subtitle: "Compliance Test",
        icon: <BrowserIcon />,
        issue: (
          <>
            {dataLoading ? (
              <Box className="d-flex justify-content-center align-items-center">
                <Skeleton variant="rectangular" width={100} height={10} />
              </Box>
            ) : summaryData?.pci_dss?.description !== undefined ? (
              `${summaryData?.pci_dss?.description}`
            ) : (
              "-"
            )}
          </>
        ),
        topBgColor: summaryData?.pci_dss?.class
          ? `top-${summaryData?.pci_dss?.class}`
          : "top-orange",
        bottomBgColor: summaryData?.pci_dss?.class
          ? `bottom-${summaryData?.pci_dss?.class}`
          : "bottom-orange",
      },
      {
        id: 2,
        title: "SECURITY",
        subtitle: "Software Security Test",
        icon: <ShieldKeyholeIcon />,
        issue: (
          <>
            {dataLoading ? (
              <Box className="d-flex justify-content-center align-items-center">
                <Skeleton variant="rectangular" width={100} height={10} />
              </Box>
            ) : summaryData?.security?.description !== undefined ? (
              `${summaryData?.security?.description}`
            ) : (
              "-"
            )}
          </>
        ),
        topBgColor: summaryData?.security?.class
          ? `top-${summaryData?.security?.class}`
          : "top-green",
        bottomBgColor: summaryData?.security?.class
          ? `bottom-${summaryData?.security?.class}`
          : "bottom-green",
      },
      {
        id: 3,
        title: "HTTP",
        subtitle: "Header Security Test",
        icon: <SiteIcon />,
        issue: (
          <>
            {dataLoading ? (
              <Box className="d-flex justify-content-center align-items-center">
                <Skeleton variant="rectangular" width={100} height={10} />
              </Box>
            ) : summaryData?.http?.description !== undefined ? (
              `${summaryData?.http?.description}`
            ) : (
              "-"
            )}
          </>
        ),
        topBgColor: summaryData?.http?.class
          ? `top-${summaryData?.http?.class}`
          : "top-orange",
        bottomBgColor: summaryData?.http?.class
          ? `bottom-${summaryData?.http?.class}`
          : "bottom-orange",
      },
    ];

    const renderSummaryCardTwo = SummaryCardDataTwo?.map((data) => {
      return (
        <Box key={data.id} className="col-12 col-md-6 col-lg-4 col-xxxl-4">
          <Box className="summary-card">
            <Box className={`body-top ${data.topBgColor}`}>
              <div>
                <h5 className="title">{data.title}</h5>
              </div>
              <div className="subtitle">{data.subtitle}</div>
            </Box>
            <div className={`body-bottom ${data.bottomBgColor}`}>
              {data.issue}
            </div>
            <Box className={`icon-box ${data.bottomBgColor}`}>{data.icon}</Box>
          </Box>
        </Box>
      );
    });
    return <>{renderSummaryCardTwo}</>;
  },

  SecurityTestCardComponent: ({ webServerSecurityTest }) => {
    const renderListItem = (number, label, linkText) => (
      <ListItem
        key={number}
        sx={{
          "&.MuiListItem-root": {
            padding: 0,
          },
        }}
      >
        <Box
          key={number}
          sx={{
            background: "#CED4DA",
            color: "#1A2232",
            borderRadius: "15px",
            padding: "4px 14px",
            fontSize: "15px",
            marginRight: "5px",
          }}
        >
          {number}.
        </Box>
        <Chip
          label={label}
          variant="outlined"
          component="a"
          to={linkText}
          clickable
          sx={{
            "&.MuiChip-root": {
              background: "transparent",
              borderColor: "#CED4DA",
            },
          }}
        />
      </ListItem>
    );

    return (
      <Card className="security-card main-card">
        <h2 className="card-title">Web Server Security Test</h2>

        <Row className="g-4">
          {webServerSecurityTest &&
            webServerSecurityTest.http_response !== null && (
              <Col xs={12} md={6} xl={4} xxl={3}>
                <h6 className="label">HTTP RESPONSE</h6>
                <span className="value">
                  {webServerSecurityTest.http_response}
                </span>
              </Col>
            )}

          {webServerSecurityTest?.http_versions?.length > 0 ? (
            <Col xs={12} md={6} xl={4} xxl={3}>
              <h6 className="label">HTTP VERSIONS</h6>
              <span>
                {webServerSecurityTest?.http_versions?.map((version, index) => (
                  <Chip key={index} label={version} />
                ))}
              </span>
            </Col>
          ) : (
            " "
          )}

          {webServerSecurityTest?.npn?.length > 0 ? (
            <Col xs={12} md={6} xl={4} xxl={3}>
              <h6 className="label">
                NPN{" "}
                <Tooltip
                  title={"Next Protocol Negotiation"}
                  placement="top-start"
                  arrow
                >
                  <InfoRoundedIcon />
                </Tooltip>
              </h6>
              <span>
                {webServerSecurityTest?.npn?.map((version, index) => (
                  <Chip key={index} label={version} />
                ))}
              </span>
            </Col>
          ) : (
            " "
          )}

          {webServerSecurityTest?.alpn ? (
            <Col xs={12} md={6} xl={4} xxl={3}>
              <h6 className="label">
                ALPN{" "}
                <Tooltip
                  title={"Application Level Protocol Negotiation"}
                  placement="top-start"
                  arrow
                >
                  <InfoRoundedIcon />
                </Tooltip>
              </h6>
              <span>
                <Chip label={webServerSecurityTest?.alpn} />
              </span>
            </Col>
          ) : (
            " "
          )}

          {webServerSecurityTest?.content_encoding?.length > 0 ? (
            <Col xs={12} md={6} xl={4} xxl={3}>
              <h6 className="label">CONTENT ENCODING</h6>
              <span>
                {webServerSecurityTest &&
                webServerSecurityTest?.content_encoding !== undefined
                  ? `${webServerSecurityTest?.content_encoding}`
                  : "-"}
              </span>
            </Col>
          ) : (
            " "
          )}

          {webServerSecurityTest &&
            webServerSecurityTest?.server_signature !== null &&
            webServerSecurityTest?.server_signature > 0 && (
              <Col xs={12} md={6} xl={4} xxl={3}>
                <h6 className="label">SERVER SIGNATURE</h6>
                <span className="value">
                  {webServerSecurityTest.server_signature}
                </span>
              </Col>
            )}

          {webServerSecurityTest?.waf?.length > 0 ? (
            <Col xs={12} md={6} xl={4} xxl={3}>
              <h6 className="label">
                WAF{" "}
                <Tooltip
                  title={"Web Application Firewall"}
                  placement="top-start"
                  arrow
                >
                  <InfoRoundedIcon />
                </Tooltip>
              </h6>
              <span>
                {webServerSecurityTest && webServerSecurityTest?.waf !== null
                  ? `${webServerSecurityTest?.waf}`
                  : "-"}
              </span>
            </Col>
          ) : (
            " "
          )}
          {webServerSecurityTest?.location?.length > 0 ? (
            <Col xs={12} md={6} xl={4} xxl={3}>
              <h6 className="label">LOCATION</h6>
              <span>
                {webServerSecurityTest &&
                webServerSecurityTest?.location !== null
                  ? `${webServerSecurityTest?.location}`
                  : "-"}
              </span>
            </Col>
          ) : (
            " "
          )}

          {webServerSecurityTest?.http_methods?.length > 0 && (
            <Col xs={12} md={6}>
              <h6 className="label">HTTP METHODS ENABLED</h6>
              <span>
                <FormGroup row>
                  {webServerSecurityTest?.http_methods?.map((method, index) => (
                    <FormControlLabel
                      key={index}
                      control={
                        <Checkbox
                          defaultChecked={webServerSecurityTest?.http_methods?.includes(
                            method
                          )}
                          disabled
                        />
                      } // Check if method is in http_methods array
                      label={method}
                      sx={{
                        "&.MuiFormControlLabel-root": {
                          background:
                            method === "GET"
                              ? "#5CB85C"
                              : method === "POST"
                              ? "#F99F1E"
                              : "", // Adjust background color based on method
                        },
                        "& .MuiFormControlLabel-label": {
                          color: "#fff",
                        },
                      }}
                    />
                  ))}
                </FormGroup>
              </span>
            </Col>
          )}

          {webServerSecurityTest?.http_redirects?.length > 0 ? (
            <Col xs={12} md={6}>
              <h6 className="label">
                HTTP REDIRECTS{" "}
                <Tooltip
                  title={"The Original URL redirects to these web resources"}
                  placement="top-start"
                  arrow
                >
                  <InfoRoundedIcon />
                </Tooltip>
              </h6>
              <span className="redirect-box">
                <List>
                  {/* {webServerSecurityTest?.http_redirects?.map(
                    (redirect, index) => (
                      <ListItem key={index}>
                        {renderListItem(index + 1, redirect, redirect)}
                      </ListItem>
                    )
                  )} */}
                  {webServerSecurityTest.http_redirects?.map(
                    (redirect, index) =>
                      renderListItem(index + 1, redirect, redirect)
                  )}
                </List>
              </span>
            </Col>
          ) : (
            " "
          )}
        </Row>
      </Card>
    );
  },

  WebSoftwareSecurityTestComponent: ({ webSoftwareSecurityTest }) => {
    return (
      <Card className="main-card software-security">
        <h3 className="card-title">Web Software Security Test</h3>
        <Box>
          <h3 className="label-text">
            Fingerprinted CMS & Vulnerabilities
            <Tooltip
              title={
                "The name of CMS was fingerprinted using non-intrusive techniques and therefore are not guaranteed to be accurate."
              }
              placement="top-start"
              arrow
            >
              <InfoRoundedIcon />
            </Tooltip>
          </h3>
          {webSoftwareSecurityTest?.fingerprintedCms ? (
            <Box className="label-detail">
              <Chip
                label={webSoftwareSecurityTest?.fingerprintedCms}
                className="gray-chip"
              />
              <p className="description">
                {webSoftwareSecurityTest?.cmsVersionInfo !== undefined
                  ? `${webSoftwareSecurityTest?.cmsVersionInfo}`
                  : "-"}
              </p>
            </Box>
          ) : (
            <div>
              <p>Data not found</p>
            </div>
          )}
          <h3 className="label-text">
            Fingerprinted CMS Components & Vulnerabilities
            <Tooltip
              title={
                "The name of CMS was fingerprinted using non-intrusive techniques and therefore are not guaranteed to be accurate."
              }
              placement="top-start"
              arrow
            >
              <InfoRoundedIcon />
            </Tooltip>
          </h3>
          {webSoftwareSecurityTest?.fingerprintedCmsComponents?.length > 0 ? (
            webSoftwareSecurityTest?.fingerprintedCmsComponents?.map(
              (component, index) => (
                <Box key={index} className="label-detail">
                  <Box className="chip-box">
                    <span>{component.componentName}</span>
                    <span>{component.componentVersion}</span>
                  </Box>
                  <p className="description">
                    {component.componentStatus}{" "}
                    {component.componentVulnerabilities}{" "}
                    {component.componentUpdateInfo}
                    {/* <strong>{component.componentNewVersion}</strong>. */}
                  </p>
                </Box>
              )
            )
          ) : (
            <div>
              <p>Data not found</p>
            </div>
          )}
        </Box>
      </Card>
    );
  },

  GDPRComplianceTestComponent: ({ gdprComplianceTest }) => {
    const GDPRData = [
      {
        id: 1,
        title: "PRIVACY POLICY",
        description: "Privacy Policy was found on the website.",
        label: "Good configuration",
        status: "green-label",
        tooltip:
          "Article 13 of GDPR requires data controller to provide a conspicuously visible notice to data subjects when collecting their personal data including but not limited to data collected by web applications.",
      },
      {
        id: 2,
        title: "WEBSITE SECURITY",
        description:
          "No publicly known vulnerabilities were found in the website CMS or its components.",
        label: "Good configuration",
        status: "green-label",
        tooltip:
          "Article 5(1)(f), Article 24(1) and Article 32 of GDPR require implementation, testing and maintenance of adequate security controls to protect personal data. EDPB guidelines provide further technical details and examples including, among other things, maintenance of up2date web application software and regular website security testing.",
      },
      {
        id: 3,
        title: "TLS ENCRYPTION",
        description: "HTTPS encryption is present on the web server.",
        label: "Good configuration",
        status: "green-label",
        tooltip:
          "Article 32 of GDPR requires implementation of adequate protection of processed personal data including, among other things, encryption of personal data while at rest or in transit. This applies to web applications when personal data is being sent or retrieved via a browser or API.",
      },
      {
        id: 4,
        title: "COOKIE PROTECTION",
        description:
          "No cookies with personal or tracking information seem to be sent.",
        label: "Information",
        status: "blue-label",
        tooltip:
          "Article 32 of GDPR requires implementation of adequate protection of processed personal data including, among other things, encryption of personal data while at rest or in transit. This applies to cookies when they contain personal data or identifiers attributable to data subjects (see GDPR Recital 30).",
      },
      {
        id: 5,
        title: "COOKIE DISCLAIMER",
        description:
          "No third-party cookies or cookies with tracking information seem to be sent.",
        label: "Information",
        status: "blue-label",
        tooltip:
          "In addition to Article 13 of GDPR, the EU ePrivacy Directive requires website operators to obtain an informed data subject’s consent prior to setting any cookies except strictly necessary cookies.",
      },
    ];

    const updatedGDPRData = GDPRData.map((data) => {
      const testItem = gdprComplianceTest?.find(
        (item) => item.title === data.title
      );
      const status = testItem && testItem.check ? "green-label" : "blue-label";
      const label =
        testItem && testItem.check ? "Good configuration" : "Information";

      return {
        ...data,
        label,
        status,
        check: testItem ? testItem.check : null, // Add check value to data
      };
    });

    const renderGDPR = updatedGDPRData?.map((data) => (
      <Box key={data.id}>
        <h3 className="label-text">
          {data.title}{" "}
          <Tooltip title={data?.tooltip} placement="top-start" arrow>
            <InfoRoundedIcon />
          </Tooltip>
        </h3>
        <Box className={`label-detail ${data.status}`}>
          <p className="description-text">{data.description}</p>
          {data.check !== null && <Chip label={data.label} />}{" "}
          {/* Conditionally render Chip */}
        </Box>
      </Box>
    ));

    return (
      <Card className="main-card software-security compliance">
        <h3 className="card-title">GDPR Compliance Test</h3>
        <div>
          <p>
            If the website processes or stores personal data of the EU
            residents, the following requirements of EU GDPR may apply:
          </p>
        </div>
        <Box>
          {gdprComplianceTest && gdprComplianceTest?.length > 0 ? (
            renderGDPR
          ) : (
            <div>
              <p>Data not found</p>
            </div>
          )}
        </Box>
      </Card>
    );
  },

  // GDPRComplianceTestComponent: ({ gdprComplianceTest }) => {
  //   const GDPRData = [
  //     {
  //       id: 1,
  //       title: "PRIVACY POLICY",
  //       description: "Privacy Policy was found on the website.",
  //       label: "Good configuration",
  //       status: "green-label",
  //       tooltip:
  //         "Article 13 of GDPR requires data controller to provide a conspicuously visible notice to data subjects when collecting their personal data including but not limited to data collected by web applications.",
  //     },
  //     {
  //       id: 2,
  //       title: "WEBSITE SECURITY",
  //       description:
  //         "No publicly known vulnerabilities were found in the website CMS or its components.",
  //       label: "Good configuration",
  //       status: "green-label",
  //       tooltip:
  //         "Article 5(1)(f), Article 24(1) and Article 32 of GDPR require implementation, testing and maintenance of adequate security controls to protect personal data. EDPB guidelines provide further technical details and examples including, among other things, maintenance of up2date web application software and regular website security testing.",
  //     },
  //     {
  //       id: 3,
  //       title: "TLS ENCRYPTION",
  //       description: "HTTPS encryption is present on the web server.",
  //       label: "Good configuration",
  //       status: "green-label",
  //       tooltip:
  //         "Article 32 of GDPR requires implementation of adequate protection of processed personal data including, among other things, encryption of personal data while at rest or in transit. This applies to web applications when personal data is being sent or retrieved via a browser or API.",
  //     },
  //     {
  //       id: 4,
  //       title: "COOKIE PROTECTION",
  //       description:
  //         "No cookies with personal or tracking information seem to be sent.",
  //       label: "Information",
  //       status: "blue-label",
  //       tooltip:
  //         "Article 32 of GDPR requires implementation of adequate protection of processed personal data including, among other things, encryption of personal data while at rest or in transit. This applies to cookies when they contain personal data or identifiers attributable to data subjects (see GDPR Recital 30).",
  //     },
  //     {
  //       id: 5,
  //       title: "COOKIE DISCLAIMER",
  //       description:
  //         "No third-party cookies or cookies with tracking information seem to be sent.",
  //       label: "Information",
  //       status: "blue-label",
  //       tooltip:
  //         "In addition to Article 13 of GDPR, the EU ePrivacy Directive requires website operators to obtain an informed data subject’s consent prior to setting any cookies except strictly necessary cookies.",
  //     },
  //   ];
  //   const updatedGDPRData = GDPRData.map((data) => {
  //     const testItem = gdprComplianceTest?.find(
  //       (item) => item.title === data.title
  //     );
  //     const status = testItem && testItem.check ? "green-label" : "blue-label";
  //     const label =
  //       testItem && testItem.check ? "Good configuration" : "Information";

  //     return {
  //       ...data,
  //       label,
  //       status,
  //     };
  //   });

  //   // const updatedGDPRData = GDPRData?.map((data) => {
  //   //   const testItem = gdprComplianceTest?.find(
  //   //     (item) => item.title === data.title
  //   //   );
  //   //   let status, label;

  //   //   if (testItem) {
  //   //     if (testItem?.check === true) {
  //   //       status = "green-label";
  //   //       label = "Good configuration";
  //   //     } else if (testItem?.check === false) {
  //   //       status = "blue-label";
  //   //       label = "Information";
  //   //     } else if (testItem?.check === null) {
  //   //       status = "grey-label"; // or another appropriate label for null check
  //   //       label = "-";
  //   //     }
  //   //   } else {
  //   //     status = "blue-label";
  //   //     label = "Information";
  //   //   }

  //   //   return {
  //   //     ...data,
  //   //     label,
  //   //     status,
  //   //   };
  //   // });
  //   const renderGDPR = updatedGDPRData?.map((data) => (
  //     <Box key={data.id}>
  //       <h3 className="label-text">
  //         {data.title}{" "}
  //         <Tooltip title={data?.tooltip} placement="top-start" arrow>
  //           <InfoRoundedIcon />
  //         </Tooltip>
  //       </h3>
  //       <Box className={`label-detail ${data.status}`}>
  //         <p className="description-text">{data.description}</p>
  //         <Chip label={data.label} />
  //       </Box>
  //     </Box>
  //   ));

  //   return (
  //     <Card className="main-card software-security compliance">
  //       <h3 className="card-title">GDPR Compliance Test</h3>
  //       <p>
  //         If the website processes or stores personal data of the EU residents,
  //         the following requirements of EU GDPR may apply:
  //       </p>
  //       <Box>
  //         {gdprComplianceTest && gdprComplianceTest?.length > 0 ? (
  //           renderGDPR
  //         ) : (
  //           <p>Data not found</p>
  //         )}
  //       </Box>
  //     </Card>
  //   );
  // },

  PCIDSSComplianceTestComponent: ({ pcidssComplianceTest }) => {
    const updatedPCIDSSData = pcidssComplianceTest?.map((testItem, index) => {
      return {
        id: index + 1,
        title: testItem.title,
        description: testItem.msg,
        label:
          testItem.lvl === 1
            ? "Good configuration"
            : "Misconfiguration or weakness",
        status: testItem.lvl === 1 ? "green-label" : "orange-label",
        tooltip: testItem?.tooltip,
      };
    });

    const renderPCIDSS = updatedPCIDSSData?.map((data) => {
      return (
        <Box key={data.id}>
          <h3 className="label-text">
            {data.title}
            <Tooltip title={data?.tooltip} placement="top-start" arrow>
              <InfoRoundedIcon />
            </Tooltip>
          </h3>
          <Box className={`label-detail ${data.status}`}>
            <div className="description-text PCIDSS-text">
              {data?.description}
            </div>
            <Chip label={data.label} />
          </Box>
        </Box>
      );
    });

    return (
      <Card className="main-card software-security compliance">
        <h3 className="card-title">PCI DSS Compliance Test</h3>
        <span>
          <p>
            If the website falls into a CDE (Cardholder Data Environment) scope,
            the following Requirements of PCI DSS may apply:
          </p>
        </span>
        <Box>
          {updatedPCIDSSData && updatedPCIDSSData?.length > 0 ? (
            renderPCIDSS
          ) : (
            <div>
              <p>No Data Found</p>
            </div>
          )}
        </Box>
      </Card>
    );
  },

  ContentSecurityPolicyTestComponent: ({ contentSecurityPolicyTest }) => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(3);

    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };

    const PolicyDirectivesRows =
      contentSecurityPolicyTest?.content_security_policy?.directives?.map(
        (directive) => ({
          name: directive.name,
          description: directive?.description?.replace(/\s*\[\d+\]$/, ""),
          alerts: "No problems found", // Assuming the alerts value to be the same for all rows
        })
      ) || [];

    const PolicyDirectivesReportsRows =
      contentSecurityPolicyTest?.content_security_policy_report_only?.directives?.map(
        (directive) => ({
          name: directive.name,
          description: directive?.description?.replace(/\s*\[\d+\]$/, ""),
          alerts: "No problems found", // Assuming the alerts value to be the same for all rows
        })
      ) || [];

    const renderTableBody = (rows) => (
      <TableBody>
        {rows?.length > 0
          ? rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => (
                <TableRow
                  key={row.name}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell>{row.name}</TableCell>
                  <TableCell>{row?.description}</TableCell>
                  <TableCell
                  // sx={{ color: "#5CB85C" }}
                  >
                    {row.alerts}
                  </TableCell>
                </TableRow>
              ))
          : " "}
      </TableBody>
    );

    return (
      <Card className="main-card software-security compliance">
        <h3 className="card-title">Content Security Policy Test</h3>
        <SimpleBar style={{ maxHeight: "650px" }} autoHide={false}>
          <Box>
            <h3 className="label-text">
              CONTENT-SECURITY-POLICY
              {contentSecurityPolicyTest?.content_security_policy
                ?.description && (
                <Tooltip
                  title={common.decodeHTMLEntities(
                    contentSecurityPolicyTest?.content_security_policy
                      ?.description
                  )}
                  placement="top-start"
                  arrow
                >
                  <InfoRoundedIcon />
                </Tooltip>
              )}
            </h3>

            {contentSecurityPolicyTest?.content_security_policy?.highlight?.map(
              (item, index) => (
                <Box key={index} className="label-detail green-label">
                  <p className="description-text PCIDSS-text">
                    {item?.replace(/\s*\[\d+\]$/, "")}
                  </p>
                  <Chip label="Good configuration" />
                </Box>
              )
            )}
          </Box>
          {contentSecurityPolicyTest &&
            contentSecurityPolicyTest?.content_security_policy?.raw !==
              null && (
              <Box>
                <h3 className="label-text">Raw HTTP Header</h3>
                <p
                  className="header-label"
                  style={{
                    maxHeight: "100px",
                    overflow: "auto",
                    scrollbarWidth: "thin",
                  }}
                >
                  {contentSecurityPolicyTest?.content_security_policy?.raw !=
                  null
                    ? decodeURIComponent(
                        contentSecurityPolicyTest.content_security_policy?.raw
                      )
                    : "No Data Found"}
                </p>
              </Box>
            )}

          {PolicyDirectivesRows?.length > 0 && (
            <Box>
              <>
                <h3 className="label-text">Directives</h3>
                <TableContainer sx={{ marginBottom: "40px" }}>
                  <Table className="directives-table">
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ width: "120px" }}>Name</TableCell>
                        <TableCell sx={{ width: "350px" }}>
                          Description
                        </TableCell>
                        <TableCell>Alerts</TableCell>
                      </TableRow>
                    </TableHead>
                    {renderTableBody(PolicyDirectivesRows)}
                  </Table>
                  {PolicyDirectivesRows?.length > 5 && (
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 25]}
                      component="div"
                      count={PolicyDirectivesRows?.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  )}
                </TableContainer>
              </>
            </Box>
          )}

          <Box>
            <h3 className="label-text">
              CONTENT-SECURITY-POLICY-REPORT-ONLY
              {contentSecurityPolicyTest?.content_security_policy_report_only
                ?.description && (
                <Tooltip
                  title={common.decodeHTMLEntities(
                    contentSecurityPolicyTest
                      ?.content_security_policy_report_only?.description
                  )}
                  placement="top-start"
                  arrow
                >
                  <InfoRoundedIcon />
                </Tooltip>
              )}
            </h3>

            {contentSecurityPolicyTest?.content_security_policy_report_only?.highlight?.map(
              (item, index) => (
                <Box key={index} className="label-detail blue-label">
                  <p className="description-text PCIDSS-text">
                    {item?.replace(/\s*\[\d+\]$/, "")}
                  </p>
                  <Chip label="Information" />
                </Box>
              )
            )}
          </Box>

          {contentSecurityPolicyTest &&
            contentSecurityPolicyTest?.content_security_policy_report_only
              ?.raw !== null && (
              <Box>
                <h3 className="label-text">Raw HTTP Header</h3>
                <p className="header-label">
                  {contentSecurityPolicyTest
                    ?.content_security_policy_report_only?.raw != null
                    ? decodeURIComponent(
                        contentSecurityPolicyTest
                          .content_security_policy_report_only?.raw
                      )
                    : "No Data Found"}
                </p>
              </Box>
            )}

          {PolicyDirectivesReportsRows?.length > 0 ? (
            <Box>
              <h3 className="label-text">Directives</h3>
              <TableContainer sx={{ marginBottom: "40px" }}>
                <Table className="directives-table">
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ width: "120px" }}>Name</TableCell>
                      <TableCell sx={{ width: "350px" }}>Description</TableCell>
                      <TableCell>Alerts</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {PolicyDirectivesReportsRows?.map((row) => (
                      <TableRow
                        key={row.name}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell>{row.name}</TableCell>
                        <TableCell>
                          {row.description?.replace(/\s*\[\d+\]$/, "")}
                        </TableCell>
                        <TableCell>{row.alerts}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          ) : (
            " "
          )}
        </SimpleBar>
      </Card>
    );
  },

  CookiePrivacyAndSecurityTestComponent: ({ cookiesPrivacySecurityTest }) => {
    return (
      <Card className="main-card software-security compliance h-auto">
        <h3 className="card-title">Cookies Privacy and Security Analysis</h3>

        {cookiesPrivacySecurityTest?.cookieInfo?.highlight && (
          <Box
            className="label-detail green-label"
            sx={{ marginBottom: "15px" }}
          >
            <p className="description-text PCIDSS-text">
              {cookiesPrivacySecurityTest?.cookieInfo?.highlight?.replace(
                /\s*\[\d+\]$/,
                ""
              )}
            </p>
            <Chip label="Good configuration" />
          </Box>
        )}

        <SimpleBar style={{ maxHeight: "500px" }} autoHide={false}>
          {cookiesPrivacySecurityTest?.bodyInfo?.map((policy, index) => (
            <Box key={index}>
              <h3 className="label-text">
                Cookies {policy?.name}
                {policy?.description && (
                  <Tooltip
                    title={policy?.description}
                    placement="top-start"
                    arrow
                  >
                    <InfoRoundedIcon />
                  </Tooltip>
                )}
              </h3>

              {policy.highlight?.map((item, index) => (
                <Box key={index} className="label-detail green-label">
                  <p className="description-text PCIDSS-text">
                    {item?.replace(/\s*\[\d+\]$/, "")}
                  </p>
                  <Chip label="Good configuration" />
                </Box>
              ))}

              <Box>
                <h3 className="label-text">Raw HTTP Header</h3>
                <p
                  className="header-label"
                  style={{
                    maxHeight: "100px",
                    overflow: "auto",
                    scrollbarWidth: "thin",
                  }}
                >
                  {policy.raw !== undefined
                    ? decodeURIComponent(policy?.raw)
                    : "-"}
                </p>
              </Box>

              <Box>
                <h3 className="label-text">Directives</h3>
                <TableContainer sx={{ marginBottom: "40px" }}>
                  <Table className="directives-table">
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ width: "120px" }}>Name</TableCell>
                        <TableCell sx={{ width: "350px" }}>
                          Description
                        </TableCell>
                        <TableCell>Alerts</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {policy.directives?.map((directive, idx) => (
                        <TableRow
                          key={idx}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell>{directive?.name}</TableCell>
                          <TableCell>
                            {directive?.description?.replace(/\s*\[\d+\]$/, "")}
                          </TableCell>
                          <TableCell
                          // sx={{
                          //   color: "#5CB85C",
                          // }}
                          >
                            No problems found
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Box>
          ))}
        </SimpleBar>
      </Card>
    );
  },

  HTTPHeaderSecurityComponent: ({ httpHeaderSecurityData, MyListItem }) => {
    return (
      <Card className="main-card software-security compliance Header-security">
        <h3 className="card-title">HTTP Headers Security</h3>
        {httpHeaderSecurityData?.length > 0 && (
          <Box className="label-detail orange-label">
            <p className="description-text PCIDSS-text">
              {httpHeaderSecurityData?.headerInfo?.highlight !== null
                ? `${httpHeaderSecurityData?.headerInfo?.highlight?.replace(
                    /\s*\[\d+\]$/,
                    ""
                  )}`
                : "-"}
            </p>
            <Chip label="Misconfiguration or weakness" />
          </Box>
        )}
        <SimpleBar style={{ maxHeight: "600px" }} autoHide={false}>
          <Box>
            {httpHeaderSecurityData?.bodyInfo?.map((item) => {
              if (item.name === "MISSING REQUIRED HTTP HEADERS") {
                return (
                  <React.Fragment key={item.name}>
                    <h3 className="label-text">
                      MISSING REQUIRED HTTP HEADERS
                      <Tooltip
                        title={
                          "If missing, these HTTP security header may weaker your website and web server security."
                        }
                        placement="top-start"
                        arrow
                      >
                        <InfoRoundedIcon />
                      </Tooltip>
                    </h3>
                    <Box
                      sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        listStyle: "none",
                        p: 0.5,
                        m: 0,
                      }}
                      component="ul"
                      className="header-label green"
                    >
                      {item.value?.map((header, idx) => (
                        <MyListItem key={idx}>
                          <Tooltip title={header?.description} placement="top">
                            <Chip
                              label={header.name}
                              deleteIcon={<InfoRoundedIcon />}
                            />
                          </Tooltip>
                        </MyListItem>
                      ))}
                    </Box>
                  </React.Fragment>
                );
              }
              return null; // Render nothing if the condition isn't met
            })}
          </Box>
          <Box>
            {httpHeaderSecurityData?.bodyInfo?.map((item) => {
              if (item.name === "MISSING OPTIONAL HTTP HEADERS") {
                return (
                  <React.Fragment key={item.name}>
                    <h3 className="label-text">
                      MISSING OPTIONAL HTTP HEADERS
                      <Tooltip
                        title={
                          "Insignificant or otherwise non-obligatory HTTP security-related headers."
                        }
                        placement="top-start"
                        arrow
                      >
                        <InfoRoundedIcon />
                      </Tooltip>
                    </h3>
                    <Box
                      sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        listStyle: "none",
                        p: 0.5,
                        m: 0,
                      }}
                      component="ul"
                      className="header-label blue"
                    >
                      {item?.value?.map((header, idx) => (
                        <MyListItem key={idx}>
                          <Tooltip title={header?.description} placement="top">
                            <Chip
                              label={header.name}
                              deleteIcon={<InfoRoundedIcon />}
                            />
                          </Tooltip>
                        </MyListItem>
                      ))}
                    </Box>
                  </React.Fragment>
                );
              }
              return null; // Render nothing if the condition isn't met
            })}
          </Box>
          <Box>
            {httpHeaderSecurityData?.bodyInfo?.map((item) => {
              if (item.name === "MISSING OTHER HTTP HEADERS") {
                return (
                  <React.Fragment key={item.name}>
                    <h3 className="label-text">
                      MISSING OTHER HTTP HEADERS <InfoRoundedIcon />
                    </h3>
                    <Box
                      sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        listStyle: "none",
                        p: 0.5,
                        m: 0,
                      }}
                      component="ul"
                      className="header-label blue"
                    >
                      {item?.value?.map((header, idx) => (
                        <MyListItem key={idx}>
                          <Tooltip title={header?.description} placement="top">
                            <Chip
                              label={header.name}
                              deleteIcon={<InfoRoundedIcon />}
                            />
                          </Tooltip>
                        </MyListItem>
                      ))}
                    </Box>
                  </React.Fragment>
                );
              }
              return null; // Render nothing if the condition isn't met
            })}
          </Box>
          <Box>
            {httpHeaderSecurityData?.bodyInfo?.map((header, index) => {
              if (header.value) return null; // Skip if `value` array is present

              return (
                <Box key={index}>
                  <h3 className="label-text">
                    {header.name}
                    <Tooltip
                      title={header?.description}
                      placement="top-start"
                      arrow
                    >
                      <InfoRoundedIcon />
                    </Tooltip>
                  </h3>

                  {(header?.highlights?.length > 0 ||
                    header?.highlight?.length > 0) && (
                    <Box className="label-detail green-label">
                      <p className="description-text PCIDSS-text">
                        {header?.highlights?.length > 0
                          ? header?.highlights[0]?.replace(/\s*\[\d+\]$/, "")
                          : header?.highlight?.length > 0
                          ? header?.highlight[0]?.replace(/\s*\[\d+\]$/, "")
                          : ""}
                      </p>
                      <Chip label="Good configuration" />
                    </Box>
                  )}

                  {header?.raw && (
                    <Box>
                      <h3 className="label-text">{header?.name}</h3>
                      <p
                        className="header-label"
                        style={{
                          maxHeight: "100px",
                          overflow: "auto",
                          scrollbarWidth: "thin",
                        }}
                      >
                        {header?.raw}
                      </p>
                    </Box>
                  )}

                  {header?.directives?.length > 0 && (
                    <Box>
                      <h3 className="label-text">Directives</h3>
                      <TableContainer sx={{ marginBottom: "40px" }}>
                        <Table className="directives-table">
                          <TableHead>
                            <TableRow>
                              <TableCell sx={{ width: "120px" }}>
                                Name
                              </TableCell>
                              <TableCell sx={{ width: "350px" }}>
                                Description
                              </TableCell>
                              <TableCell>Alerts</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {header?.directives?.map((directive, idx) => (
                              <TableRow
                                key={idx}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell>{directive?.name}</TableCell>
                                <TableCell>
                                  {directive?.description?.replace(
                                    /\s*\[\d+\]$/,
                                    ""
                                  )}
                                </TableCell>
                                <TableCell
                                // sx={{
                                //   color: "#5CB85C",
                                // }}
                                >
                                  No problems found
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Box>
                  )}
                </Box>
              );
            })}
          </Box>
        </SimpleBar>
      </Card>
    );
  },
};

export default CONSTANT;
