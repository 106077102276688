import { Box, FormControl, MenuItem, Select, Skeleton } from "@mui/material";
import { Card } from "react-bootstrap";
import { Spinner } from "reactstrap";
import { DropdownStyle, MenuHeight } from "../../NewDashboard/constant";
import { Link } from "react-router-dom";
import { DeleteIcon } from "../../../Components/Common/SvgIcons";
import ProfileImage from "../../../Components/Common/ProfileImage";
import { useSelector } from "react-redux";
import { TICKETING_SYSTEM } from "../../../Components/Common/ModuleName";
import { toast } from "react-toastify";

const CONSTANTS = {
  TimeTrackingCard: ({
    data,
    dataLoading,
    timeDiff,
    updateSingleTicketStatus,
    isStopLoading,
  }) => {
    const permissionData = useSelector(
      (state) => state.userDetails.userPermission
    );
    const hasPermission = permissionData?.find(
      (item) => item.module_name === TICKETING_SYSTEM
    );

    return (
      <>
        <Card>
          <Card.Body>
            <h4 className="card-title mb-3">Time Tracking</h4>

            <Box className="mb-2">
              <lord-icon
                src="https://cdn.lordicon.com/kbtmbyzy.json"
                trigger={
                  data.status === "closed" && data.close_time ? "" : "loop"
                }
                colors="primary:#25a0e2,secondary:#00bd9d"
                style={{ width: "90px", height: "90px" }}
              ></lord-icon>
            </Box>
            {dataLoading ? (
              <>
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  className="mb-3"
                >
                  <Skeleton variant="text" width={280} height={30} />
                </Box>
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  className="mb-3"
                >
                  <Skeleton variant="text" width={100} height={30} />
                </Box>
              </>
            ) : (
              <>
                <h5 className="mb-2">{timeDiff}</h5>
                <p className="mb-4">{data.title}</p>
              </>
            )}

            <Box className="hstack gap-2 justify-content-center">
              <button
                className="btn btn-primary btn-sm"
                disabled={
                  !hasPermission?.canModify ||
                  (data.status === "closed" && data.close_time)
                }
                onClick={() => updateSingleTicketStatus("closed")}
              >
                <i className="ri-stop-circle-line align-bottom me-1"></i>{" "}
                {isStopLoading ? <Spinner size="sm" variant="light" /> : "Stop"}
              </button>
            </Box>
          </Card.Body>
        </Card>
      </>
    );
  },

  TicketStatusCard: ({ data, dataLoading, updateSingleTicketStatus }) => {
    const userIdOfTicketCreator = data?.user_id;
    const userId = parseInt(localStorage.getItem("user_id"));
    const role = localStorage.getItem("role");
    const permissionData = useSelector(
      (state) => state.userDetails.userPermission
    );
    const hasPermission = permissionData?.find(
      (item) => item.module_name === TICKETING_SYSTEM
    );

    const statusOptions = [
      { label: "Close", value: "Closed" },
      { label: "Active", value: "Active" },
      { label: "Pending", value: "Pending" },
      { label: "Hold", value: "Hold" },
    ];

    return (
      <>
        <Card>
          <Card.Body>
            <Box className="mb-4">
              <FormControl fullWidth variant="filled">
                <Select
                  displayEmpty
                  fullWidth
                  value={
                    statusOptions.find(
                      (option) =>
                        option.value.toLowerCase() ===
                        data.status?.toLowerCase()
                    )?.value || ""
                  }
                  onChange={(statusOptions, m) =>
                    updateSingleTicketStatus(m?.props.value)
                  }
                  disabled={!hasPermission?.canModify}
                  sx={DropdownStyle}
                  MenuProps={MenuHeight}
                >
                  <MenuItem disabled value="">
                    select
                  </MenuItem>
                  {statusOptions.map((option) => (
                    <MenuItem
                      key={option.value}
                      value={option.value}
                      disabled={
                        data.close_time !== null &&
                        userIdOfTicketCreator !== userId &&
                        role !== "Super Admin" &&
                        option.value !== "Closed"
                      }
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box className="table-card table-responsive">
              <table className="table mb-0" border="0">
                <tbody>
                  {dataLoading ? (
                    <>
                      <tr>
                        <td colSpan="2">
                          <Skeleton
                            variant="text"
                            width={250}
                            height={30}
                            style={{ marginLeft: "20px" }}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td colSpan="2">
                          <Skeleton
                            variant="text"
                            width={250}
                            height={30}
                            style={{ marginLeft: "20px" }}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td colSpan="2">
                          <Skeleton
                            variant="text"
                            width={250}
                            height={30}
                            style={{ marginLeft: "20px" }}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td colSpan="2">
                          <Skeleton
                            variant="text"
                            width={250}
                            height={30}
                            style={{ marginLeft: "20px" }}
                          />
                        </td>
                      </tr>
                    </>
                  ) : (
                    <>
                      <tr>
                        <td className="fw-semibold border-0">Ticket Id</td>
                        <td className="border-0">
                          {data?.ticket_id ? `#${data?.ticket_id}` : ""}
                        </td>
                      </tr>
                      <tr>
                        <td className="fw-semibold border-0">Ticket Title</td>
                        <td className="border-0">{data?.title}</td>
                      </tr>
                      {data?.website_full_name && (
                        <tr>
                          <td className="fw-semibold border-0">Project Name</td>
                          <td className="border-0">
                            {data?.website_full_name}
                          </td>
                        </tr>
                      )}
                      <tr>
                        <td className="fw-semibold border-0">Status</td>
                        <td className="border-0">
                          <span>{data?.status}</span>
                        </td>
                      </tr>
                      <tr>
                        <td className="fw-semibold border-0">
                          Website Category
                        </td>
                        <td className="border-0">
                          <span>{data?.category || "-"}</span>
                        </td>
                      </tr>
                    </>
                  )}
                </tbody>
              </table>
            </Box>
          </Card.Body>
        </Card>
      </>
    );
  },

  AssignedToCard: ({
    assignedToDeleteButtonStatus,
    userIdOfTicketCreator,
    setAssignedMembersModalShow,
    isLoading,
    assignedMembersToTicket,
    deleteAssignedMember,
  }) => {
    const userId = parseInt(localStorage.getItem("user_id"));
    const role = localStorage.getItem("role");

    const permissionData = useSelector(
      (state) => state.userDetails.userPermission
    );
    const hasPermission = permissionData?.find(
      (item) => item.module_name === TICKETING_SYSTEM
    );

    const canShowButton =
      hasPermission?.canModify &&
      (role === "Super Admin" || assignedToDeleteButtonStatus);

    return (
      <>
        <Card style={{ overflow: "unset" }}>
          <Card.Body>
            <Box className="d-flex flex-wrap gap-2 mb-3">
              <h6 className="card-title mb-0 flex-grow-1">Assigned To</h6>

              {canShowButton && (
                <button
                  type="button"
                  className="btn btn-soft-primary btn-sm"
                  onClick={() => setAssignedMembersModalShow(true)}
                >
                  <i className="ri-share-line me-1 align-bottom"></i>
                  Assigned Member
                </button>
              )}
            </Box>

            {isLoading ? (
              <>
                <Skeleton variant="text" width={250} height={30} />
                <Skeleton variant="text" width={250} height={30} />
              </>
            ) : (
              <ul className="list-unstyled vstack gap-3 mb-0">
                {assignedMembersToTicket?.length > 0
                  ? assignedMembersToTicket?.map((user) => (
                      <li key={user?.id}>
                        <Box className="d-flex align-items-center">
                          <Box className="flex-shrink-0">
                            {user.user_image !== null ? (
                              <img
                                src={`${process.env.REACT_APP_BASE_URL}${user.user_image}`}
                                className="img-fluid rounded-circle"
                                alt=""
                                style={{
                                  borderRadius: "100px",
                                  height: "32px",
                                  width: "32px",
                                }}
                              />
                            ) : (
                              <ProfileImage username={user.user_name} />
                            )}
                          </Box>
                          <Box className="flex-grow-1 ms-2">
                            <h6 className="mb-1">
                              <Link>{user.user_name}</Link>
                            </h6>
                            <p className="text-muted mb-0">{user.department}</p>
                          </Box>

                          {hasPermission?.canDelete && (
                            <Box className="flex-shrink-0">
                              {(assignedToDeleteButtonStatus ||
                                role === "Super Admin") && (
                                <Box className="dropdown">
                                  {user.id !== userId && (
                                    <button
                                      className="btn btn-icon btn-sm fs-16 dropdown"
                                      type="button"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="false"
                                    >
                                      <i className="ri-more-fill"></i>
                                    </button>
                                  )}

                                  <ul className="dropdown-menu">
                                    <li>
                                      <Link
                                        className="dropdown-item"
                                        onClick={() =>
                                          assignedMembersToTicket.length <= 1
                                            ? toast.error(
                                                "At least one assignee is required."
                                              )
                                            : deleteAssignedMember(user.id)
                                        }
                                      >
                                        <span className="me-2">
                                          <DeleteIcon width={17} height={17} />
                                        </span>
                                        Remove
                                      </Link>
                                    </li>
                                  </ul>
                                </Box>
                              )}
                            </Box>
                          )}
                        </Box>
                      </li>
                    ))
                  : !isLoading && (
                      <Box sx={{ padding: "50px 0" }}>
                        <p className="mb-0 text-center">
                          No assigned members found
                        </p>
                      </Box>
                    )}
              </ul>
            )}
          </Card.Body>
        </Card>
      </>
    );
  },
};

export default CONSTANTS;
