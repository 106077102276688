import { useEffect, useState } from "react";
import { Card, CardBody } from "reactstrap";
import SwiperCore, { Navigation } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import { formatDateTime } from "../../../Components/Common/FormatDateTIme";
import { useSelector } from "react-redux";
import { Box, Skeleton } from "@mui/material";
import common from "../../../Components/Common/common";

SwiperCore.use([Navigation]);

const Projects = ({ id = null }) => {
  const token = localStorage.getItem("token");

  const generalSettingData = useSelector(
    (state) => state.settingsData.settingsData
  );

  const otherSettingsData = generalSettingData?.filter(
    (item) => item.settings_category === "other"
  );

  const dateFormat = otherSettingsData?.find(
    (date) => date.field_name === "DEFAULT_DATE_FORMAT"
  )?.field_value;

  const timeZone = otherSettingsData?.find(
    (time) => time.field_name === "DEFAULT_TIME_FORMAT"
  )?.field_value;

  const timeFormat = otherSettingsData?.find(
    (zone) => zone.field_name === "DEFAULT_TIME_ZONE"
  )?.field_value;

  /////*  FOR PROJECTS   */////
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dataFetched, setDataFetched] = useState(false);
  // const [error, setError] = useState(null);

  const assetsEndPoint = `${process.env.REACT_APP_BASE_URL}online-assets/online-assets-list`;
  const newAssetsEndPoint = `${process.env.REACT_APP_BASE_URL}developer-profile/get-developer-profile-id/${id}`;

  useEffect(() => {
    setLoading(true);
    // setError(null);

    fetch(assetsEndPoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        const responseData = common.decrypt(data.data);
        const assetsIds = responseData?.map((item) => ({
          label: item.website_full_name,
          value: item.id,
          status: item.status,
          updatedAt: item.updatedAt,
        }));
        fetch(newAssetsEndPoint, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
          .then((response) => response.json())
          .then((newData) => {
            const decryptData = common.decrypt(newData.data);
            newData.data = decryptData;
            const newAssetsId = JSON.parse(newData.data.asset_url_management);
            const matchedOptions = assetsIds.filter((option) =>
              newAssetsId.includes(option.value)
            );
            setSelectedOptions(matchedOptions);
          })
          .catch((error) => {
            // setError(error);
            setLoading(false);
          })
          .finally(() => {
            setLoading(false); // Set loading to false after the request is complete
            setDataFetched(true);
          });
      })
      .catch((error) => {
        // setError(error);
        setLoading(false);
        setDataFetched(true);
      });
  }, [assetsEndPoint, newAssetsEndPoint, token]);

  return (
    <>
      {loading ? (
        selectedOptions.length === 0 ? (
          <Card className="profile-project-card profile-project-success">
            <CardBody>
              <div className="d-flex">
                <div className="flex-grow-1 text-muted overflow-hidden">
                  <Skeleton variant="text" width={100} height={30} />
                  <Skeleton variant="text" width={200} height={30} />
                </div>
                <div className="flex-shrink-0 ms-2">
                  <Skeleton variant="text" width={100} height={30} />
                </div>
              </div>
            </CardBody>
          </Card>
        ) : (
          selectedOptions.map((item, index) => (
            <Box className="col-12 col-md-6 col-xxl-4 col-xxxl-3" key={index}>
              <Card className="profile-project-card profile-project-success">
                <CardBody>
                  <div className="d-flex">
                    <div className="flex-grow-1 text-muted overflow-hidden">
                      <Skeleton variant="text" width={100} height={30} />
                      <Skeleton variant="text" width={200} height={30} />
                    </div>
                    <div className="flex-shrink-0 ms-2">
                      <Skeleton variant="text" width={100} height={30} />
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Box>
          ))
        )
      ) : dataFetched && selectedOptions.length === 0 ? (
        <div>No Projects assigned...</div>
      ) : (
        selectedOptions.map((item, index) => (
          <Box className="col-12 col-md-6 col-xxl-4 col-xxxl-3" key={index}>
            <Card className="profile-project-card profile-project-success">
              <CardBody>
                <div className="d-flex">
                  <div className="flex-grow-1 text-muted overflow-hidden">
                    <h5 className="fs-14 mb-2">{item.label}</h5>
                    <p className="text-muted text-truncate mb-0">
                      Last Update :
                      <span className="fw-semibold text-dark">
                        {" "}
                        {
                          formatDateTime(
                            item.updatedAt,
                            dateFormat,
                            timeZone,
                            timeFormat
                          ).timeZone.formattedDateFinal
                        }{" "}
                        {
                          formatDateTime(
                            item.updatedAt,
                            dateFormat,
                            timeZone,
                            timeFormat
                          ).timeZone.formattedTimed
                        }
                      </span>
                    </p>
                  </div>
                  <div className="flex-shrink-0 ms-2">
                    {item.status.includes("In-Active") ? (
                      <span
                        className="badge badge-soft-danger fs-12 border-0 btn-sm"
                        variant="contained"
                      >
                        In-Active
                      </span>
                    ) : (
                      <span
                        className="badge badge-soft-success fs-12 border-0 btn-sm"
                        variant="contained"
                        color="success"
                      >
                        Active
                      </span>
                    )}
                  </div>
                </div>
              </CardBody>
            </Card>
          </Box>
        ))
      )}
    </>
  );
};

export default Projects;
