import axios from "axios";
import React, { useEffect, useState } from "react";
import { ToastMessage } from "../../Components/Common/ToastMessage";
import { formatDateTime } from "../../Components/Common/FormatDateTIme";
import { useSelector } from "react-redux";
import {
  Card,
  Modal,
  Offcanvas,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import {
  Button,
  Col,
  Row,
  Form,
  Label,
  Input,
  FormFeedback,
  Spinner,
} from "reactstrap";

import Swal from "sweetalert2";
import { useFormik } from "formik";
import * as Yup from "yup";
import { detect } from "detect-browser";
import { useNavigate } from "react-router-dom";
import { BLOCKED_IP } from "../../Components/Common/ModuleName";
import { Box, FormControl, MenuItem, Select } from "@mui/material";
import { DropdownStyle, MenuHeight } from "../NewDashboard/constant";
import LoopIcon from "@mui/icons-material/Loop";
import DataTable from "react-data-table-component";
import { CustomTableStyle } from "../../Components/Common/constants";
import { DeleteIcon } from "../../Components/Common/SvgIcons";
import EmailIcon from "@mui/icons-material/Email";
import common from "../../Components/Common/common";

// const calculateRange = (totalCount, pageSize, currentPage) => {
//   const start = (currentPage - 1) * pageSize + 1;
//   const end = Math.min(currentPage * pageSize, totalCount);
//   return [start, end];
// };

const BlockedIp = () => {
  const token = localStorage.getItem("token");

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalblockedIpsData, setBlockedIpsData] = useState();
  const [show, setShow] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  // const [totalCount, setTotalCount] = useState(20);
  // const [perPageSize, setPerPageSize] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectStatus, setSelectStatus] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const browser = detect();
  const navigate = useNavigate();
  const permissionData = useSelector(
    (state) => state.userDetails.userPermission
  );
  const hasPermission = permissionData?.find(
    (item) => item.module_name === BLOCKED_IP
  );

  const handleCloseModal = () => {
    setShowModal(false);
    setBlockedIpsData();
  };

  const handleClose = () => {
    setShow(false);
    validation.resetForm();
  };

  const handleShow = () => {
    setShow(true);
  };

  const handleShowAccessModal = (data) => {
    setBlockedIpsData(data);
    setShowModal(true);
  };

  const handleInputSearch = (e) => {
    setCurrentPage(1);
    setSearchQuery(e.target.value);
  };

  const handleStatus = (e) => {
    setCurrentPage(1);
    setSelectStatus(e.target.value);
  };

  // const [start, end] = calculateRange(totalCount, perPageSize, currentPage);
  // const totalPages = Math.ceil(totalCount / perPageSize);

  // const handlePageChange = (page) => {
  //   if (page < 1) {
  //     page = 1;
  //   } else if (page > totalPages) {
  //     page = totalPages;
  //   }

  //   setCurrentPage(page);

  //   if (page === totalPages) {
  //     document.querySelector(".pagination-next").classList.add("disabled");
  //   } else {
  //     document.querySelector(".pagination-next").classList.remove("disabled");
  //   }

  //   if (page === 1) {
  //     document.querySelector(".pagination-prev").classList.add("disabled");
  //   } else {
  //     document.querySelector(".pagination-prev").classList.remove("disabled");
  //   }
  // };

  // const handleSelectPageSize = (e) => {
  //   setCurrentPage(1);
  //   setPerPageSize(parseInt(e.target.value, 10));
  // };

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      blockedIp: null,
    },

    validationSchema: Yup.object({
      blockedIp: Yup.string()
        .required("Please enter the IP.")
        .matches(
          /^(?!0+\.0+\.0+\.0+$)([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/,
          "Invalid IP address"
        ),
    }),

    onSubmit: (values, { resetForm }) => {
      addBlockedIp(values);
    },
  });

  const getIpData = async () => {
    try {
      setLoading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}ip/get-blockedIp?page=${currentPage}&status=${selectStatus}&searchFilter=${searchQuery}`,
        {
          method: "post",
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      if (response) {
        const responseData = common.decrypt(response.data);
        setData(responseData.rows);
        setLoading(false);
        // setTotalCount(response.data.count);
      } else {
        ToastMessage.Error("No forms available at the moment.");
      }
    } catch (error) {
      ToastMessage.Error("Failed to load forms. Please try again later.");
    }
  };

  useEffect(() => {
    getIpData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, selectStatus, searchQuery]);

  const handleDeleteIP = async (id) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}ip/delete-blockedIp/${id}`
      );
      if (response) {
        // ToastMessage.Success("Blocked Ip record deleted successfully.");
        getIpData();
      }
    } catch (error) {
      ToastMessage.Error("Something went wrong. Please try again later.");
    }
  };

  const addBlockedIp = async (values) => {
    setIsSubmitting(true);
    const data = {
      ip_address: values.blockedIp,
      browser_info: browser.name,
      blocked_type: "Manual",
    };
    let apiUrl = `${process.env.REACT_APP_BASE_URL}ip/add-blockedIp`;
    let config = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    };
    let result = await fetch(apiUrl, config);
    let response = await result.json();
    if (response.code === 403) {
      navigate("/auth-404-cover");
    }
    if (response.status === "success") {
      setShow(false);
      getIpData();
      validation.resetForm();
      ToastMessage.Success("BlockIp added successfully");
    }
    if (response.status === "fail") {
      setShow(false);
      getIpData();
      ToastMessage.Error(response.message);
    }
    setIsSubmitting(false);
  };

  const handleDeleteAlert = (id, email) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-dark mx-2",
        cancelButton: "btn btn-primary mx-2",
      },
      buttonsStyling: false,
    });

    swalWithBootstrapButtons
      .fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          handleDeleteIP(id);
          swalWithBootstrapButtons.fire(
            "Deleted!",
            "IP Address has been deleted.",
            "success"
          );
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          swalWithBootstrapButtons.fire("Cancelled", "IP is safe :)", "error");
        }
      });
  };

  const generalSettingData = useSelector(
    (state) => state.settingsData.settingsData
  );

  const otherSettingsData = generalSettingData?.filter(
    (item) => item.settings_category === "other"
  );

  const dateFormat = otherSettingsData?.find(
    (date) => date.field_name === "DEFAULT_DATE_FORMAT"
  )?.field_value;

  const timeZone = otherSettingsData?.find(
    (time) => time.field_name === "DEFAULT_TIME_FORMAT"
  )?.field_value;

  const timeFormat = otherSettingsData?.find(
    (zone) => zone.field_name === "DEFAULT_TIME_ZONE"
  )?.field_value;

  const handleReset = () => {
    setSearchQuery("");
    setSelectStatus("");
    setCurrentPage(1);
  };

  const columns = [
    {
      name: "IP",
      selector: (row) => (
        <span>
          <span>{row?.ip_address}</span>
        </span>
      ),
      minWidth: "150px",
    },
    {
      name: "Access Information",
      selector: (row) => (
        <span onClick={() => handleShowAccessModal(row)}>
          <EmailIcon />
        </span>
      ),
      minWidth: "150px",
    },
    {
      name: "Date Time",
      selector: (row) => (
        <span>
          <span>
            {" "}
            {
              formatDateTime(row.createdAt, dateFormat, timeZone, timeFormat)
                .timeZone.formattedDateFinal
            }{" "}
            {
              formatDateTime(row.createdAt, dateFormat, timeZone, timeFormat)
                .timeZone.formattedTimed
            }
          </span>
        </span>
      ),
      minWidth: "150px",
    },
    {
      name: "Action",
      cell: (row) => (
        <span className="table-action">
          {hasPermission && hasPermission?.canDelete != null ? (
            <>
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id={`tooltip-delete-${row.id}`}>Delete</Tooltip>
                }
              >
                <span
                  className="cursor-pointer ms-2"
                  onClick={() => {
                    handleDeleteAlert(row.id);
                  }}
                  style={{ marginLeft: "20px", cursor: "pointer" }}
                >
                  <DeleteIcon />
                </span>
              </OverlayTrigger>
              {/* <span
                onClick={() => {
                  handleDeleteAlert(row.id);
                }}
              >
                <DeleteIcon />
              </span> */}
            </>
          ) : (
            ""
          )}
        </span>
      ),
      minWidth: "150px",
    },
  ];

  return (
    <React.Fragment>
      <Card className="table-main-card">
        <Box className="table-card-header">
          <Box className="left-side">
            <Box className="search-box">
              <input
                type="number"
                className="form-control"
                placeholder="Search"
                value={searchQuery}
                onChange={(e) => handleInputSearch(e)}
              />
              <i className="ri-search-line search-icon"></i>
            </Box>
            <FormControl
              fullWidth
              variant="filled"
              sx={{
                width: "150px",
                minWidth: "150px",
              }}
            >
              <Select
                displayEmpty
                fullWidth
                value={selectStatus}
                onChange={handleStatus}
                sx={DropdownStyle}
                MenuProps={MenuHeight}
              >
                <MenuItem disabled value="">
                  select
                </MenuItem>
                <MenuItem value="">Login Access </MenuItem>
                <MenuItem value="Y">Approve </MenuItem>
                <MenuItem value="N">Reject</MenuItem>
              </Select>
            </FormControl>
            <Button
              className="btn btn-primary reset-btn"
              onClick={() => {
                handleReset();
              }}
            >
              <LoopIcon />
            </Button>
          </Box>
          {hasPermission && hasPermission?.canCreate != null ? (
            <>
              <button
                type="button"
                className="btn btn-primary view-btn"
                onClick={handleShow}
              >
                Add Blocked IP
              </button>
            </>
          ) : (
            ""
          )}
        </Box>

        {/* {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "50vh",
            }}
          >
            <Spinner height={100} width={100} />
          </div>
        ) : (
          <Card.Body>
            <DataTable
              fixedHeight="500px"
              pointerOnHover
              columns={columns}
              data={data}
              pagination
              customStyles={CustomTableStyle}
              className="table-design"
            />
          </Card.Body>
        )} */}
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "50vh",
            }}
          >
            <Spinner height={100} width={100} />
          </div>
        ) : (
          <Card.Body>
            <DataTable
              fixedHeight="500px"
              pointerOnHover
              columns={columns}
              data={data}
              pagination
              customStyles={CustomTableStyle}
              className="table-design"
            />
          </Card.Body>
        )}
      </Card>

      <Modal show={showModal} onHide={handleCloseModal} centered size="sm">
        <Modal.Body>
          <Box className="table-responsive table-design">
            <table style={{ width: "100%" }}>
              <tbody>
                {modalblockedIpsData?.email ? (
                  <tr>
                    <th>Email</th>
                    <td>asd adsad@sdfsdf </td>
                  </tr>
                ) : (
                  ""
                )}
                <tr>
                  <th>Browser Info</th>
                  <td>{modalblockedIpsData?.browser_info}</td>
                </tr>
                <tr>
                  <th>Blocked Form</th>
                  <td>{modalblockedIpsData?.blocked_type}</td>
                </tr>
              </tbody>
            </table>
          </Box>
        </Modal.Body>
      </Modal>

      {/* <ModalBlockedIps
                    show={show}
                    handleClose={handleClose}
                    listOfBlockedIps={listOfBlockedIps}
                    formik={formik}
                /> */}
      {/* <ToastContainer /> */}

      <Offcanvas
        style={{ width: "31rem" }}
        show={show}
        onHide={handleClose}
        placement="end"
        backdrop="static"
      >
        <Offcanvas.Header>
          <Offcanvas.Title>Add Blocked IP</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Form onSubmit={validation.handleSubmit}>
            <Row>
              <Col lg={12} className="mb-4">
                <Label>IP Address *</Label>
                <Input
                  name="blockedIp"
                  id="outlined-size-small"
                  placeholder="Enter IP Address"
                  value={validation.values.blockedIp || ""}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  invalid={
                    validation.errors.blockedIp && validation.touched.blockedIp
                      ? true
                      : false
                  }
                />
                {validation.errors.blockedIp &&
                  validation.touched.blockedIp && (
                    <FormFeedback type="d-block" style={{ color: "red" }}>
                      {validation.errors.blockedIp}
                    </FormFeedback>
                  )}
              </Col>
              <Col lg={12} style={{ alignItems: "center" }}>
                <Button
                  className="btn btn-dark"
                  type="submit"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? (
                    <Spinner size="sm" variant="light" />
                  ) : (
                    "Submit"
                  )}
                </Button>
                <Button className="btn btn-primary ms-2" onClick={handleClose}>
                  Cancel
                </Button>
              </Col>
            </Row>
          </Form>
        </Offcanvas.Body>
      </Offcanvas>
    </React.Fragment>
  );
};

export default BlockedIp;
