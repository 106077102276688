import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  FormControl,
  InputGroup,
  Modal,
  ModalHeader,
  ModalTitle,
  Row,
} from "react-bootstrap";
import Select from "react-select";
import { Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  getBrandingGuideline,
  getWebsiteGuideline,
} from "../../helpers/Help/CommomApi";
import common from "../../Components/Common/common";
import { useFormik } from "formik";
import { ToastMessage } from "../../Components/Common/ToastMessage";
import { addCompliance } from "../../store/actions";

const ComplianceModal = (props) => {
  const dispatch = useDispatch();

  const { lgShow, setLgShow } = props;

  const [websiteGuideline, setWebsiteGuideline] = useState({});
  const [brandingGuideline, setBrandingGuideline] = useState({});
  const [developersSelectedOptions, setDevelopersSelectedOptions] = useState(
    []
  );
  const [websiteSelectedOptions, setWebsiteSelectedOptions] = useState([]);
  const [brandingSelectedOptions, setBrandingSelectedOptions] = useState([]);
  const [showWebsiteGuidelineSelect, setShowWebsiteGuidelineSelect] =
    useState(false);
  const [showBrandingGuidelineSelect, setShowBrandingGuidelineSelect] =
    useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const { developersProfileDetails } = useSelector(
    (state) => state.developersProfile
  );
  const { addComplianceError, addComplianceLoading } = useSelector(
    (state) => state.compliance
  );

  const customStyles = {
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
    }),
  };

  const complianceValidation = useFormik({
    enableReinitialize: true,

    initialValues: {
      website_guideline_id: null,
      branding_guideline_id: null,
      website_policy: false,
      developers_id: [],
      other_email: "",
    },

    validate: (values) => {
      const errors = {};

      if (showWebsiteGuidelineSelect && !values.website_guideline_id) {
        errors.website_guideline_id = "Website Guideline is required.";
      }

      if (showBrandingGuidelineSelect && !values.branding_guideline_id) {
        errors.branding_guideline_id = "Branding Guideline is required.";
      }

      if (
        complianceValidation.touched.developers_id &&
        values.developers_id.length <= 0
      ) {
        errors.developers_id = "Developers is required.";
      } else {
        delete errors.developers_id;
      }

      if (values.developers_id.some((i) => i === "other")) {
        delete errors.other_email;
        if (!values.other_email) {
          errors.other_email = "Please type the others email id.";
        } else {
          delete errors.other_email;
        }
      } else {
        delete errors.other_email;
      }

      if (values.developers_id.length === 0) {
        if (!values.other_email) {
          errors.other_email = "Please type the others email id.";
        } else {
          delete errors.other_email;
        }
      }

      return errors;
    },

    onSubmit: (values) => {
      if (addComplianceError !== null) {
        ToastMessage.Error(addComplianceError);
      }
      setErrorMessage(null);
      dispatch(addCompliance(values));
      if (addComplianceLoading === false) {
        handleClose();
        ToastMessage.Success("compliance sent successfully.");
      }
    },
  });

  const handleClose = () => {
    setLgShow(false);
    setErrorMessage(null);
    setBrandingSelectedOptions([]);
    setWebsiteSelectedOptions([]);
    setDevelopersSelectedOptions([]);
    setShowWebsiteGuidelineSelect(false);
    setShowBrandingGuidelineSelect(false);
    complianceValidation.resetForm();
  };

  const getData = useCallback(async () => {
    try {
      let response = await getWebsiteGuideline();
      if (response.status === "success") {
        const responseData = common.decrypt(response.data);
        let guidelineOptions = responseData?.map((item) => ({
          label: item.website_guideline_title,
          value: item.id,
        }));
        setWebsiteGuideline(guidelineOptions);
      }
    } catch (error) {
      return null;
    }
  }, []);

  const getBrandingData = useCallback(async () => {
    try {
      let response = await getBrandingGuideline();
      if (response.status === "success") {
        const responseData = common.decrypt(response.data);
        let brandingOptions = responseData.map((item) => ({
          label: item.branding_guideline_title,
          value: item.id,
        }));
        setBrandingGuideline(brandingOptions);
      }
    } catch (error) {
      return null;
    }
  }, []);

  useEffect(() => {
    getData();
    getBrandingData();
  }, [getData, getBrandingData]);

  const handleWebsiteSelectChange = (selectedOptions) => {
    complianceValidation.setFieldValue(
      "website_guideline_id",
      selectedOptions.map((item) => item.value)
    );
    setWebsiteSelectedOptions(selectedOptions);
  };

  const handleBrandingSelectChange = (selectedOptions) => {
    complianceValidation.setFieldValue(
      "branding_guideline_id",
      selectedOptions.map((item) => item.value)
    );
    setBrandingSelectedOptions(selectedOptions);
  };

  const handleWebsiteSwitchChange = (selected) => {
    setShowWebsiteGuidelineSelect(selected.target.checked);
    if (!selected.target.checked) {
      setWebsiteSelectedOptions([]);
    }
  };

  const handleBrandingSwitchChange = (selected) => {
    setShowBrandingGuidelineSelect(selected.target.checked);
    if (!selected.target.checked) {
      setBrandingSelectedOptions([]);
    }
  };

  const handlePolicySwitchChange = async (selected) => {
    complianceValidation.setFieldValue(
      "website_policy",
      selected.target.checked
    );
  };

  const handleDevelopersSelectChange = (selected) => {
    const isSelected = selected && selected.some((i) => i.label === "other");

    if (isSelected) {
      setDevelopersSelectedOptions(selected.filter((i) => i.label === "other"));
      complianceValidation.setFieldValue(
        "developers_id",
        selected.map((item) => item.value)
      );
    } else {
      setDevelopersSelectedOptions(selected.filter((i) => i.label !== "other"));
      complianceValidation.setFieldValue(
        "developers_id",
        selected.map((item) => item.value)
      );
    }
  };

  const handleSubmit = () => {
    if (
      !complianceValidation.values.website_guideline_id &&
      !complianceValidation.values.branding_guideline_id &&
      !complianceValidation.values.website_policy
    ) {
      setErrorMessage(
        "Please select at least one value in Website Guideline, Branding Guideline, and Website Policies."
      );
    } else {
      setErrorMessage(null);
      complianceValidation.submitForm();
    }
  };

  return (
    <>
      <Modal
        size="md"
        show={lgShow}
        onHide={handleClose}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <ModalHeader closeButton>
          <ModalTitle className="h5">Send Compliance</ModalTitle>
        </ModalHeader>

        <Modal.Body>
          <Form
            className="needs-validation"
            noValidate
            action="index"
            onSubmit={complianceValidation.handleSubmit}
          >
            <Row className="g-3">
              <Col lg={12} className="align-items-center">
                <Box className="d-flex">
                  <label className="me-2">Website Guideline: &nbsp;</label>
                  <Form.Check
                    type="switch"
                    id="website-guideline-switch"
                    onChange={handleWebsiteSwitchChange}
                  />
                </Box>
                {showWebsiteGuidelineSelect && (
                  <>
                    <Select
                      isMulti
                      name="website-guideline-select"
                      placeholder="please select the website guideline."
                      options={websiteGuideline}
                      value={websiteSelectedOptions}
                      styles={customStyles}
                      onChange={handleWebsiteSelectChange}
                    />
                    {complianceValidation.errors.website_guideline_id && (
                      <Box
                        className="invalid-feedback d-block"
                        style={{ color: "red" }}
                      >
                        {complianceValidation.errors.website_guideline_id}
                      </Box>
                    )}
                  </>
                )}
              </Col>

              <Col lg={12} className="align-items-center">
                <Box className="d-flex">
                  <label className="me-2">Branding Guideline: &nbsp;</label>
                  <Form.Check
                    type="switch"
                    id="branding-guideline-switch"
                    onChange={handleBrandingSwitchChange}
                  />
                </Box>
                {showBrandingGuidelineSelect && (
                  <>
                    <Select
                      isMulti
                      name="branding-guideline-select"
                      placeholder="please select the branding guideline."
                      options={brandingGuideline}
                      value={brandingSelectedOptions}
                      styles={customStyles}
                      onChange={handleBrandingSelectChange}
                    />
                    {complianceValidation.errors.branding_guideline_id && (
                      <Box
                        className="invalid-feedback d-block"
                        style={{ color: "red" }}
                      >
                        {complianceValidation.errors.branding_guideline_id}
                      </Box>
                    )}
                  </>
                )}
              </Col>

              <Col lg={12} className="d-flex align-items-center">
                <label className="me-2">Website Policies: &nbsp;</label>
                <Form.Check
                  type="switch"
                  id="website-policies-switch"
                  onChange={handlePolicySwitchChange}
                />
              </Col>

              <Col lg={12}>
                <Box className="d-flex align-items-start">
                  <label className="me-2">Developers: &nbsp;</label>
                  <span className="w-100" style={{ zIndex: 9 }}>
                    <Select
                      isMulti
                      name="website-guideline-select"
                      placeholder="please select the developers."
                      options={[
                        { label: "other", value: "other" }, // Static value
                        ...((developersProfileDetails &&
                          Array.isArray(developersProfileDetails.rows) &&
                          developersProfileDetails.rows.map((item) => ({
                            label: item.user_name,
                            value: item.id,
                          }))) ||
                          []),
                      ]}
                      value={developersSelectedOptions}
                      onChange={handleDevelopersSelectChange}
                    />
                    {complianceValidation.errors.developers_id && (
                      <Box
                        className="invalid-feedback d-block"
                        style={{ color: "red" }}
                      >
                        {complianceValidation.errors.developers_id}
                      </Box>
                    )}
                  </span>
                </Box>
              </Col>

              <Col lg={12}>
                {developersSelectedOptions &&
                  developersSelectedOptions.some(
                    (i) => i.label === "other"
                  ) && (
                    <Box className="d-flex align-items-start">
                      <label className="me-2">Email:</label>
                      <Box className="w-100">
                        <InputGroup>
                          <FormControl
                            type="email"
                            name="other_email"
                            placeholder="Enter email"
                            value={complianceValidation.values.other_email}
                            onChange={complianceValidation.handleChange}
                            onBlur={complianceValidation.handleBlur}
                            isInvalid={
                              !!complianceValidation.errors.other_email &&
                              !!complianceValidation.touched.other_email
                            }
                          />
                        </InputGroup>
                        {complianceValidation.errors.other_email &&
                          complianceValidation.touched.other_email && (
                            <Box
                              className="invalid-feedback d-block"
                              style={{ color: "red" }}
                            >
                              {complianceValidation.errors.other_email}
                            </Box>
                          )}
                      </Box>
                    </Box>
                  )}
              </Col>

              {errorMessage != null && (
                <Box
                  className="alert alert-borderless alert-warning text-center mb-2 mx-2 mt-4"
                  role="alert"
                >
                  {errorMessage}
                </Box>
              )}

              <Col lg={12}>
                <Box className="hstack gap-2 justify-content-center">
                  <Button className="btn btn-primary" onClick={handleClose}>
                    Cancel
                  </Button>
                  <Button className="btn btn-dark" onClick={handleSubmit}>
                    Submit
                  </Button>
                </Box>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ComplianceModal;
