import React, { useEffect, useState } from "react";
import {
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  ListItemText,
  FormControlLabel,
  Skeleton,
} from "@mui/material";
// import common from "../../Components/Common/common";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { updateNotificationSetting } from "../../helpers/Help/CommomApi";
import { ToastMessage } from "../../Components/Common/ToastMessage";
import axios from "axios";
import { Card } from "react-bootstrap";
import { AndroidSwitch } from "../../Components/Common/constants";
// const { IOSSwitch } = common;

const Constant = {
  GlobalNotifications: ({ data }) => {
    const [globalSetting, setGlobalSetting] = useState({});
    const [loading, setLoading] = useState(true);

    // useEffect(() => {
    //   const initialGlobalSetting = {};
    //   data.forEach((item) => {
    //     initialGlobalSetting[item.field_name] = item.field_value === "true";
    //   });
    //   setGlobalSetting(initialGlobalSetting);
    // }, [data]);

    useEffect(() => {
      if (data.length > 0) {
        const initialGlobalSetting = {};
        data.forEach((item) => {
          initialGlobalSetting[item.field_name] = item.field_value === "true";
        });
        setGlobalSetting(initialGlobalSetting);
        setLoading(false);
      }
    }, [data]);

    const handleChange = (fieldName) => (event) => {
      const isChecked = event.target.checked;
      setGlobalSetting((prev) => ({
        ...prev,
        [fieldName]: isChecked,
      }));
      if (fieldName === "GLOBAL_EMAIL_NOTIFICATION") {
        postGlobalNotificationData({ GLOBAL_EMAIL_NOTIFICATION: isChecked });
      }
      if (fieldName === "GLOBAL_SYSTEM_NOTIFICATION") {
        postGlobalNotificationData({ GLOBAL_SYSTEM_NOTIFICATION: isChecked });
      }
    };

    const postGlobalNotificationData = async (data) => {
      try {
        await axios.post(`settings/add-settings`, data);
        const successMessages = {
          GLOBAL_EMAIL_NOTIFICATION:
            "GLOBAL EMAIL NOTIFICATION status updated successfully.",
          GLOBAL_SYSTEM_NOTIFICATION:
            "GLOBAL SYSTEM NOTIFICATION status updated successfully.",
        };
        Object.keys(data).forEach((key) => {
          if (successMessages[key]) {
            ToastMessage.Success(successMessages[key]);
          }
        });
      } catch (error) {
        console.error(error);
        ToastMessage.Error(
          "Failed to update global notification settings. Please try again."
        );
      }
    };

    return (
      <Box>
        <h3 className="mb-4">Global Notifications</h3>
        <Card>
          {/* <Card.Body>
            {data.map((item, index) => (
              <Box key={index} className="global-not-box">
                <Box>
                  {loading ? (
                    <Skeleton variant="text" width={100} height={30} />
                  ) : (
                    <h5>
                      {item.field_name === "GLOBAL_EMAIL_NOTIFICATION" &&
                        "Email notifications"}
                      {item.field_name === "GLOBAL_SYSTEM_NOTIFICATION" &&
                        "System notifications"}
                    </h5>
                  )}
                  {loading ? (
                    <Skeleton variant="text" width={300} height={30} />
                  ) : (
                    <p className="m-0">
                      {item.field_name === "GLOBAL_EMAIL_NOTIFICATION" &&
                        "Turn on if you want email notifications."}
                      {item.field_name === "GLOBAL_SYSTEM_NOTIFICATION" &&
                        "Turn on if you want system notifications."}
                    </p>
                  )}
                </Box>
                <AndroidSwitch
                  checked={globalSetting[item.field_name] || false}
                  onChange={handleChange(item.field_name)}
                />
              </Box>
            ))}
          </Card.Body> */}
          <Card.Body>
            {loading ? (
              <>
                <Skeleton variant="text" width={150} height={30} />
                <Skeleton variant="text" width={300} height={30} />
                <Skeleton variant="text" width={150} height={30} />
                <Skeleton variant="text" width={300} height={30} />
              </>
            ) : (
              data.map((item, index) => (
                <Box key={index} className="global-not-box">
                  <Box>
                    <h5>
                      {item.field_name === "GLOBAL_EMAIL_NOTIFICATION" &&
                        "Email notifications"}
                      {item.field_name === "GLOBAL_SYSTEM_NOTIFICATION" &&
                        "System notifications"}
                    </h5>
                    <p className="m-0">
                      {item.field_name === "GLOBAL_EMAIL_NOTIFICATION" &&
                        "Turn on if you want email notifications."}
                      {item.field_name === "GLOBAL_SYSTEM_NOTIFICATION" &&
                        "Turn on if you want system notifications."}
                    </p>
                  </Box>
                  <AndroidSwitch
                    checked={globalSetting[item.field_name] || false}
                    onChange={handleChange(item.field_name)}
                  />
                </Box>
              ))
            )}
          </Card.Body>
        </Card>
      </Box>
    );
  },

  CustomizeNotifications: ({ data }) => {
    const [selectedValues, setSelectedValues] = useState([]);
    const [emailSettings, setEmailSettings] = useState({});
    const [notificationSettings, setNotificationSettings] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
      const initialEmailSettings = {};
      const initialNotificationSettings = {};

      data.forEach((section, sectionIndex) => {
        section.items.forEach((item, itemIndex) => {
          const key = `${sectionIndex}-${itemIndex}`;
          initialEmailSettings[key] = item.isEmail === "true";
          initialNotificationSettings[key] =
            item.isSystemNotification === "true";
        });
      });

      setEmailSettings(initialEmailSettings);
      setNotificationSettings(initialNotificationSettings);
      setLoading(false);
    }, [data]);

    // Set default values based on the send_to property of the items
    useEffect(() => {
      const initialSelectedValues = {};
      data.forEach((module) => {
        module.items.forEach((item) => {
          initialSelectedValues[item.id] = item.send_to_all_options
            .filter((option) => item.send_to.includes(option.value.toString()))
            .map((option) => option.value);
        });
      });

      // Only update state if initialSelectedValues is different from the current state
      setSelectedValues((prevValues) => {
        if (
          JSON.stringify(prevValues) !== JSON.stringify(initialSelectedValues)
        ) {
          return initialSelectedValues;
        }
        return prevValues;
      });
    }, [data]);

    const handleChange = (itemId, value, title) => {
      setSelectedValues((prevValues) => ({
        ...prevValues,
        [itemId]: value,
      }));
      updateNotificationSetting({
        title,
        send_to: value,
      }).then(() => {
        ToastMessage.Success(`${title} send to updated successfully.`);
      });
    };

    const handleEmailChange = (sectionIndex, itemIndex, title) => (event) => {
      let isChecked = event.target.checked;
      const key = `${sectionIndex}-${itemIndex}`;
      setEmailSettings((prev) => ({
        ...prev,
        [key]: isChecked,
      }));
      updateNotificationSetting({
        title,
        isEmail: isChecked,
      }).then(() => {
        ToastMessage.Success(`${title} status updated successfully.`);
      });
    };

    const handleNotificationChange =
      (sectionIndex, itemIndex, title) => (event) => {
        let isChecked = event.target.checked;
        const key = `${sectionIndex}-${itemIndex}`;
        setNotificationSettings((prev) => ({
          ...prev,
          [key]: isChecked,
        }));
        updateNotificationSetting({
          title,
          isSystemNotification: isChecked,
        }).then(() => {
          ToastMessage.Success(`${title} status updated successfully.`);
        });
      };

    const renderSkeleton = () => (
      <Box>
        <Skeleton variant="text" width="40%" height={40} />
        {[1, 2, 3].map((_, index) => (
          <Accordion
            key={index}
            sx={{ my: 2, minHeight: "64px", maxHeight: "800px" }}
          >
            <AccordionSummary>
              <Skeleton variant="text" width="60%" height={30} />
            </AccordionSummary>
            <AccordionDetails>
              {[1, 2, 3].map((_, itemIndex) => (
                <Box
                  key={itemIndex}
                  display="flex"
                  justifyContent="space-between"
                  my={2}
                >
                  <Skeleton variant="text" width="30%" />
                  <Box display="flex" gap={2}>
                    <Skeleton variant="text" width={100} height={40} />
                    <Skeleton variant="text" width={100} height={40} />
                    <Skeleton variant="text" width={100} height={40} />
                  </Box>
                </Box>
              ))}
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    );

    if (loading) {
      return renderSkeleton();
    }

    return (
      <Box>
        <h3 className="mb-4">Customize Notifications</h3>
        {(data || []).map((section, sectionIndex) => (
          <Accordion
            key={sectionIndex}
            sx={{
              boxShadow: "none",
              "&.MuiAccordion-root": {
                borderRadius: "10px",
                margin: "10px 0",
              },
              "&.MuiAccordion-root::before": {
                display: "none",
              },
              "&.MuiAccordion-root.Mui-expanded": {
                // margin: 0,
                borderRadius: "10px",
              },
              "& .MuiAccordionSummary-root.Mui-expanded": {
                borderBottom: "1px solid #e4e8eb",
              },
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel${sectionIndex}-content`}
              id={`panel${sectionIndex}-header`}
              // sx={{ padding: 0 }}
            >
              <h5 className="mb-0">{section?.module_title}</h5>
            </AccordionSummary>
            <AccordionDetails>
              {(section?.items || []).map((item, itemIndex) => {
                const key = `${sectionIndex}-${itemIndex}`;
                return (
                  <Box key={itemIndex} className="customize-not-box">
                    <p className="title">{item?.title}</p>

                    <Box className="right-side">
                      {item.send_to_all_options.length > 0 && (
                        <FormControl sx={{ my: 1, minWidth: 200 }} size="small">
                          <InputLabel
                            id="select"
                            sx={{
                              "&.MuiInputLabel-root": { lineHeight: 2 },
                            }}
                          >
                            Send-To
                          </InputLabel>
                          <Select
                            labelId={`send-to-label-${item.id}`}
                            multiple
                            value={selectedValues[item.id] || []}
                            label="Send-To"
                            onChange={(event) =>
                              handleChange(
                                item.id,
                                event.target.value,
                                item?.title
                              )
                            }
                            renderValue={(selected) => (
                              <div>
                                {selected
                                  .map(
                                    (value) =>
                                      item.send_to_all_options.find(
                                        (option) => option.value === value
                                      )?.label || value
                                  )
                                  .join(", ")}
                              </div>
                            )}
                          >
                            {Array.isArray(item.send_to_all_options) &&
                              item.send_to_all_options.map((option) => {
                                return (
                                  <MenuItem
                                    value={option.value}
                                    key={option.value}
                                    sx={{ whiteSpace: "break-spaces" }}
                                  >
                                    <ListItemText primary={option.label} />
                                  </MenuItem>
                                );
                              })}
                          </Select>
                        </FormControl>
                      )}

                      {item?.isEmail !== "None" && (
                        <FormControlLabel
                          value="E-mail"
                          control={
                            <AndroidSwitch
                              checked={emailSettings[key] ?? false}
                              onChange={handleEmailChange(
                                sectionIndex,
                                itemIndex,
                                item?.title
                              )}
                            />
                          }
                          label="E-mail"
                          labelPlacement="start"
                        />
                      )}

                      {item?.isSystemNotification !== "None" && (
                        <FormControlLabel
                          value="Notification"
                          control={
                            <AndroidSwitch
                              checked={notificationSettings[key] ?? false}
                              onChange={handleNotificationChange(
                                sectionIndex,
                                itemIndex,
                                item?.title
                              )}
                            />
                          }
                          label="Notification"
                          labelPlacement="start"
                        />
                      )}
                    </Box>
                  </Box>
                );
              })}
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    );
  },
};

export default Constant;
