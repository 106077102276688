import React from "react";
import { useLocation, useParams } from "react-router-dom";

const PageTitle = () => {
  const location = useLocation();
  const params = useParams();
  const id = params.id;

  return (
    <React.Fragment>
      <h5 className="title_breadcum fw-semibold me-4 mb-0">
        {(location.pathname === "/settings" && <span>Settings</span>) ||
          (location.pathname === "/" && <span>Dashboard</span>) ||
          (location.pathname === "/dashboard" && <span>Dashboard</span>) ||
          (location.pathname === "/online-assets" && (
            <span>Online Assets</span>
          )) ||
          (location.pathname === `/online-assets/${id}` && (
            <span>Online Assets</span>
          )) ||
          (location.pathname === "/internal-users" && (
            <span>Internal Users</span>
          )) ||
          (location.pathname === "/core-users" && <span>Core Users</span>) ||
          (location.pathname === "/developer-profile" && (
            <span>Developer Profile</span>
          )) ||
          (location.pathname === `/developer-profile/${id}` && (
            <span>Developer Profile</span>
          )) ||
          (location.pathname === "/role-manager/add" && (
            <span>Add Role</span>
          )) ||
          (location.pathname === `/role-manager/add/${id}` && (
            <span>Edit Role</span>
          )) ||
          (location.pathname === "/role-manager" && (
            <span>Role Manager</span>
          )) ||
          (location.pathname === "/website-guideline" && (
            <span>Website Guideline</span>
          )) ||
          (location.pathname === "/branding-guideline" && (
            <span>Branding Guideline</span>
          )) ||
          (location.pathname === "/website-policies" && (
            <span>Website Policies</span>
          )) ||
          (location.pathname === "/announcements" && (
            <span>Announcements</span>
          )) ||
          (location.pathname === "/ticketing-system" && (
            <span>Ticketing System</span>
          )) ||
          (location.pathname === `/ticketing-system/${id}` && (
            <span>Ticket Details</span>
          )) ||
          (location.pathname === `/add-role/${id}` && <span>Add Role</span>) ||
          (location.pathname === "/uptime-report" && (
            <span>Uptime Report</span>
          )) ||
          (location.pathname === `/uptime-report/${id}` && (
            <span>Uptime Report Details</span>
          )) ||
          (location.pathname === "/hit-report" && <span>Hit Report</span>) ||
          (location.pathname === `/hit-report/${id}` && (
            <span>Analytics Report Details</span>
          )) ||
          (location.pathname === "/performance" && <span>Performance</span>) ||
          (location.pathname === `/performance/${id}` && (
            <span>Performance Details</span>
          )) ||
          (location.pathname === "/web-security" && (
            <span>Web Security</span>
          )) ||
          (location.pathname === `/web-security/${id}` && (
            <span>Web Security Details</span>
          )) ||
          (location.pathname === `/pages-profile/${id}` && (
            <span>My Profile</span>
          )) ||
          (location.pathname === `/pages-profile-settings/${id}` && (
            <span>My Profile</span>
          )) ||
          (location.pathname === `/blocked-ip` && <span>Blocked IP</span>) ||
          (location.pathname === `/audit-logs` && <span>Audit Logs</span>) ||
          (location.pathname === `/compliance` && <span>Compliance</span>) ||
          (location.pathname === `/notification-setting` && (
            <span>Notification Setting</span>
          )) ||
          (location.pathname === `/email-logs` && <span>Email Logs</span>) ||
          (location.pathname === `/package/add` && <span>Add Package</span>) ||
          (location.pathname === `/package/edit/${id}` && (
            <span>Edit Package</span>
          )) ||
          (location.pathname === `/my-package` && <span>My Package</span>) ||
          (location.pathname === `/contact-us` && <span>Package</span>) ||
          (location.pathname === `/package` && <span>Packages</span>) ||
          (location.pathname === `/purchase-history` && (
            <span>Purchase History</span>
          )) ||
          (location.pathname === `/services` && <span>Services</span>) ||
          (location.pathname === `/notifications` && (
            <span>Notification Detail</span>
          ))}
      </h5>
    </React.Fragment>
  );
};

export default PageTitle;
