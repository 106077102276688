import React, { useCallback, useEffect, useRef, useState } from "react";
import Flatpickr from "react-flatpickr";
import DataTable from "react-data-table-component";
import { Card, Form } from "react-bootstrap";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import LoopIcon from "@mui/icons-material/Loop";
import { Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import { ListHitReportReq } from "../../../Api/Reporting/HitReport/HitReportReq";
import { ListHitReportRes } from "../../../Api/Reporting/HitReport/HitReportRes";
import { CustomTableStyle } from "../../../Components/Common/constants";
import common from "../../../Components/Common/common";

const HitReport = () => {
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [query, setQuery] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [selectedDate, setSelectedDate] = useState([]);
  const [totalRowCount, setTotalRowCount] = useState("");
  const [perPage, setPerPage] = useState(10);
  const flatpickrRef = useRef(null);
  const [currentPage, setCurrentPage] = useState(1);

  let [startDate, endDate] = selectedDate;

  const handleDateChange = (selectedDates) => {
    setCurrentPage(1);
    setSelectedDate(selectedDates);
    // if (selectedDates[1] !== undefined) {
    //   fetchData(selectedDates[0], selectedDates[1], query);
    // }
  };

  const generalSettingData = useSelector(
    (state) => state.settingsData.settingsData
  );

  const otherSettingsData = generalSettingData?.filter(
    (item) => item.settings_category === "other"
  );

  const dateFormat = otherSettingsData?.find(
    (date) => date.field_name === "DEFAULT_DATE_FORMAT"
  )?.field_value;

  const handleReset = () => {
    setQuery("");
    if (flatpickrRef.current) {
      flatpickrRef.current.flatpickr.clear();
    }
    setCurrentPage(1);
    setSelectedDate([]);
  };

  const fetchData = useCallback(
    async (
      startDate = null,
      endDate = null,
      query = "",
      isDashboardView = false,
      page = 1,
      limit = 10
    ) => {
      // setIsLoading(true);
      try {
        const { url, requestOptions } = ListHitReportReq({
          query,
          startDate,
          endDate,
          token,
          isDashboardView,
          page: page || 1,
          limit: limit || 10,
        });

        const response = await fetch(url.toString(), requestOptions);
        const result = await response.json();
        const responseData = common.decrypt(result.data);
        setTotalRowCount(responseData?.totalRecords);
        setCurrentPage(page);
        ListHitReportRes({
          result,
          response,
          setData,
          responseData: responseData.data,
        });
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        return null;
      }
    },
    [token]
  );

  // useEffect(() => {
  //   setCurrentPage(1);
  //   fetchData(null, null, query, _, 1, perPage);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [fetchData]);

  // useEffect(() => {
  //   setCurrentPage(1);
  //   if (selectedDate?.length === 2)
  //     fetchData(selectedDate[0], selectedDate[1], query, _, 1, perPage);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [selectedDate]);

  // useEffect(() => {
  //   setCurrentPage(1);
  //   const debouncedFetchData = _.debounce(() => {
  //     if (selectedDate?.length === 2)
  //       fetchData(selectedDate[0], selectedDate[1], query, _, 1, perPage);
  //     else fetchData(null, null, query, _, 1, perPage);
  //   }, 500);

  //   debouncedFetchData();

  //   return () => {
  //     debouncedFetchData.cancel();
  //   };
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [query]);

  useEffect(() => {
    const debouncedFetchData = _.debounce(() => {
      fetchData(startDate, endDate, query, _, currentPage, perPage);
    }, 500);

    if (query) {
      debouncedFetchData();
    }
    return () => {
      debouncedFetchData.cancel();
    };
  }, [fetchData, query, startDate, endDate, perPage, currentPage]);

  useEffect(() => {
    if (startDate && endDate) {
      fetchData(startDate, endDate, query, _, currentPage, perPage);
    }
  }, [fetchData, query, startDate, endDate, perPage, currentPage]);

  useEffect(() => {
    if (!query && !(startDate && endDate)) {
      fetchData(startDate, endDate, query, _, currentPage, perPage);
    }
  }, [fetchData, query, startDate, endDate, perPage, currentPage]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handlePerRowsChange = async (newPerPage) => {
    setCurrentPage(1);
    setPerPage(newPerPage);
  };

  const columns = [
    {
      name: <span>Website</span>,
      selector: (row) => (
        <span onClick={() => navigate(`/hit-report/${row.assets_id}`)}>
          <span className="fw-bold cursor-pointer">{row.url || " - "}</span>
        </span>
      ),
      grow: 2,
    },
    {
      name: <span>Desktop Hits</span>,
      selector: (row) =>
        // row.desktop_hits === "0" ? (
        //   <>-</>
        // ) : (
        //   <>
        //     <span>{common.formatNumberWithCommas(row.desktop_hits)}</span>
        //   </>
        // ),
        row.desktop_hits != null && row.desktop_hits !== "0" ? (
          <span>{common.formatNumberWithCommas(row.desktop_hits)}</span>
        ) : (
          <span>-</span>
        ),
    },
    {
      name: <span>Mobile Hits</span>,
      selector: (row) =>
        // row.mobile_hits === "0" ? (
        //   <>-</>
        // ) : (
        //   <>
        //     <span>{common.formatNumberWithCommas(row.mobile_hits)}</span>
        //   </>
        // ),
        row.mobile_hits != null && row.mobile_hits !== "0" ? (
          <span>{common.formatNumberWithCommas(row.mobile_hits)}</span>
        ) : (
          <span>-</span>
        ),
    },
  ];

  return (
    <>
      <Card className="table-main-card">
        <Box className="table-card-header">
          <Box className="left-side">
            <Box className="search-box">
              <Form.Control
                className="search bg-light border-light border-0"
                type="text"
                placeholder="Search for URL..."
                value={query}
                onChange={(e) => {
                  setCurrentPage(1);
                  setQuery(e.target.value);
                }}
              />
              <i className="ri-search-line search-icon"></i>
            </Box>
            <Box className="dateinput">
              <Flatpickr
                placeholder="Select Date Range"
                ref={flatpickrRef}
                className="form-control bg-light border-light border-0"
                options={{
                  mode: "range",
                  dateFormat: `${dateFormat}`,
                }}
                value={selectedDate}
                onChange={handleDateChange}
              />
            </Box>
            <button
              className="btn btn-primary reset-btn"
              // onClick={() => {
              //   if (currentPage !== 1 || selectedDate || query) handleReset();
              // }}
              onClick={handleReset}
            >
              <LoopIcon />
            </button>
          </Box>
        </Box>
        <Card.Body>
          {isLoading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "50vh",
              }}
            >
              <Spinner height={100} width={100} />
            </div>
          ) : (
            // <DataTable
            //   columns={columns}
            //   data={data}
            //   fixedHeader
            //   fixedHeaderScrollHeight="700px"
            //   pagination
            //   customStyles={CustomTableStyle}
            //   className="table-design"
            // />
            <DataTable
              columns={columns}
              data={data}
              fixedHeader
              fixedHeaderScrollHeight="700px"
              progressPending={isLoading}
              pagination
              paginationServer
              paginationDefaultPage={currentPage}
              paginationTotalRows={totalRowCount}
              onChangePage={handlePageChange}
              onChangeRowsPerPage={handlePerRowsChange}
              customStyles={CustomTableStyle}
              className="table-design"
            />
          )}
        </Card.Body>
      </Card>
    </>
  );
};

export default HitReport;
