import Modal from "react-bootstrap/Modal";

const ViewEmailLogModal = ({ currentRow, isTemplet, ...props }) => {
  const allCurrentAttachments = JSON.parse(currentRow.attachments);

  const RenderHTMLContent = ({ htmlString }) => {
    return <div dangerouslySetInnerHTML={{ __html: htmlString }} />;
  };

  const capitalEachLetterOfWord = (str) => {
    let splitStr = str.toLowerCase().split(" ");
    for (let i = 0; i < splitStr.length; i++) {
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(" ");
  };

  const iterateThePdfs = (pdfs) => {
    if (typeof pdfs === "object") {
      let key = Object.keys(pdfs);
      return (
        <div>
          {key?.map(
            (item, index) =>
              pdfs[item]?.length !== 0 && (
                <div key={`section-${index}`}>
                  <h3>
                    {capitalEachLetterOfWord(item.replaceAll("-", " "))} :{" "}
                  </h3>
                  {pdfs[item]?.map((pdf, pdfIndex) => (
                    <embed
                      key={`pdf-${index}-${pdfIndex}`}
                      name={pdfs[item]}
                      height="100%"
                      width="100%"
                      src={pdf}
                      alt={pdfs[item]}
                    />
                  ))}
                </div>
              )
          )}
        </div>
      );
    }
  };

  const iterateTheImage = (images) => {
    if (typeof images === "object") {
      let key = Object.keys(images);
      return (
        <div>
          {key?.map((item) => {
            return (
              images[item]?.length !== 0 && (
                <div>
                  <h3>{item} : </h3>
                  {images[item]?.map((image) => {
                    return (
                      <div>
                        <img
                          name={image[item]}
                          src={image}
                          className="avatar-xl rounded-circle flex-shrink-0 border"
                          alt={images[item]}
                        />
                      </div>
                    );
                  })}
                </div>
              )
            );
          })}
        </div>
      );
    }
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {isTemplet ? (
            <strong>Email Preview</strong>
          ) : (
            <strong>Attachment Preview</strong>
          )}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          {isTemplet ? (
            <RenderHTMLContent htmlString={currentRow.templateData} />
          ) : (
            <>
              {allCurrentAttachments?.pdf !== null &&
                iterateThePdfs(allCurrentAttachments.pdf)}
              {allCurrentAttachments?.images !== null &&
                iterateTheImage(allCurrentAttachments.images)}
            </>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ViewEmailLogModal;
