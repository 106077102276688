import getChartColorsArray from "../../../../Components/Common/ChartsDynamicColor";
import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useParams } from "react-router-dom";

import FunctionsIcon from "@mui/icons-material/Functions";
import DownloadSharpIcon from "@mui/icons-material/DownloadSharp";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { Col, Row } from "react-bootstrap";

import common from "../../../../Components/Common/common";
// import { Box, colors } from "@mui/material";
import { Box, Skeleton } from "@mui/material";
import { formatDateTime } from "../../../../Components/Common/FormatDateTIme";

const DayLinewithDataLabels = ({ dataColors }) => {
  var LinewithDataLabelsColors = getChartColorsArray(dataColors);

  var options = {
    chart: {
      height: 380,
      type: "line",
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    colors: LinewithDataLabelsColors,
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: [3, 3],
      curve: "straight",
    },
    title: {
      text: "Average Desktop & Mobile Hits",
      align: "left",
      style: {
        fontWeight: 500,
      },
    },
    grid: {
      row: {
        colors: ["transparent", "transparent"], // takes an array which will be repeated on columns
        opacity: 0.2,
      },
      borderColor: "#f1f1f1",
    },
    markers: {
      style: "inverted",
      size: 6,
    },
    xaxis: {
      // categories: ,
      title: {
        text: "Day",
      },
    },
    yaxis: {
      title: {
        text: "Hits",
      },
      min: 0,
      max: 200,
    },
    legend: {
      position: "top",
      horizontalAlign: "right",
      floating: true,
      offsetY: -25,
      offsetX: -5,
    },
    responsive: [
      {
        breakpoint: 600,
        options: {
          chart: {
            toolbar: {
              show: false,
            },
          },
          legend: {
            show: false,
          },
        },
      },
    ],
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const token = localStorage.getItem("token");

  const fetchData = async () => {
    let userData = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    const url = `${process.env.REACT_APP_BASE_URL}asset/analytics-day-linechart`;
    await fetch(url, userData).then(async (data) => {
      const res = await data.json();

      return res;
    });
  };

  var series = [
    {
      name: "Desktop Hits",
      // data: desktopHits,
    },
    {
      name: "Mobile Hits",
      // data: mobileHits,
    },
  ];

  return (
    <>
      <ReactApexChart
        dir="ltr"
        options={options}
        series={series}
        type="line"
        height="380"
        width="100%"
        className="apex-charts"
      />
    </>
  );
};

const LinewithDataLabels = ({ dataColors, year }) => {
  const token = localStorage.getItem("token");

  const [webMobile, setWebMobile] = useState("");

  useEffect(() => {
    getLineWithDataLabels();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [year]);

  const params = useParams();
  const assetsId = params.id;

  const getLineWithDataLabels = async () => {
    let userData = {
      assets_id: assetsId,
      year: year,
    };
    let reqData = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(userData),
    };
    let webMobileHits = await fetch(
      `${process.env.REACT_APP_BASE_URL}asset/analytics-web-mobile-hits`,
      reqData
    );
    let data1 = await webMobileHits.json();
    const decryptedData = common.decrypt(data1.data);
    data1.data = decryptedData;

    if (data1.status === "success") {
      setWebMobile(data1.data);
    }
    if (data1.status === "fail") {
      console.error("something went wrong please try again later");
    }
  };

  useEffect(() => {
    if (webMobile && webMobile.mobileHits && webMobile.desktopHits) {
      const maxHits = Math.max(
        ...webMobile.mobileHits,
        ...webMobile.desktopHits
      );
      const updatedMax = maxHits + 1000;
      setOptions((prevOptions) => ({
        ...prevOptions,
        yaxis: {
          ...prevOptions.yaxis,
          max: updatedMax,
        },
      }));
    }
  }, [webMobile]);

  var series = [
    {
      name: "Mobile Hits",
      data: webMobile?.mobileHits,
    },
    {
      name: "Desktop Hits",
      data: webMobile?.desktopHits,
    },
  ];

  const [options, setOptions] = useState({
    chart: {
      height: 380,
      type: "line",
      zoom: {
        enabled: true,
      },
      toolbar: {
        show: false,
      },
    },
    colors: ["#E91E63", "#FF9800"],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: [3, 3],
      curve: "straight",
    },
    title: {
      text: "Average Mobile & Desktop Hits",
      align: "left",
      style: {
        fontWeight: 500,
        color: "#1A2232",
      },
    },
    grid: {
      row: {
        colors: ["transparent", "transparent"], // takes an array which will be repeated on columns
        opacity: 0.2,
      },
      borderColor: "#f1f1f1",
    },
    markers: {
      style: "inverted",
      size: 6,
    },
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      title: {
        text: "Month",
      },
    },
    yaxis: {
      title: {
        text: "Hits",
      },
      min: 0,
      max: 1800,
      fillColor: "#F5F7F8",
    },
    legend: {
      position: "top",
      horizontalAlign: "right",
      floating: true,
      offsetY: -25,
      offsetX: -5,
    },
    responsive: [
      {
        breakpoint: 600,
        options: {
          chart: {
            toolbar: {
              show: false,
            },
          },
          legend: {
            show: false,
          },
        },
      },
    ],
  });

  return (
    <React.Fragment>
      {webMobile ? (
        <ReactApexChart
          dir="ltr"
          options={options}
          series={series}
          type="line"
          height="380"
          className="apex-charts"
        />
      ) : (
        "" // You can show a loading indicator or any other message here
      )}
    </React.Fragment>
  );
};

const WeekLinewithDataLabels = ({ dataColors }) => {
  const [mobileHits, setMobileHits] = useState([]);
  const [desktopHits, setDesktopHits] = useState([]);
  const [weekSet, setWeek] = useState([]);

  var options = {
    chart: {
      height: 380,
      type: "line",
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: [3, 3],
      curve: "straight",
    },
    title: {
      text: "Average Desktop & Mobile Hits",
      align: "left",
      style: {
        fontWeight: 500,
      },
    },
    grid: {
      row: {
        colors: ["transparent", "transparent"], // takes an array which will be repeated on columns
        opacity: 0.2,
      },
      borderColor: "#f1f1f1",
    },
    markers: {
      style: "inverted",
      size: 6,
    },
    xaxis: {
      categories: weekSet,
      title: {
        text: "Weeks",
      },
    },
    yaxis: {
      title: {
        text: "Hits",
      },
      min: 0,
      max: 200,
    },
    legend: {
      position: "top",
      horizontalAlign: "right",
      floating: true,
      offsetY: -25,
      offsetX: -5,
    },
    responsive: [
      {
        breakpoint: 600,
        options: {
          chart: {
            toolbar: {
              show: false,
            },
          },
          legend: {
            show: false,
          },
        },
      },
    ],
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const url = `${process.env.REACT_APP_BASE_URL}analytics-week-linechart`;
    const dataSet1 = [];
    const dataSet2 = [];
    const dataSet3 = [];
    await fetch(url)
      .then((data) => {
        const res = data.json();
        return res;
      })
      .then((res) => {
        for (const val of res) {
          dataSet1.push(val.total_mobile_hits);
          dataSet2.push(val.total_desktop_hits);
          dataSet3.push(val.week);
        }
        setMobileHits(dataSet1);
        setDesktopHits(dataSet2);
        setWeek(dataSet3);
      })
      .catch((e) => {
        console.error("error", e);
      });
  };

  var series = [
    {
      name: "Desktop Hits",
      data: desktopHits,
    },
    {
      name: "Mobile Hits",
      data: mobileHits,
    },
  ];

  return (
    <>
      <ReactApexChart
        dir="ltr"
        options={options}
        series={series}
        type="line"
        height="380"
        width="100%"
        className="apex-charts"
      />
    </>
  );
};

const ZoomableTimeseries = ({ dataColors }) => {
  // var ZoomableTimeseriesColors = getChartColorsArray(dataColors);
  const [responseTime, setResponseTime] = useState([]);
  const [formattedDate, setFormattedDate] = useState([]);
  const [formattedYear, setFormattedYear] = useState([]);

  const series = [
    {
      name: "RESPONSE TIME",
      data: [
        {
          x: formattedDate[formattedDate.length - 5],
          y: responseTime[responseTime.length - 5],
        },
        {
          x: formattedDate[formattedDate.length - 4],
          y: responseTime[responseTime.length - 4],
        },
        {
          x: formattedDate[formattedDate.length - 3],
          y: responseTime[responseTime.length - 3],
        },
        {
          x: formattedDate[formattedDate.length - 2],
          y: responseTime[responseTime.length - 2],
        },
        {
          x: formattedDate[formattedDate.length - 1],
          y: responseTime[responseTime.length - 1],
        },
      ],
    },
  ];
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const url = `${process.env.REACT_APP_BASE_URL}uptime-response-time-1d`;
    const dataSet1 = [];
    const dataSet2 = [];
    const dataSet3 = [];

    await fetch(url)
      .then((data) => {
        const res = data.json();
        return res;
      })
      .then((res) => {
        for (const val of res) {
          dataSet1.push(val.response_time);
          dataSet2.push(val.formatted_date);
          dataSet3.push(val.formatted_year);
        }
        setResponseTime(dataSet1);
        setFormattedDate(dataSet2);
        setFormattedYear(dataSet3);
      })
      .catch((e) => {});
  };
  var options = {
    chart: {
      type: "area",
      stacked: false,
      height: 350,
      zoom: {
        type: "x",
        enabled: true,
        autoScaleYaxis: true,
      },
      toolbar: {
        autoSelected: "zoom",
      },
    },
    // colors: ZoomableTimeseriesColors,
    dataLabels: {
      enabled: false,
    },
    markers: {
      size: 0,
    },
    title: {
      text: "Bounce Rate Movement",
      align: "left",
      style: {
        fontWeight: 500,
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        inverseColors: false,
        opacityFrom: 0.5,
        opacityTo: 0,
        stops: [0, 90, 100],
      },
    },
    yaxis: {
      showAlways: true,
      labels: {
        show: true,
      },
      title: {
        text: "Bounce Time",
        style: {
          fontWeight: 500,
        },
      },
    },
    xaxis: {
      type: formattedYear,
    },
    tooltip: {
      shared: false,
      y: responseTime,
    },
  };
  return (
    <React.Fragment>
      <ReactApexChart
        dir="ltr"
        options={options}
        series={series}
        type="area"
        height="350"
        className="apex-charts"
      />
    </React.Fragment>
  );
};

const ZoomableTimeseries7Days = ({ dataColors }) => {
  // var ZoomableTimeseriesColors = getChartColorsArray(dataColors);
  const [responseTime, setResponseTime] = useState([]);
  const [formattedDays, setFormattedDays] = useState([]);
  // eslint-disable-next-line
  const [formattedYear, setFormattedYear] = useState([]);

  const series = [
    {
      name: "RESPONSE TIME",
      data: [
        {
          x: formattedDays[formattedDays.length - 4],
          y: responseTime[responseTime.length - 4],
        },
        {
          x: formattedDays[formattedDays.length - 3],
          y: responseTime[responseTime.length - 3],
        },
        {
          x: formattedDays[formattedDays.length - 2],
          y: responseTime[responseTime.length - 2],
        },
        {
          x: formattedDays[formattedDays.length - 1],
          y: responseTime[responseTime.length - 1],
        },
      ],
    },
  ];
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const url = `${process.env.REACT_APP_BASE_URL}uptime-response-time-7d`;
    const dataSet1 = [];
    const dataSet2 = [];
    const dataSet3 = [];

    await fetch(url)
      .then((data) => {
        const res = data.json();
        return res;
      })
      .then((res) => {
        for (const val of res) {
          dataSet1.push(val.response_time);
          dataSet2.push(val.formatted_date);
          dataSet3.push(val.formatted_year);
        }
        setResponseTime(dataSet1);
        setFormattedDays(dataSet2);
        setFormattedYear(dataSet3);
      })
      .catch((e) => {});
  };

  var options = {
    chart: {
      type: "area",
      stacked: false,
      height: 350,
      zoom: {
        type: "x",
        enabled: true,
        autoScaleYaxis: true,
      },
      toolbar: {
        autoSelected: "zoom",
      },
    },
    dataLabels: {
      enabled: false,
    },
    markers: {
      size: 0,
    },
    title: {
      text: "Bounce Rate Movement",
      align: "left",
      style: {
        fontWeight: 500,
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        inverseColors: false,
        opacityFrom: 0.5,
        opacityTo: 0,
        stops: [0, 90, 100],
      },
    },
    yaxis: {
      showAlways: true,
      labels: {
        show: true,
      },
      title: {
        text: "Bounce Time",
        style: {
          fontWeight: 500,
        },
      },
    },
    xaxis: {
      type: formattedDays,
    },
    tooltip: {
      shared: false,
      y: responseTime,
    },
  };
  return (
    <React.Fragment>
      <ReactApexChart
        dir="ltr"
        options={options}
        series={series}
        type="area"
        height="350"
        className="apex-charts"
      />
    </React.Fragment>
  );
};

const ZoomableTimeseries30Days = ({ dataColors }) => {
  const series = [
    {
      name: "RESPONSE TIME",
      data: [],
    },
  ];

  var options = {
    chart: {
      type: "area",
      stacked: false,
      height: 350,
      zoom: {
        type: "x",
        enabled: true,
        autoScaleYaxis: true,
      },
      toolbar: {
        autoSelected: "zoom",
      },
    },
    dataLabels: {
      enabled: false,
    },
    markers: {
      size: 0,
    },
    title: {
      text: "Bounce Rate Movement",
      align: "left",
      style: {
        fontWeight: 500,
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        inverseColors: false,
        opacityFrom: 0.5,
        opacityTo: 0,
        stops: [0, 90, 100],
      },
    },
    yaxis: {
      showAlways: true,
      labels: {
        show: true,
      },
      title: {
        text: "Bounce Time",
        style: {
          fontWeight: 500,
        },
      },
    },
    xaxis: {},
    tooltip: {
      shared: false,
    },
  };
  return (
    <React.Fragment>
      <ReactApexChart
        dir="ltr"
        options={options}
        series={series}
        type="area"
        height="350"
        className="apex-charts"
      />
    </React.Fragment>
  );
};

const DonutChart = ({ data, height, width, loading }) => {
  const { desktop, mobile } = data || {};
  const DESKTOP = parseInt(desktop);
  const MOBILE = parseInt(mobile);
  const tablet = parseInt(data?.tablet) || 0;

  const [series, setSeries] = useState([0, 0, 0]);
  const [options] = useState({
    chart: {
      type: "donut",
      width: "100%",
      height: "100%",
    },
    labels: ["Desktop", "Tablet", "Mobile"],
    colors: ["#ac1a5d", "#f39e19", "#5e81c1"],
    legend: {
      position: "bottom",
      show: false,
    },
  });

  useEffect(() => {
    if (!isNaN(DESKTOP) && !isNaN(MOBILE)) {
      const total = DESKTOP + tablet + MOBILE;
      if (total === 0) {
        setSeries([0, 0, 0]);
        return;
      }
      const DESKTOPPercentage = ((DESKTOP / total) * 100).toFixed(2);
      const tabletPercentage = ((tablet / total) * 100).toFixed(2);
      const MOBILEPercentage = ((MOBILE / total) * 100).toFixed(2);
      setSeries([
        parseInt(DESKTOPPercentage),
        parseInt(tabletPercentage),
        parseInt(MOBILEPercentage),
      ]);
    }
  }, [DESKTOP, tablet, MOBILE]);

  // const isAllZero = series.every((value) => value === 0);

  if (loading) {
    return <Skeleton variant="circular" width={width} height={height} />;
  }

  if (!data || Object.keys(data).length === 0 || series.length === 0) {
    return (
      <div
        className="no-data-message"
        style={{
          width,
          height,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <h5>No Data Available</h5>
        <p>Please check back later for updated analytics.</p>
      </div>
    );
  }
  return (
    <div id="chart" className="d-flex justify-content-center">
      <ReactApexChart
        height={height}
        width={width}
        options={options}
        series={series}
        type="donut"
        className="hitapexchart"
      />
    </div>
  );
  // return (
  //   <div id="chart" className="d-flex justify-content-center">
  //     {loading ? (
  //       <Skeleton variant="circular" width={width} height={height} />
  //     ) : series.length > 0 && !isAllZero ? (
  //       <ReactApexChart
  //         height={height}
  //         width={width}
  //         options={options}
  //         series={series}
  //         type="donut"
  //       />
  //     ) : (
  //       // No data available, display the message
  //       <div className="no-data-message">
  //         <h5>No Data Available</h5>
  //         <p>Please check back later for updated analytics.</p>
  //       </div>
  //     )}
  //   </div>
  // );
};

// const DonutChart = ({ data, height, width }) => {
//   const { desktop, mobile } = data || {};
//   const DESKTOP = parseInt(desktop);
//   const MOBILE = parseInt(mobile);
//   const tablet = parseInt(data?.tablet) || 0;

//   const [series, setSeries] = useState([0, 0, 0]);
//   const [options] = useState({
//     chart: {
//       type: "donut",
//       width: "100%",
//       height: "100%",
//     },
//     labels: ["Desktop", "tablet", "Mobile"],
//     colors: ["#ac1a5d", "#f39e19", "#5e81c1"],
//     legend: {
//       position: "bottom",
//       show: false,
//     },
//     // responsive: [
//     //   {
//     //     breakpoint: 992,
//     //     options: {
//     //       chart: {
//     //         width: 300,
//     //       },
//     //     },
//     //   },
//     // ],
//   });

//   useEffect(() => {
//     if (!isNaN(DESKTOP) && !isNaN(MOBILE)) {
//       const total = DESKTOP + tablet + MOBILE;
//       // Check if total is zero before calculating percentages
//       if (total === 0) {
//         setSeries([0, 0, 0]);
//         return;
//       }
//       const DESKTOPPercentage = ((DESKTOP / total) * 100).toFixed(2);
//       const tabletPercentage = ((tablet / total) * 100).toFixed(2);
//       const MOBILEPercentage = ((MOBILE / total) * 100).toFixed(2);
//       setSeries([
//         parseInt(DESKTOPPercentage),
//         parseInt(tabletPercentage),
//         parseInt(MOBILEPercentage),
//       ]);
//     }
//   }, [DESKTOP, tablet, MOBILE]);

//   const isAllZero = series.every((value) => value === 0);
//   return (
//     <>
//       <div id="chart" className="d-flex justify-content-center">
//         {series.length > 0 && !isAllZero ? (
//           <ReactApexChart
//             height={height}
//             width={width}
//             options={options}
//             series={series}
//             type="donut"
//           />
//         ) : (
//           <div className="no-data-message">
//             <h5>No Data Available</h5>
//             <p>Please check back later for updated analytics.</p>
//           </div>
//         )}
//       </div>
//     </>
//   );
// };

const ApexLineChart = ({ data }) => {
  const [series, setSeries] = useState([]);

  useEffect(() => {
    if (Array.isArray(data) && data.length > 0) {
      const sessionPerUser = data.map((i) => i.sessionPerUser.toFixed(2));
      const totalUsers = data.map((i) => i.totalUsers);

      setSeries([
        {
          name: "Total Users",
          data: totalUsers,
        },
        {
          name: "Session Per User",
          data: sessionPerUser,
        },
      ]);
    }
  }, [data]);

  const [options] = useState({
    chart: {
      height: 350,
      type: "line",
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: [5, 7, 5],
      curve: "straight",
      dashArray: [0, 8, 5],
    },
    title: {
      text: "Users & Sessions report",
      align: "left",
      style: {
        fontSize: "14px",
        fontWeight: 500,
        color: "#495057",
      },
    },
    legend: {
      tooltipHoverFormatter: (val, opts) => {
        return (
          val +
          " - <strong>" +
          opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] +
          "</strong>"
        );
      },
    },
    markers: {
      size: 0,
      hover: {
        sizeOffset: 6,
      },
    },
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
    },
    tooltip: {
      y: [
        {
          title: {
            formatter: (val) => {
              return val;
            },
          },
        },
        {
          title: {
            formatter: (val) => {
              return val;
            },
          },
        },
      ],
    },
    grid: {
      borderColor: "#f1f1f1",
    },
  });

  return (
    <div>
      <h4 style={{ fontSize: "14px", fontWeight: 500 }}>
        Users & Sessions report
      </h4>
      <div id="chart">
        <ReactApexChart
          options={options}
          series={series}
          type="line"
          height={350}
        />
      </div>
      <div id="html-dist"></div>
    </div>
  );
};

const AreaChart = ({ data }) => {
  const [series, setSeries] = useState([]);

  useEffect(() => {
    if (data) {
      const sessionPerUser = data.map((i) => i.sessionPerUser.toFixed(2));
      const totalUsers = data.map((i) => i.totalUsers);

      setSeries([
        {
          name: "Total Users",
          data: totalUsers,
        },
        {
          name: "Session Per User",
          data: sessionPerUser,
        },
      ]);
    }
  }, [data]);

  const [options] = useState({
    chart: {
      height: 350,
      type: "area",
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    // stroke: {
    //   width: [5, 7, 5],
    //   curve: 'straight',
    //   dashArray: [0, 8, 5]
    // },
    // title: {
    //   text: 'Users & Sessions report',
    //   align: 'left',
    //   style:{
    //     fontSize:"14px",
    //     fontWeight:500,
    //     color:"#495057"
    //   }
    // },
    legend: {
      tooltipHoverFormatter: (val, opts) => {
        return (
          val +
          " - <strong>" +
          opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] +
          "</strong>"
        );
      },
    },
    markers: {
      size: 0,
      hover: {
        sizeOffset: 6,
      },
    },
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
    },
    yaxis: {
      fillColor: "#F5F7F8",
    },
    tooltip: {
      y: [
        {
          title: {
            formatter: (val) => {
              return val;
            },
          },
        },
        {
          title: {
            formatter: (val) => {
              return val;
            },
          },
        },
      ],
    },
    grid: {
      borderColor: "#f1f1f1",
    },
  });

  return (
    <div id="chart">
      <ReactApexChart
        options={options}
        series={series}
        type="area"
        height={350}
      />
    </div>
  );
};

const AnalyticsDataRadialChart = ({ data }) => {
  const parsedData = data[0]?.acquisitions_report
    ? JSON.parse(data[0]?.acquisitions_report)
    : [];

  const series = Array.isArray(parsedData)
    ? parsedData.map((item) => parseInt(item.engagedSessions))
    : [];
  const labels = Array.isArray(parsedData)
    ? parsedData.map((item) => item.firstUserDefaultChannelGroup)
    : [];
  const [colors, setColors] = useState([]);

  const options = {
    chart: {
      height: 350,
      type: "radialBar",
    },
    plotOptions: {
      radialBar: {
        dataLabels: {
          name: {
            fontSize: "22px",
          },
          value: {
            fontSize: "16px",
          },
          total: {
            show: true,
            label: "Sessions",
            formatter: function (w) {
              setColors(w.globals.colors);
              const total = w.globals.initialSeries.reduce(
                (acc, curr) => acc + parseFloat(curr),
                0
              );
              return total;
            },
          },
        },
      },
    },
    labels: labels,
  };

  return (
    <>
      <div className="row g-4">
        <div id="chart" className="col-12 col-md-8">
          <ReactApexChart
            options={options}
            series={series}
            type="radialBar"
            height={350}
          />
        </div>
        <div className="col-12 col-md-4 text-center text-md-start pb-3 pb-md-0">
          {Array.isArray(labels) &&
            labels.length > 0 &&
            labels.map((label, index) => (
              <div key={index} style={{ color: colors[index] }}>
                <span>{label}: </span>
                <span>{series[index]}</span>
              </div>
            ))}
        </div>
      </div>
    </>
  );
};

const UptimeRobotLineChart = ({
  data,
  filterDate,
  timeFormat,
  timeZone,
  dateFormat,
}) => {
  const [series, setSeries] = useState([]);
  const [dataValues, setDataValues] = useState({
    averageValue: null,
    minimumValue: null,
    maximumValue: null,
  });

  const [options, setOptions] = useState({
    chart: {
      height: 350,
      type: "area",
      stacked: false,
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    markers: {
      size: 0,
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        inverseColors: false,
        opacityFrom: 0.45,
        opacityTo: 0.05,
        stops: [20, 100, 100, 100],
      },
    },
    grid: {
      row: {
        colors: ["#f3f3f3", "transparent"],
        opacity: 0.5,
      },
    },
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
    },
  });

  useEffect(() => {
    const getDataSeries = (data) => {
      if (data.last1Year)
        return data.last1Year.map((a) => a.average_response_time);
      if (data.last7Days) return data.last7Days.map((a) => a.dataForWeek);
      if (data.last24Hours) return data.last24Hours.map((a) => a.dataForHour);
      if (data.last6Months)
        return data.last6Months.map((a) => a.average_response_time);
      return null;
    };

    const seriesData = data ? getDataSeries(data) : null;

    if (seriesData) {
      setSeries([{ name: "average response time", data: seriesData }]);
    }

    const dateFilters = {
      1: Array.isArray(data?.last24Hours)
        ? data?.last24Hours?.map((a) => {
            const formattedDateTime = formatDateTime(
              a.updatedAt ? a.updatedAt : a.hour,
              dateFormat,
              timeZone,
              timeFormat
            );
            return `${formattedDateTime.timeZone.formattedTimed}`;
          })
        : [],
      // 7: Array.isArray(data?.last7Days)
      //   ? data.last7Days.map((a) => a.formattedDate)
      //   : [],
      7: Array.isArray(data?.last7Days)
        ? data.last7Days.map(
            (a) =>
              formatDateTime(a.formattedDate, dateFormat, timeZone, timeFormat)
                .timeZone.formattedDateFinal
          )
        : [],
      365: Array.isArray(data?.last1Year)
        ? data.last1Year.map((a) => a.monthName)
        : [],
      180: Array.isArray(data?.last6Months)
        ? data.last6Months.map((a) => a.monthName)
        : [],
    };
    const dateFilter = dateFilters[parseInt(filterDate)] || null;
    if (dateFilter) {
      setOptions((prevOptions) => ({
        ...prevOptions,
        xaxis: { ...prevOptions.xaxis, categories: dateFilter },
      }));
    } else if (filterDate) {
      console.warn("Unexpected filter date value:", filterDate);
    }

    if (data) {
      const filterData = (arr, key) => arr.filter((item) => item[key] !== 0);
      const newData = data.last1Year
        ? filterData(data.last1Year, "average_response_time")
        : data.last7Days
        ? filterData(data.last7Days, "dataForWeek")
        : data.last24Hours
        ? filterData(data.last24Hours, "dataForHour")
        : data.last6Months
        ? filterData(data.last6Months, "average_response_time")
        : [];

      const values = newData.map(
        (a) => a.average_response_time || a.dataForHour || a.dataForWeek
      );
      const maxValue = Math.max(...values);
      const minValue = Math.min(...values);

      const sum = newData
        .map((a) =>
          parseInt(a.average_response_time || a.dataForHour || a.dataForWeek)
        )
        .reduce((acc, curr) => acc + curr, 0);
      const averageValue = sum / newData.length;

      setDataValues({
        averageValue,
        minimumValue: minValue,
        maximumValue: maxValue,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, filterDate]);

  const dataValuesArray = [
    {
      headerName: "Average",
      bodyName: (
        <>
          <FunctionsIcon />{" "}
          {dataValues.averageValue
            ? `${common.formatNumberWithCommas(
                parseFloat(dataValues.averageValue).toFixed(2)
              )} ms`
            : "-"}
        </>
      ),
    },
    {
      headerName: "Minimum",
      bodyName: (
        <>
          <DownloadSharpIcon />{" "}
          {dataValues.minimumValue
            ? `${common.formatNumberWithCommas(
                parseFloat(dataValues.minimumValue).toFixed(2)
              )} ms`
            : "-"}
        </>
      ),
    },
    {
      headerName: "Maximum",
      bodyName: (
        <>
          <FileUploadIcon />{" "}
          {dataValues.maximumValue
            ? `${common.formatNumberWithCommas(
                parseFloat(dataValues.maximumValue).toFixed(2)
              )} ms`
            : "-"}
        </>
      ),
    },
  ];

  return (
    <div>
      <div id="chart">
        <ReactApexChart
          options={options}
          series={series}
          type="area"
          height={350}
        />
      </div>
      <Row className="g-4">
        {Array.isArray(dataValuesArray) &&
          dataValuesArray.length > 0 &&
          dataValuesArray.map((item, index) => (
            <Col xs={4} key={index} className="incident-column">
              <Box key={index} className="responce-table-card">
                <h1 className="value">{item?.bodyName}</h1>
                <span className="title"> {item?.headerName} </span>
              </Box>
            </Col>
          ))}
      </Row>
    </div>
  );
};

const BarChart = ({ data = [] }) => {
  const seriesData = data
    .map((item) => (item?.avgPageviews).toString() || 0)
    .filter((value) => !isNaN(value));

  const categories = Array.from(
    { length: data.length },
    (_, index) => `Month ${index + 1}`
  );

  const options = {
    chart: {
      height: 350,
      type: "bar",
      zoom: { enabled: false },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return val;
      },
      offsetY: -20,
      style: { fontSize: "12px", colors: ["#304758"] },
    },
    plotOptions: {
      bar: {
        dataLabels: {
          position: "top",
        },
      },
    },
    xaxis: {
      categories: categories,
      position: "bottom",
      axisBorder: { show: false },
      axisTicks: { show: false },
      crosshairs: {
        fill: {
          type: "gradient",
          gradient: {
            colorFrom: "#D8E3F0",
            colorTo: "#BED1E6",
            stops: [0, 100],
            opacityFrom: 0.4,
            opacityTo: 0.5,
          },
        },
      },
      tooltip: { enabled: true },
    },
    yaxis: {
      axisBorder: { show: false },
      axisTicks: { show: false },
      labels: {
        show: false,
        formatter: function (val) {
          return val;
        },
      },
    },
  };

  const series = [{ name: "Avg Pageviews", data: seriesData }];

  return (
    <div id="chart">
      <ReactApexChart
        options={options}
        series={series}
        type="bar"
        height={350}
      />
    </div>
  );
};

const BasicBarChart = ({ data, lastStatus }) => {
  const getColor = (status) => {
    if (status === "D") return "#FF0000";
    if (status === "U") return "#00FF00";
    if (status === "null") {
      return lastStatus === "D" ? "#FF0000" : "#00FF00";
    }
  };

  const seriesData =
    data?.map((item) => ({
      x: item?.hour,
      y: item?.avg_uptime
        ? parseFloat(item?.avg_uptime).toFixed(2) === "0.00"
          ? "100"
          : parseFloat(item?.avg_uptime).toFixed(2)
        : "100",
      fillColor: getColor(item?.status),
    })) || [];

  const options = {
    chart: {
      type: "bar",
      zoom: { enabled: false },
      toolbar: { show: false },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        endingShape: "rounded",
        colors: {
          ranges: seriesData.map((item) => ({
            from: item.y,
            to: item.y,
            color: item.fillColor,
          })),
        },
      },
    },
    dataLabels: { enabled: false },
    yaxis: {
      show: false,
      min: 0,
      max: 100,
      tickAmount: 5,
    },
    xaxis: {
      labels: { show: false },
      axisBorder: { show: false },
    },
    axisBorder: { show: false },
    stroke: {
      show: false,
      width: 0,
      colors: ["transparent"],
    },
  };

  const series = [
    {
      name: "Avg Uptime",
      data: seriesData,
    },
  ];

  return (
    <div>
      <div id="chart">
        <ReactApexChart
          options={options}
          series={series}
          type="bar"
          height={95}
        />
      </div>
      <div id="html-dist"></div>
    </div>
  );
};

export default BasicBarChart;

const GradientChart = ({ data }) => {
  const options = {
    chart: {
      height: 350,
      type: "line",
      zoom: {
        enabled: false,
      },
    },
    stroke: {
      width: 5,
      curve: "smooth",
    },
    xaxis: {
      type: "category",
      categories: data?.map((item) => item.month), // Use months from data for x-axis
      tickAmount: 12, // Assuming 12 months in a year
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: "dark",
        gradientToColors: ["#FDD835"],
        shadeIntensity: 1,
        type: "horizontal",
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 100, 100, 100],
      },
    },
  };

  const series = [
    {
      name: "Pages Per Session",
      data: data?.map((item) => item.pagesPerSession), // Use pagesPerSession data
    },
    {
      name: "Average Session Duration",
      data: data?.map((item) => item.avgSessionDuration), // Use avgSessionDuration data
    },
  ];

  return (
    <div id="chart">
      <ReactApexChart
        options={options}
        series={series}
        type="line"
        height={350}
      />
    </div>
  );
};

// This is original
const PageSizeBarChart = ({ pageDetail }) => {
  const getByteOrMega = (size) => {
    const byte = size / 1024;
    if (byte < 1000) return `${byte.toFixed(2)} KB`;
    return `${(byte / 1000).toFixed(2)} MB`;
  };
  const formatSize = (size, opts) => {
    if (opts === 1) {
      return getByteOrMega(size);
    }
    const labelName = opts.w.config.series.find(
      (item) => parseFloat(item.data[0]) === parseFloat(size)
    );
    return size < 1000
      ? [labelName?.name, getByteOrMega(size)]
      : [labelName?.name, getByteOrMega(size)];
  };
  const series = [
    {
      name: "Javascript",
      data:
        pageDetail?.Javascript?.size !== undefined
          ? [pageDetail.Javascript.size]
          : [],
    },
    {
      name: "Image",
      data:
        pageDetail?.Image?.size !== undefined ? [pageDetail.Image.size] : [],
    },
    {
      name: "Fonts",
      data:
        pageDetail?.Fonts?.size !== undefined ? [pageDetail.Fonts.size] : [],
    },
    {
      name: "CSS",
      data: pageDetail?.CSS?.size !== undefined ? [pageDetail.CSS.size] : [],
    },
    {
      name: "Other",
      data:
        pageDetail?.Other?.size !== undefined ? [pageDetail.Other.size] : [],
    },
    {
      name: "Html",
      data: pageDetail?.Html?.size !== undefined ? [pageDetail.Html.size] : [],
    },
  ];

  const options = {
    chart: {
      type: "bar",
      stacked: true,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        dataLabels: {
          position: "center",
          total: {
            enabled: false,
          },
        },
      },
    },
    dataLabels: {
      enabled: true,
      formatter: formatSize,
      style: {
        colors: ["#fff"],
        fontSize: "12px",
        fontWeight: 900,
      },
      useHTML: true,
      dropShadow: {
        enabled: false,
      },
    },
    fill: {
      colors: ["#4277A5", "#5A7A98", "#9353A1", "#70A1CB", "#C86DA4"],
    },
    stroke: {
      width: 0,
      colors: ["#fff"],
      show: false,
    },
    title: {
      text: "",
      show: false,
    },
    yaxis: {
      show: true,
      labels: {
        style: {
          colors: ["#000"],
          fontSize: "0px",
        },
      },
    },
    xaxis: {
      labels: {
        show: true,
        style: {
          colors: ["#000"],
          fontSize: "0px",
        },
      },
      axisBorder: {
        show: false,
      },
    },
    tooltip: {
      enabled: true,
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        if (
          series[seriesIndex] &&
          series[seriesIndex][dataPointIndex] !== undefined
        ) {
          const value = series[seriesIndex][dataPointIndex];
          const seriesName = w.globals.seriesNames[seriesIndex];
          const totalPageSize = pageDetail[seriesName].totalPageSize;
          const totalPageRequest = pageDetail[seriesName].totalPageRequest;
          const totalRequests = pageDetail[seriesName].requests;

          return `
            <div class="apexcharts-tooltip-title">${seriesName}</div>
            ${
              value !== "- "
                ? `<div class="apexcharts-tooltip-text">
              <span class="apexcharts-tooltip-text-y-value">${formatSize(
                value,
                1
              )} of ${getByteOrMega(pageDetail.totalPageSize)} size</span>
            </div>`
                : ""
            }

            ${
              totalPageSize !== "- "
                ? `<div class="apexcharts-tooltip-text">
              <span class="apexcharts-tooltip-text-y-value">${totalPageSize}% of total page size</span>
            </div>`
                : ""
            }

            ${
              totalRequests !== "- "
                ? `<div class="apexcharts-tooltip-text">
              <span class="apexcharts-tooltip-text-y-value">${totalRequests} of ${pageDetail.totalRequest} request</span>
            </div>`
                : ""
            }

            ${
              totalPageRequest !== "- "
                ? `<div class="apexcharts-tooltip-text">
              <span class="apexcharts-tooltip-text-y-value">${totalPageRequest}% of total page request</span>
            </div>`
                : ""
            }

          `;
        }
        return "";
      },
    },
    legend: {
      position: "top",
      horizontalAlign: "left",
      offsetX: 40,
      show: false,
    },
  };

  return (
    <div id="chart">
      <ReactApexChart
        options={options}
        series={series}
        type="bar"
        height={150}
      />
    </div>
  );
};
//This is original
const TotalRequestPageSizeBarChart = (props) => {
  const { pageDetail } = props;

  // const formatSize = (size) => {
  //   return size < 1000 ? `${size} KB` : `${(size / 1024).toFixed(2)} MB`;
  // };

  const getByteOrMega = (size) => {
    const byte = size / 1024;
    if (byte < 1000) return `${byte.toFixed(2)} KB`;
    return `${(byte / 1000).toFixed(2)} MB`;
  };

  const formatPercentage = (val, data) => {
    const labelName = data.w.config.series.find(
      (item) => item.data[0] === val.toString()
    );
    if (val !== 0) {
      return [labelName?.name, val + "%"];
    } else {
      return `${val}%`;
    }
  };

  const options = {
    chart: {
      type: "bar",
      stacked: true,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        dataLabels: {
          total: {
            enabled: false,
            offsetX: 0,
            style: {
              fontSize: "13px",
              fontWeight: 900,
              colors: "#dsf3ds",
            },
          },
        },
      },
    },
    dataLabels: {
      enabled: true,
      formatter: formatPercentage,
      style: {
        colors: ["#fff"],
        fontSize: "13px",
        fontWeight: 900,
      },
      useHTML: true,
    },
    fill: {
      colors: ["#4277A5", "#5A7A98", "#9353A1", "#70A1CB", "#C86DA4"],
    },
    stroke: {
      width: 0,
      colors: ["#fff"],
      show: false,
    },
    title: {
      text: "",
      show: false,
    },
    yaxis: {
      //show: false,
      show: true,
      labels: {
        style: {
          colors: ["#000"],
          fontSize: "0px",
        },
      },
    },
    xaxis: {
      labels: {
        //show: false,
        show: true,
        style: {
          colors: ["#000"],
          fontSize: "0px",
        },
      },
      axisBorder: {
        show: false,
      },
    },
    tooltip: {
      enabled: true,
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        if (
          series[seriesIndex] &&
          series[seriesIndex][dataPointIndex] !== undefined
        ) {
          // const value = series[seriesIndex][dataPointIndex];
          // const label = w.globals.labels[dataPointIndex];
          const seriesName = w.globals.seriesNames[seriesIndex];
          const totalPageSize = pageDetail[seriesName].totalPageSize;
          const totalSize = pageDetail[seriesName].size;
          const totalPageRequest = pageDetail[seriesName].totalPageRequest;
          const totalRequests = pageDetail[seriesName].requests;

          return `
            <div class="apexcharts-tooltip-title">${seriesName}</div>
            <div class="apexcharts-tooltip-text">
              <span class="apexcharts-tooltip-text-y-value">${getByteOrMega(
                totalSize
              )} of ${getByteOrMega(pageDetail.totalPageSize)} size</span>
            </div>
            <div class="apexcharts-tooltip-text">
              <span class="apexcharts-tooltip-text-y-value">${totalPageSize}% of total page size</span>
            </div>
            <div class="apexcharts-tooltip-text">
              <span class="apexcharts-tooltip-text-y-value">${totalRequests} of ${
            pageDetail.totalRequest
          } request</span>
            </div>
            <div class="apexcharts-tooltip-text">
              <span class="apexcharts-tooltip-text-y-value">${totalPageRequest}% of total page request</span>
            </div>
          `;
        }
        return "";
      },
    },
    legend: {
      position: "top",
      horizontalAlign: "left",
      offsetX: 40,
      show: false,
    },
  };

  const series = [
    {
      name: "Javascript",
      data:
        pageDetail?.Javascript.totalPageRequest !== undefined
          ? [pageDetail?.Javascript.totalPageRequest]
          : [],
    },
    {
      name: "Image",
      data:
        pageDetail?.Image.totalPageRequest !== undefined
          ? [pageDetail?.Image.totalPageRequest]
          : [],
    },
    {
      name: "Fonts",
      data:
        pageDetail?.Fonts.totalPageRequest !== undefined
          ? [pageDetail?.Fonts.totalPageRequest]
          : [],
    },
    {
      name: "CSS",
      data:
        pageDetail?.CSS.totalPageRequest !== undefined
          ? [pageDetail?.CSS.totalPageRequest]
          : [],
    },
    {
      name: "Other",
      data:
        pageDetail?.Other.totalPageRequest !== undefined
          ? [pageDetail?.Other.totalPageRequest]
          : [],
    },
    {
      name: "Html",
      data:
        pageDetail?.Html.totalPageRequest !== undefined
          ? [pageDetail?.Html.totalPageRequest]
          : [],
    },
  ];

  return (
    <div id="chart">
      <ReactApexChart
        options={options}
        series={series}
        type="bar"
        height={150}
      />
    </div>
  );
};

const SplineAreaChart = ({ data, filter }) => {
  const [series, setSeries] = useState([]);

  const [options, setOptions] = useState({
    chart: {
      type: "area",
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    xaxis: {
      // type: "datetime",
      categories: [
        "2018-09-19T00:00:00.000Z",
        "2018-09-19T01:30:00.000Z",
        "2018-09-19T02:30:00.000Z",
        "2018-09-19T03:30:00.000Z",
        "2018-09-19T04:30:00.000Z",
        "2018-09-19T05:30:00.000Z",
        "2018-09-19T06:30:00.000Z",
      ],
    },
    // fill: {
    //   type: "solid",
    //   color: "red",
    // },
    colors: ["#49b9fe"],
    tooltip: {
      x: {
        format: "dd/MM/yy HH:mm",
      },
    },
  });

  useEffect(() => {
    const getDataSeries = (data) => {
      if (data.Today) return data.Today.map((a) => a.dataForDay).reverse();
      if (data.last1Year) return data.last1Year.map((a) => a.active_users);
      if (data.last7Days) return data.last7Days.map((a) => a.dataForWeek);
      if (data.last30Days) return data.last30Days.map((a) => a.active_users);
      if (data.last6Months)
        return data.last6Months.map((a) => a.active_users).reverse();
      return null;
    };

    const seriesData = data ? getDataSeries(data) : null;

    if (seriesData) {
      setSeries([{ name: "visitors", data: seriesData }]);
    }

    const dateFilters = {
      1: Array.isArray(data?.Today)
        ? data?.Today.map((a) => JSON.stringify(a.hour))
        : [],
      7: Array.isArray(data?.last7Days)
        ? data.last7Days.map((a) => a.formattedDate)
        : [],
      30: Array.isArray(data?.last30Days)
        ? data.last30Days.map((a) => a.day)
        : [],
      365: Array.isArray(data?.last1Year)
        ? data.last1Year.map((a) => a.monthName)
        : [],
      180: Array.isArray(data?.last6Months)
        ? data.last6Months.map((a) => a.monthName)
        : [],
    };

    const dateFilter = dateFilters[parseInt(filter)] || null;

    if (dateFilter) {
      setOptions((prevOptions) => ({
        ...prevOptions,
        xaxis: { ...prevOptions.xaxis, categories: dateFilter },
      }));
    } else if (filter) {
      console.warn("Unexpected filter date value:", filter);
    }
  }, [data, filter]);

  return (
    <>
      <ReactApexChart
        options={options}
        series={series}
        type="area"
        height={"100%"}
      />
    </>
  );
};

export {
  ZoomableTimeseries,
  ZoomableTimeseries7Days,
  ZoomableTimeseries30Days,
  LinewithDataLabels,
  WeekLinewithDataLabels,
  DayLinewithDataLabels,
  DonutChart,
  ApexLineChart,
  AnalyticsDataRadialChart,
  UptimeRobotLineChart,
  BarChart,
  AreaChart,
  GradientChart,
  BasicBarChart,
  PageSizeBarChart,
  TotalRequestPageSizeBarChart,
  SplineAreaChart,
};
