import {
  Box,
  Card,
  CardContent,
  Typography,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Switch,
  Skeleton,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  getPackagesServices,
  getUserPackageLimit,
} from "../../helpers/Help/CommomApi";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import MyPackage from "./MyPackage";
import { useSelector } from "react-redux";
import { formatDateTime } from "../../Components/Common/FormatDateTIme";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import common from "../../Components/Common/common";

const AllPackage = () => {
  const userId = localStorage.getItem("user_id");
  const userData = useSelector((state) => state.userDetails.userData);
  const userRole = userData?.role;
  const userIsAdmin = userData?.isAdmin;
  const userDepartment = userData?.department_id;

  const [packageServiceData, setPackageServiceData] = useState([]);
  const [isYearly, setIsYearly] = useState(true);
  const [myPackage, setMyPackage] = useState([]);
  const [currentUserPackageStatus, setCurrentUserPackageStatus] =
    useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  const location = useLocation();
  const { selectedPlan } = location.state || {};

  const cardRefs = useRef([]);

  const generalSettingData = useSelector(
    (state) => state.settingsData.settingsData
  );
  const otherSettingsData = generalSettingData?.filter(
    (item) => item.settings_category === "other"
  );

  const dateFormat = otherSettingsData?.find(
    (date) => date.field_name === "DEFAULT_DATE_FORMAT"
  )?.field_value;

  const timeZone = otherSettingsData?.find(
    (time) => time.field_name === "DEFAULT_TIME_FORMAT"
  )?.field_value;

  const timeFormat = otherSettingsData?.find(
    (zone) => zone.field_name === "DEFAULT_TIME_ZONE"
  )?.field_value;

  const fetchPackageServiceData = async () => {
    try {
      const response = await getPackagesServices();
      const responseData = common.decrypt(response.data);
      setPackageServiceData(responseData);
      setIsLoading(false);
    } catch (error) {
      console.error(error.message);
    }
  };

  const fetchPackageServiceDataByUserId = async (userId) => {
    try {
      const response = await getPackagesServices(userId); // Fetch by userId
      const responseData = common.decrypt(response.data);
      setMyPackage(responseData);
    } catch (error) {
      console.error(error.message);
    }
  };

  useEffect(() => {
    fetchPackageServiceData();
  }, []);

  useEffect(() => {
    fetchPackageServiceDataByUserId(userId);
  }, [userId]);

  const renderValue = (service, plan) => {
    if (service.slug === "uptime_report") {
      if (service.value === "true") {
        const interval = plan.packageServiceData.find(
          (s) => s.slug === "uptime_monitoring_interval"
        )?.value;
        const unit = plan.packageServiceData.find(
          (s) => s.slug === "uptime_monitoring_unit"
        )?.value;
        return `${interval} ${unit.toLowerCase()}. monitoring interval`;
      }
      return "Not Available";
    } else if (service.slug === "performance_report") {
      if (service.value === "true") {
        const interval = plan.packageServiceData.find(
          (s) => s.slug === "performance_report_limit"
        )?.value;
        const unit = plan.packageServiceData.find(
          (s) => s.slug === "performance_report_limit_unit"
        )?.value;
        return `${interval} / ${unit.toLowerCase()}`;
      }
      return "Not Available";
    } else if (service.slug === "security_scan") {
      if (service.value === "true") {
        const interval = plan.packageServiceData.find(
          (s) => s.slug === "security_scan_report_limit"
        )?.value;
        const unit = plan.packageServiceData.find(
          (s) => s.slug === "security_scan_report_limit_unit"
        )?.value;
        return `${interval} / ${unit.toLowerCase()}`;
      }
      return "Not Available";
    }
    // else if (service.slug === "schedule_security_scan") {
    //   if (service.value === "true") {
    //     const interval = plan.packageServiceData.find(
    //       (s) => s.slug === "schedule_security_report_limit"
    //     )?.value;
    //     const unit = plan.packageServiceData.find(
    //       (s) => s.slug === "schedule_security_report_limit_unit"
    //     )?.value;
    //     return `${interval} / ${unit.toLowerCase()}`;
    //   }
    //   return "Not Available";
    // }
    else if (service.slug === "analytics_reports") {
      if (service.value === "true") {
        const interval = plan.packageServiceData.find(
          (s) => s.slug === "analytics_services"
        )?.value;

        return `${interval}`;
      }
      return "Not Available";
    }

    if (service.value === null) return "Not Available";
    if (service.value === "true") return "Yes";
    if (service.value === "false") return "No";
    if (Array.isArray(service.value)) return service.value.join(", ");
    return service.value;
  };

  const shouldDisplayService = (service) => {
    return ![
      "uptime_monitoring_interval",
      "uptime_monitoring_unit",
      "performance_report_limit",
      "performance_report_limit_unit",
      "security_scan_report_limit",
      "security_scan_report_limit_unit",
      // "schedule_security_report_limit",
      // "schedule_security_report_limit_unit",
      "analytics_services",
      "custom_package",
    ].includes(service.slug);
  };

  const isCurrentPackage = (plan) => {
    return myPackage && plan?.packageId === myPackage[0]?.packageId;
  };

  const fetchUserPackageLimit = async (user_id) => {
    try {
      let response = await getUserPackageLimit(user_id);
      const responseData = common.decrypt(response.data);
      if (
        responseData?.package_end_date &&
        responseData?.package_end_date !== null
      ) {
        const packagEndDate =
          formatDateTime(
            responseData?.package_end_date,
            dateFormat,
            timeZone,
            timeFormat
          ).timeZone.formattedDateFinal +
          " - " +
          formatDateTime(
            responseData?.package_end_date,
            dateFormat,
            timeZone,
            timeFormat
          ).timeZone.formattedTimed;

        responseData["package_end_date"] = packagEndDate || null;
      }
      setCurrentUserPackageStatus(responseData || null);
    } catch (error) {
      console.error(error.message);
    }
  };

  useEffect(() => {
    fetchUserPackageLimit(userId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedPlan && selectedPlan?.package_id) {
      const matchingCardIndex = packageServiceData.findIndex(
        (plan) => plan.packageId === selectedPlan?.package_id
      );

      if (matchingCardIndex !== -1 && cardRefs.current[matchingCardIndex]) {
        cardRefs.current[matchingCardIndex].scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    }
  }, [selectedPlan, packageServiceData]);

  const isPackageExpired = currentUserPackageStatus?.isPackageExpired;

  const isPlanUpgradeable = (currentStatus, selectedPlan) => {
    const currentPackage = packageServiceData.find(
      (pkg) => pkg.packageId === currentStatus?.package_id
    );

    const selectedPackage = packageServiceData.find(
      (pkg) => pkg.packageId === selectedPlan.packageId
    );

    if (!currentPackage || !selectedPackage) return false;

    const currentAssets = currentPackage.packageServiceData.find(
      (service) => service.slug === "assets"
    )?.value;
    const currentUsersLimits = currentPackage.packageServiceData.find(
      (service) => service.slug === "users_limits"
    )?.value;

    const selectedAssets = selectedPackage.packageServiceData.find(
      (service) => service.slug === "assets"
    )?.value;
    const selectedUsersLimits = selectedPackage.packageServiceData.find(
      (service) => service.slug === "users_limits"
    )?.value;

    return (
      parseInt(selectedAssets) > parseInt(currentAssets) &&
      parseInt(selectedUsersLimits) > parseInt(currentUsersLimits)
    );
  };

  const renderMessage = (plan) => {
    // if (isPackageExpired) {
    if (isCurrentPackage(plan)) {
      return "This is your current plan. This plan has the same or lower limits as your current plan. Please select a higher-tier plan.";
    }
    if (!isPlanUpgradeable(currentUserPackageStatus, plan)) {
      return "This plan has the same or lower limits as your current plan. Please select a higher-tier plan.";
    }
    return "You can upgrade or reselect this plan.";
    // }
  };

  const handleSelectPlan = (plan) => {
    const message = isPackageExpired ? renderMessage(plan) : null;

    navigate("/contact-us", {
      state: {
        currentPlan: myPackage[0],
        selectedPlan: plan,
        message: message,
        isCurrentPlan: isCurrentPackage(plan),
      },
    });
  };

  return (
    <Box sx={{ padding: "15px" }}>
      {userRole !== "Super Admin" && (
        <MyPackage
          isYearly={isYearly}
          setIsYearly={setIsYearly}
          myPackage={myPackage}
          renderValue={renderValue}
          shouldDisplayService={shouldDisplayService}
          currentUserPackageStatus={currentUserPackageStatus}
        />
      )}
      {(userRole === "Super Admin" ||
        (userIsAdmin === "Y" && (userDepartment === 0 || !userDepartment))) && (
        <>
          <Typography
            variant="h4"
            align="center"
            gutterBottom
            sx={{
              fontSize: { xs: "1.3rem", sm: "2rem" },
              marginTop: { xs: "40px", sm: "60px" },
            }}
          >
            Package Plans
          </Typography>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            mb={2}
          >
            <Typography variant="body1" sx={{ mr: 2 }}>
              Monthly
            </Typography>
            <Switch
              checked={isYearly}
              onChange={() => setIsYearly(!isYearly)}
              color="primary"
            />
            <Typography variant="body1" sx={{ ml: 2 }}>
              Yearly
            </Typography>
          </Box>
          <Grid container spacing={3} justifyContent="center">
            {isLoading ? (
              <>
                {/* <ListSkeleton listsToRender={3} /> */}
                {Array(3)
                  .fill(1)
                  .map((item, idx) => {
                    return (
                      <Grid item key={idx} xs={12} sm={6} md={4}>
                        <Card
                          className="package-card"
                          variant="outlined"
                          sx={{
                            borderRadius: "10px",
                            height: "100%",
                            transition: "0.3s",
                            "&:hover": {
                              boxShadow: 3,
                            },
                          }}
                        >
                          <CardContent sx={{ flexGrow: 1 }}>
                            <Typography
                              variant="h5"
                              align="center"
                              sx={{
                                fontWeight: "bold",
                                mb: 2,
                                fontSize: "1.5rem",
                                // marginInline: "20%",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <Skeleton
                                  variant="text"
                                  width={150}
                                  height={50}
                                />
                              </div>
                            </Typography>
                            <Typography
                              variant="h4"
                              align="center"
                              sx={{
                                color: "primary.main",
                                mb: 2,
                                fontSize: "1.75rem",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <Skeleton
                                  variant="text"
                                  width={70}
                                  height={50}
                                />
                              </div>
                            </Typography>
                            <List>
                              {Array(3)
                                .fill(1)
                                .map((item, idx) => {
                                  return (
                                    <React.Fragment key={idx}>
                                      <Skeleton
                                        variant="text"
                                        width={150}
                                        height={30}
                                      />
                                      <Skeleton
                                        variant="text"
                                        width={250}
                                        height={30}
                                      />
                                      <Skeleton
                                        variant="text"
                                        width={150}
                                        height={30}
                                      />
                                      {/* <Skeleton
                                      variant="text"
                                      width={250}
                                      height={30}
                                    /> */}
                                    </React.Fragment>
                                  );
                                })}
                            </List>
                          </CardContent>
                        </Card>
                      </Grid>
                    );
                  })}
              </>
            ) : (
              packageServiceData.map((plan, index) => (
                <Grid
                  item
                  key={plan.packageId}
                  xs={12}
                  sm={6}
                  md={6}
                  xl={4}
                  ref={(el) => (cardRefs.current[index] = el)}
                >
                  <Card
                    className={`package-card ${
                      selectedPlan?.package_id === plan.packageId
                        ? "selected"
                        : ""
                    }`}
                    variant="outlined"
                  >
                    <CardContent sx={{ flexGrow: 1, padding: "0" }}>
                      <div className="heading text-center">
                        <h3 className="package-title text-uppercase fw-medium m-0">
                          {plan.packageName}
                        </h3>
                        <p className="price-txt m-0">
                          $
                          {isYearly
                            ? parseFloat(plan?.price_annually)
                            : parseFloat(plan?.price_monthly)}
                          <small className="year">
                            {isYearly ? "/yr" : "/mo"}
                          </small>
                          {isYearly && plan?.price_monthly && (
                            <span className="old-price" variant="body2">
                              <span className="line-through">
                                {" "}
                                ${parseFloat(plan?.price_monthly) * 12}
                              </span>
                              <span className="year">/yr</span>
                            </span>
                          )}
                        </p>
                      </div>
                      <List className="ul-list">
                        {plan.packageServiceData
                          .filter(shouldDisplayService)
                          .map((service) => (
                            <ListItem key={service.serviceId} disablePadding>
                              {/* Conditionally render icon based on value */}
                              <ListItemIcon
                                sx={{
                                  minWidth: "auto",
                                  marginRight: { xs: "8px", sm: "10px" },
                                }}
                              >
                                {service.value === "true" ? (
                                  <CheckCircleIcon
                                    className="check-sign"
                                    sx={{
                                      color: "primary.main",
                                      fontSize: "1.5rem",
                                    }}
                                  />
                                ) : service.value === "false" ? (
                                  <CancelIcon
                                    className="cross-sign"
                                    sx={{
                                      color: "error.main",
                                      fontSize: "1.5rem",
                                    }}
                                  />
                                ) : (
                                  <CheckCircleIcon
                                    className="check-sign"
                                    sx={{
                                      color: "primary.main",
                                      fontSize: "1.5rem",
                                    }}
                                  />
                                )}
                              </ListItemIcon>
                              <ListItemText
                                primary={
                                  <p className="m-0" variant="body2">
                                    <strong>{service.serviceName}: </strong>
                                    <span
                                      style={{
                                        fontWeight: "normal",
                                      }}
                                    >
                                      {renderValue(service, plan)}
                                    </span>
                                  </p>
                                }
                                primaryTypographyProps={{ align: "left" }}
                              />
                            </ListItem>
                          ))}
                      </List>
                    </CardContent>

                    <div className="package-footer">
                      {userRole !== "Super Admin" ? (
                        <OverlayTrigger
                          placement="top"
                          overlay={<Tooltip>{renderMessage(plan)}</Tooltip>}
                        >
                          <span>
                            <button
                              className="package-button btn btn-dark w-100"
                              variant="contained"
                              onClick={() => handleSelectPlan(plan)}
                              disabled={
                                (isCurrentPackage(plan) &&
                                  !currentUserPackageStatus?.isExpired) ||
                                (!isCurrentPackage(plan) &&
                                  (!isPlanUpgradeable(
                                    currentUserPackageStatus,
                                    plan
                                  ) ||
                                    currentUserPackageStatus?.package_id ===
                                      plan.packageId))
                              }
                              style={{
                                backgroundColor:
                                  (isCurrentPackage(plan) &&
                                    !currentUserPackageStatus?.isExpired) ||
                                  (!isCurrentPackage(plan) &&
                                    (!isPlanUpgradeable(
                                      currentUserPackageStatus,
                                      plan
                                    ) ||
                                      currentUserPackageStatus?.package_id ===
                                        plan.packageId))
                                    ? "grey"
                                    : "black",
                                color:
                                  (isCurrentPackage(plan) &&
                                    !currentUserPackageStatus?.isExpired) ||
                                  (!isCurrentPackage(plan) &&
                                    (!isPlanUpgradeable(
                                      currentUserPackageStatus,
                                      plan
                                    ) ||
                                      currentUserPackageStatus?.package_id ===
                                        plan.packageId))
                                    ? "darkgray"
                                    : "white",
                                borderRadius: "20px",
                              }}
                            >
                              {isCurrentPackage(plan)
                                ? currentUserPackageStatus?.isExpired
                                  ? "Reselect Current Plan"
                                  : "Current Plan"
                                : "Select Plan"}
                            </button>
                          </span>
                        </OverlayTrigger>
                      ) : (
                        <button
                          className="package-button btn btn-dark w-100"
                          variant="contained"
                          onClick={() => handleSelectPlan(plan)}
                          disabled={
                            isCurrentPackage(plan) &&
                            !currentUserPackageStatus?.isExpired
                          }
                          style={{
                            backgroundColor:
                              isCurrentPackage(plan) &&
                              !currentUserPackageStatus?.isExpired
                                ? "grey"
                                : "black",
                            color:
                              isCurrentPackage(plan) &&
                              !currentUserPackageStatus?.isExpired
                                ? "darkgray"
                                : "white",
                            borderRadius: "20px",
                          }}
                        >
                          {isCurrentPackage(plan)
                            ? currentUserPackageStatus?.isExpired
                              ? "Reselect Current Plan"
                              : "Current Plan"
                            : "Select Plan"}
                        </button>
                      )}
                    </div>

                    {/* <div className="package-footer">
                      {userRole !== "Super Admin" && isPackageExpired ? (
                        <OverlayTrigger
                          placement="top"
                          overlay={<Tooltip>{renderMessage(plan)}</Tooltip>}
                        >
                          <span>
                            <button
                              className="package-button btn btn-dark w-100"
                              variant="contained"
                              onClick={() => handleSelectPlan(plan)}
                              disabled={
                                isPackageExpired &&
                                !isCurrentPackage(plan) &&
                                (!isPlanUpgradeable(
                                  currentUserPackageStatus,
                                  plan
                                ) ||
                                  currentUserPackageStatus?.package_id ===
                                    plan.packageId)
                              }
                              style={{
                                backgroundColor:
                                  isPackageExpired &&
                                  !isCurrentPackage(plan) &&
                                  (!isPlanUpgradeable(
                                    currentUserPackageStatus,
                                    plan
                                  ) ||
                                    currentUserPackageStatus?.package_id ===
                                      plan.packageId)
                                    ? "grey"
                                    : "black",
                                color:
                                  isPackageExpired &&
                                  !isCurrentPackage(plan) &&
                                  (!isPlanUpgradeable(
                                    currentUserPackageStatus,
                                    plan
                                  ) ||
                                    currentUserPackageStatus?.package_id ===
                                      plan.packageId)
                                    ? "darkgray"
                                    : "white",
                                borderRadius: "20px",
                              }}
                            >
                              {isCurrentPackage(plan) && !isPackageExpired
                                ? "Current Plan"
                                : "Select Plan"}
                            </button>
                          </span>
                        </OverlayTrigger>
                      ) : (
                        <button
                          className="package-button btn btn-dark w-100"
                          variant="contained"
                          onClick={() => handleSelectPlan(plan)}
                          disabled={
                            userRole !== "Super Admin" && isCurrentPackage(plan)
                          }
                          style={{
                            backgroundColor: "black",
                            color: "white",
                            borderRadius: "20px",
                          }}
                        >
                          {userRole === "Super Admin" || !isCurrentPackage(plan)
                            ? "Select Plan"
                            : "Current Plan"}
                        </button>
                      )}
                    </div> */}
                  </Card>
                </Grid>
              ))
            )}

            {/* Custom Package Card */}
            <Grid item xs={12} sm={6} md={6} xl={4}>
              <Card className="package-card" variant="outlined">
                <CardContent
                  sx={{
                    flexGrow: 1,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <div className="heading text-center">
                    <h3 className="package-title text-uppercase fw-medium m-0">
                      Custom Package
                    </h3>
                  </div>
                  <Typography
                    variant="body1"
                    align="center"
                    sx={{
                      fontSize: "1.2rem",
                      display: "flex",
                      justifyContent: "center",
                      margin: { xs: "70px 0", sm: "150px 0" },
                    }}
                  >
                    Request a Call for Custom Package
                  </Typography>
                </CardContent>

                <Box sx={{ p: 2, mt: "auto" }}>
                  <Link to="/contact-us" style={{ textDecoration: "none" }}>
                    <button
                      className="package-button btn btn-dark w-100"
                      variant="contained"
                      // color="primary"
                      // fullWidth
                      sx={{
                        backgroundColor: "black", // Set background color to black
                        color: "white", // Change text color to white for contrast
                        borderRadius: "20px",
                        px: 4,
                        "&:hover": {
                          backgroundColor: "darkgray", // Optional: change color on hover
                        },
                      }}
                      // sx={{ borderRadius: "20px" }}
                    >
                      Contact Us
                    </button>
                  </Link>
                </Box>
              </Card>
            </Grid>
          </Grid>
        </>
      )}
    </Box>
  );
};

export default AllPackage;
