import React, { useCallback, useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import HelpRoundedIcon from "@mui/icons-material/HelpRounded";
import { Box, Grid, IconButton, Skeleton, Tab, Tooltip } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { PerformanceTabStyle } from "../../constant";
import SummaryTab from "../Performance/SummaryTab";
import PerformanceTab from "../Performance/PerformanceTab";
import StructureTab from "../Performance/StructureTab";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import common from "../../../../Components/Common/common";
import { LinkIcon } from "../../../../Components/Common/SvgIcons";

function PerformanceDetail(props) {
  let params = useParams();
  let assetId = params.id;

  const [parformancevalue, setParformancevalue] = useState("1");
  const [scores, setGtmetrixData] = useState("");
  const [dataLoading, setDataLoading] = useState(false);

  const handleChangeParformance = (event, newValue) => {
    setParformancevalue(newValue);
  };

  const getMsOrSec = (value) =>
    value < 1000 ? value + "ms" : (value / 1000).toFixed(1) + "s";

  const fetchGtmetrix = useCallback(async () => {
    try {
      setDataLoading(true);
      let { data } = await axios.post("asset/performance", {
        assets_id: assetId,
      });
      const decryptedData = common.decrypt(data);
      setGtmetrixData(decryptedData);
      setDataLoading(false);
    } catch (error) {
      setDataLoading(false);
      console.error(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const auditReport = scores?.audit_report
    ? JSON.parse(scores.audit_report)
    : [];

  // Define the order of risk levels
  const riskLevelOrder = {
    High: 1,
    "Med-High": 2,
    Med: 3,
    "Med-Low": 4,
    Low: 5,
    None: 6,
    "Out of Range": 7,
  };

  // Filter and sort the audit report
  let filteredReport = auditReport
    ?.filter((item) => item.riskLevel in riskLevelOrder)
    .sort((a, b) => riskLevelOrder[a.riskLevel] - riskLevelOrder[b.riskLevel]);

  filteredReport = filteredReport.map((item) => {
    // Check if riskLevel is 'Out of Range', replace with 'N/A'
    if (item.riskLevel === "Out of Range") {
      return { ...item, riskLevel: "N/A" };
    } else {
      return item;
    }
  });
  useEffect(() => {
    fetchGtmetrix();
  }, [fetchGtmetrix]);

  const handleClick = () => {
    const url = scores?.url || "";
    if (url) {
      window.open(url, "_blank", "noopener,noreferrer");
    }
  };


  return (
    <Box className="performance-matrix">
      {scores?.url && (
        <Box
          sx={{
            fontSize: "22px",
            fontWeight: "bold",
            wordBreak: "break-all",
            marginBottom: "15px",
          }}
        >
          <Link to="#">{scores?.url || "No URL"}</Link>
          <IconButton
            onClick={handleClick}
            sx={{
              color: "#98a2b5",
              marginLeft: "20px",
              "&.MuiButtonBase-root": {
                "svg path": {
                  fill: "#98a2b5",
                  stroke: "#98a2b5",
                },
              },
            }}
          >
            <LinkIcon width={24} height={24} />
          </IconButton>
        </Box>
      )}
      <Grid container spacing={3} className="grade-box">
        <Grid item xs={12} lg={6}>
          <h2 className="main-title">
            Performance Matrix Grade{" "}
            <Tooltip
              title="Your Performance Matrix Grade is an assessment of your overall page performance."
              placement="top-start"
              arrow
            >
              <HelpRoundedIcon />
            </Tooltip>
          </h2>

          <Grid container spacing={0} className="matrix-container">
            <Grid item xs={12} sm={4}>
              <Box className="matrix-box">
                <h1
                  className={
                    scores?.grade ? "grade text-orange" : "text-orange"
                  }
                >
                  {dataLoading ? (
                    <>
                      <Skeleton variant="text" width={50} height={100} />
                    </>
                  ) : (
                    <>
                      <span>{scores?.grade ? `${scores.grade}` : "-"}</span>
                    </>
                  )}
                </h1>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              sx={{ padding: { xs: "3px 0", sm: "0 3px" } }}
            >
              <Box className="matrix-box">
                <p className="title">
                  Performance{" "}
                  <Tooltip
                    title="Your Performance Score is essentially your Lighthouse Performance Score, as captured by Controlbase, with our custom audits, Analysis Options, browser and hardware specifications."
                    placement="top-start"
                    arrow
                  >
                    <HelpRoundedIcon />
                  </Tooltip>
                </p>
                <h1 className="value text-red">
                  {dataLoading ? (
                    <>
                      <Skeleton variant="text" width={50} height={60} />
                    </>
                  ) : (
                    <>
                      <span>
                        {scores?.pagespeed_score
                          ? `${scores.pagespeed_score}%`
                          : "-"}
                      </span>
                    </>
                  )}
                </h1>
              </Box>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Box className="matrix-box">
                <p className="title">
                  Structure{" "}
                  <Tooltip
                    title="Your Structure Score is our proprietary assessment of Lighthouse and Custom ControlBase audits.It represents how well your page is built for optimal performance.s"
                    placement="top-start"
                    arrow
                  >
                    <HelpRoundedIcon />
                  </Tooltip>
                </p>
                <h1 className="value text-green">
                  {dataLoading ? (
                    <>
                      <Skeleton variant="text" width={50} height={60} />
                    </>
                  ) : (
                    <>
                      <span>
                        {scores?.structure_score
                          ? `${scores.structure_score}%`
                          : "-"}
                      </span>
                    </>
                  )}
                </h1>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} lg={6}>
          <h2 className="main-title">
            Web Vitals{" "}
            <Tooltip
              title="Web Vitals represent a small set of core metrics that indicate whether you're delivering a fast and (what Google calls) a delightful experience to your visitors.
Focus on these impactful metrics first before undertaking deeper optimizations."
              placement="top-start"
              arrow
            >
              <HelpRoundedIcon />
            </Tooltip>
          </h2>

          <Grid container spacing={0} className="matrix-container">
            <Grid item xs={12} sm={4}>
              <Box className="matrix-box">
                <p className="title">
                  Largest Contentful Paint {""}
                  <Tooltip
                    title="LCP measures how long it takes for the largest content element (i.e., a hero image or heading text) on your page to become visible within your visitors' viewport.For a good user experience, aim for an LCP of 1.2 seconds or less."
                    placement="top-start"
                    arrow
                  >
                    <HelpRoundedIcon />
                  </Tooltip>
                </p>
                <h1 className="value text-orange">
                  {dataLoading ? (
                    <>
                      <Skeleton variant="text" width={50} height={40} />
                    </>
                  ) : (
                    <>
                      <span>
                        {scores?.largest_contentful_paint
                          ? `${getMsOrSec(scores.largest_contentful_paint)}`
                          : "-"}
                      </span>
                    </>
                  )}
                </h1>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              sx={{ padding: { xs: "3px 0", sm: "0 3px" } }}
            >
              <Box className="matrix-box">
                <p className="title">
                  Total Blocking Time {""}
                  <Tooltip
                    title="TBT tells you how much time is blocked by scripts during your page loading process.For a good user experience, aim for a TBT of 150 milliseconds or less."
                    placement="top-start"
                    arrow
                  >
                    <HelpRoundedIcon />
                  </Tooltip>
                </p>
                <h1 className="value text-red">
                  {dataLoading ? (
                    <>
                      <Skeleton variant="text" width={50} height={40} />
                    </>
                  ) : (
                    <>
                      <span>
                        {scores?.total_blocking_time
                          ? `${getMsOrSec(scores.total_blocking_time)}`
                          : "-"}
                      </span>
                    </>
                  )}
                </h1>
              </Box>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Box className="matrix-box">
                <p className="title">
                  Cumulative Layout Shift{" "}
                  <Tooltip
                    title="CLS indicates how much layout shift is experienced by visitors as your page loads.For a good user experience, aim for a CLS score of 0.1 or less."
                    placement="top-start"
                    arrow
                  >
                    <HelpRoundedIcon />
                  </Tooltip>
                </p>
                <h1 className="value text-red">
                  {dataLoading ? (
                    <>
                      <Skeleton variant="text" width={50} height={40} />
                    </>
                  ) : (
                    <>
                      <span>
                        {/* {scores?.cumulative_layout_shift != null
                          ? parseFloat(scores.cumulative_layout_shift).toFixed(
                              1
                            )
                          : "-"} */}
                        {scores?.cumulative_layout_shift != null
                          ? parseFloat(scores.cumulative_layout_shift) < 0.1
                            ? "0" // If less than 0.1, display 0
                            : parseFloat(
                                scores.cumulative_layout_shift
                              ).toFixed(1) // Otherwise, round to 1 decimal
                          : "-"}
                      </span>
                    </>
                  )}
                </h1>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <TabContext value={parformancevalue}>
              <Box>
                <TabList
                  onChange={handleChangeParformance}
                  variant="scrollable"
                  // scrollButtons
                  allowScrollButtonsMobile
                  sx={PerformanceTabStyle}
                >
                  <Tab label="Summary" value="1" />
                  <Tab label="Performance" value="2" />
                  {filteredReport.length > 0 && (
                    <Tab label="Structure" value="3" />
                  )}
                </TabList>
              </Box>
              <TabPanel value="1" className="performance-tab-panel">
                <SummaryTab
                  scores={scores}
                  dataLoading={dataLoading}
                  auditReport={filteredReport}
                />
              </TabPanel>
              <TabPanel value="2" className="performance-tab-panel">
                <PerformanceTab scores={scores} dataLoading={dataLoading} />
              </TabPanel>
              {filteredReport.length > 0 && (
                <TabPanel value="3" className="performance-tab-panel">
                  <StructureTab auditReport={filteredReport} />
                </TabPanel>
              )}
            </TabContext>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
}

export default PerformanceDetail;
