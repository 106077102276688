import React, { useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  TextField,
  Button,
  CircularProgress,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ButtonGroup,
} from "@mui/material";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { ToastMessage } from "../../Components/Common/ToastMessage";
import { useLocation, useNavigate } from "react-router-dom";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import common from "../../Components/Common/common";

const ContactUsForm = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [planType, setPlanType] = useState("monthly"); // Default to 'monthly'
  const navigate = useNavigate();
  const location = useLocation();
  const userData = useSelector((state) => state.userDetails.userData);
  const userRole = userData?.role;

  const { currentPlan, selectedPlan, message, isCurrentPlan } =
    location.state || {};

  const defaultEmail = useSelector(
    (state) =>
      state?.settingsData?.settingsData?.find(
        (item) => item.field_name === "DEFAULT_CONTACTUS_EMAIL"
      )?.field_value || ""
  );

  const {
    user_name = "",
    email = "",
    contact_no = "",
  } = useSelector((state) => state?.userDetails?.userData || {});

  const handleTogglePlan = (type) => {
    setPlanType(type);
  };

  const sendTheContactEmail = async (values) => {
    try {
      setIsLoading(true);
      let reqData = {
        ...values,
        defaultEmail,
        planType,
      };

      if (selectedPlan) {
        reqData.selectedPlan = {
          packageName: selectedPlan?.packageName,
          priceManually: selectedPlan?.price_monthly,
          priceAnnually: selectedPlan?.price_annually,
          packageServiceData: selectedPlan?.packageServiceData?.map(
            (service) => ({
              serviceName: service?.serviceName,
              value: service?.value,
            })
          ),
        };
      }
      if (currentPlan) {
        reqData.currentPlan = {
          packageName: currentPlan?.packageName,
          priceManually: currentPlan?.price_monthly,
          priceAnnually: currentPlan?.price_annually,
        };
      }
      const response = await axios.post("/package/contact-us", reqData);

      if (response.status === "success") {
        ToastMessage.Success(response?.data?.message);
        navigate("/upgrade-package");
      }
    } catch (error) {
      console.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("Name is required."),
    email: Yup.string()
      .email("Invalid email format")
      .required("Email is required."),
    mobilenumber: Yup.string().nullable().notRequired(),
    description: Yup.string()
      .trim()
      .min(10, "Description must be at least 10 characters long.")
      .nullable()
      .notRequired(),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: user_name || "",
      email: email || "",
      mobilenumber: contact_no || "",
      description: "",
    },
    validationSchema,
    onSubmit: (values) => sendTheContactEmail(values),
  });

  //Modified
  return (
    <>
      {isCurrentPlan && message && userRole !== "Super Admin" && (
        <Box
          className="alert alert-borderless alert-warning text-center fs-100"
          role="alert"
          sx={{
            fontSize: "18px", // Adjust this value as needed
          }}
        >
          {message}
        </Box>
      )}

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Grid container spacing={3}>
          {selectedPlan && (
            <>
              <Grid item xs={12} sm={12} md={12} lg={5} xl={4}>
                <Card
                  variant="elevation"
                  elevation={3}
                  sx={{
                    borderRadius: "16px",
                    padding: { xs: 2, sm: 3 },
                    backgroundColor: "#fff",
                    boxShadow: "none",
                  }}
                >
                  <CardContent className="p-0">
                    <Box>
                      {/* Current Plan Section */}
                      {currentPlan && (
                        <Box
                          sx={{
                            marginBottom: "15px",
                          }}
                        >
                          <Typography
                            className="text-center fw-semibold"
                            variant="h6"
                            sx={{
                              fontWeight: { xs: "18px", sm: "22px" },
                              marginBottom: "10px",
                            }}
                          >
                            Current Plan: {currentPlan?.packageName || "N/A"}
                          </Typography>
                        </Box>
                      )}
                      <Box textAlign="center" mt={2} mb={2}>
                        <ButtonGroup>
                          <Button
                            variant={
                              planType === "monthly" ? "contained" : "outlined"
                            }
                            onClick={() => handleTogglePlan("monthly")}
                            sx={{
                              borderRadius: "30px",
                              padding: "8px 24px",
                              backgroundColor:
                                planType === "monthly"
                                  ? "black"
                                  : "transparent",
                              color: planType === "monthly" ? "white" : "black",
                              border:
                                planType === "monthly"
                                  ? "none"
                                  : "1px solid black",
                              "&:hover": {
                                backgroundColor:
                                  planType === "monthly"
                                    ? "darkgray"
                                    : "transparent",
                                borderColor: "black",
                              },
                            }}
                          >
                            Monthly
                          </Button>
                          <Button
                            variant={
                              planType === "yearly" ? "contained" : "outlined"
                            }
                            onClick={() => handleTogglePlan("yearly")}
                            sx={{
                              borderRadius: "30px",
                              padding: "8px 24px",
                              backgroundColor:
                                planType === "yearly" ? "black" : "transparent",
                              color: planType === "yearly" ? "white" : "black",
                              border:
                                planType === "yearly"
                                  ? "none"
                                  : "1px solid black",
                              "&:hover": {
                                backgroundColor:
                                  planType === "yearly"
                                    ? "darkgray"
                                    : "transparent",
                                borderColor: "black",
                              },
                            }}
                          >
                            Yearly
                          </Button>
                        </ButtonGroup>
                      </Box>

                      {/* Selected Plan Section */}
                      {selectedPlan && (
                        <Grid container spacing={4}>
                          <Grid item xs={12}>
                            <Card
                              className="package-card"
                              variant="outlined"
                              sx={{ borderRadius: "16px" }}
                            >
                              <CardContent sx={{ flexGrow: 1, padding: "0" }}>
                                <div className="heading text-center">
                                  <h3 className="package-title text-uppercase fw-medium m-0">
                                    Selected Plan
                                  </h3>
                                  <p className="price-txt m-0">
                                    $
                                    {planType === "monthly"
                                      ? parseFloat(selectedPlan?.price_monthly)
                                      : parseFloat(
                                          selectedPlan?.price_annually
                                        ) || "N/A"}
                                    <small className="year">{planType === "monthly" ? "/mo" : "/yr"}</small>
                                  </p>
                                </div>
                                <List
                                  className="ul-list mx-auto mb-4 py-3 px-4"
                                  sx={{
                                    background: "#f8f8f8",
                                    maxWidth: {
                                      xs: "calc(100% - 40px)",
                                      sm: "calc(100% - 60px)",
                                    },
                                    borderRadius: "10px",
                                  }}
                                >
                                  <ListItem disablePadding>
                                    <ListItemText
                                      primary={
                                        <p className="m-0" variant="body2">
                                          <strong>Package Name: </strong>
                                          {selectedPlan?.packageName || "N/A"}
                                        </p>
                                      }
                                    />
                                  </ListItem>
                                  <ListItem disablePadding>
                                    <ListItemText
                                      primary={
                                        <p className="m-0" variant="body2">
                                          <strong>Price Annually: </strong>$
                                          {parseFloat(
                                            selectedPlan?.price_annually
                                          ) || "N/A"}
                                        </p>
                                      }
                                    />
                                  </ListItem>
                                </List>
                                <List className="ul-list">
                                  {selectedPlan.packageServiceData
                                    .filter(common.shouldDisplayService)
                                    .map((service) => (
                                      <ListItem
                                        key={service.serviceId}
                                        disablePadding
                                      >
                                        {/* Conditionally render icon based on value */}
                                        <ListItemIcon
                                          sx={{
                                            minWidth: "auto",
                                            marginRight: {
                                              xs: "8px",
                                              sm: "10px",
                                            },
                                          }}
                                        >
                                          {service.value === "true" ? (
                                            <CheckCircleIcon
                                              className="check-sign"
                                              sx={{
                                                color: "primary.main",
                                                fontSize: "1.5rem",
                                              }}
                                            />
                                          ) : service.value === "false" ? (
                                            <CancelIcon
                                              className="cross-sign"
                                              sx={{
                                                color: "error.main",
                                                fontSize: "1.5rem",
                                              }}
                                            />
                                          ) : (
                                            <CheckCircleIcon
                                              className="check-sign"
                                              sx={{
                                                color: "primary.main",
                                                fontSize: "1.5rem",
                                              }}
                                            />
                                          )}
                                        </ListItemIcon>
                                        <ListItemText
                                          primary={
                                            <p className="m-0" variant="body2">
                                              <strong>
                                                {service.serviceName}:{" "}
                                              </strong>
                                              <span
                                                style={{
                                                  fontWeight: "normal",
                                                }}
                                              >
                                                {common.renderValue(
                                                  service,
                                                  selectedPlan.packageServiceData
                                                )}
                                              </span>
                                            </p>
                                          }
                                          primaryTypographyProps={{
                                            align: "left",
                                          }}
                                        />
                                      </ListItem>
                                    ))}
                                </List>
                              </CardContent>
                            </Card>
                          </Grid>
                        </Grid>
                      )}
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            </>
          )}
          <Grid item xs={12} sm={12} md={12} lg={7} xl={8}>
            <Card
              variant="elevation"
              elevation={3}
              sx={{
                borderRadius: "16px",
                padding: { xs: 3, lg: 3, xl: 5 },
                backgroundColor: "#fff",
                boxShadow: "none",
              }}
            >
              <CardContent
                sx={{
                  padding: { xs: 0, sm: 2 },
                }}
              >
                <Typography
                  className="fw-semibold"
                  variant="h3"
                  align="center"
                  gutterBottom
                  sx={{
                    fontSize: { xs: "24px", sm: "30px" },
                    color: "#333",
                    marginBottom: "10px",
                  }}
                >
                  Contact Us
                </Typography>
                <Typography
                  variant="body1"
                  align="center"
                  sx={{
                    color: "#666",
                    fontSize: { xs: "14px", sm: "16px" },
                    marginBottom: { xs: "20px", sm: "30px" },
                  }}
                >
                  We’d love to hear from you! Please fill out the form below.
                </Typography>

                <form onSubmit={formik.handleSubmit}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <TextField
                        label="Name*"
                        name="name"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        fullWidth
                        error={
                          formik.touched.name && Boolean(formik.errors.name)
                        }
                        helperText={formik.touched.name && formik.errors.name}
                        disabled={true}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6}>
                      <TextField
                        label="Email*"
                        name="email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        fullWidth
                        type="email"
                        error={
                          formik.touched.email && Boolean(formik.errors.email)
                        }
                        helperText={formik.touched.email && formik.errors.email}
                        disabled={true}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} md={6} lg={6}>
                      <TextField
                        label="Mobile Number"
                        name="mobilenumber"
                        value={formik.values.mobilenumber}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        fullWidth
                        type="text"
                        disabled={!!contact_no}
                        error={
                          formik.touched.mobilenumber &&
                          Boolean(formik.errors.mobilenumber)
                        }
                        helperText={
                          formik.touched.mobilenumber &&
                          formik.errors.mobilenumber
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        label="Description"
                        name="description"
                        value={formik.values.description}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        fullWidth
                        multiline
                        rows={4}
                        error={
                          formik.touched.description &&
                          Boolean(formik.errors.description)
                        }
                        helperText={
                          formik.touched.description &&
                          formik.errors.description
                        }
                      />
                    </Grid>
                  </Grid>

                  <Box sx={{ textAlign: "left", mt: 3 }}>
                    <Button
                      className="btn btn-dark btn btn-secondary"
                      variant="contained"
                      color="primary"
                      type="submit"
                      sx={{
                        color: "white",
                        borderRadius: "20px",
                        px: 4,
                      }}
                      disabled={isLoading}
                      startIcon={
                        isLoading ? (
                          <CircularProgress size={20} color="inherit" />
                        ) : null
                      }
                    >
                      {isLoading ? "Submitting..." : "Send mail"}
                    </Button>
                    <Button
                      className="btn btn-primary btn btn-secondary ms-2"
                      sx={{
                        ml: "5px",
                        borderRadius: "20px",
                        px: 4,
                      }}
                      variant="outlined"
                      onClick={() => navigate("/my-package")}
                    >
                      Cancel
                    </Button>
                  </Box>
                </form>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default ContactUsForm;
