import { Box } from "@mui/material";
import React from "react";
import { Button, Card } from "react-bootstrap";
import { Spinner } from "reactstrap";

const RunScan = ({ loading, onClick }) => {
  return (
    <>
      <Card className="main-card">
        <Card.Body className="nv_tab_com">
          <Box className="text-center">
            <Button
              title="Run Test"
              onClick={onClick}
              style={{ width: "100%", marginTop: "20px" }}
              disabled={loading}
            >
              {loading ? (
                <>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />{" "}
                  Running Scan...
                </>
              ) : (
                "Run Scan"
              )}
            </Button>
          </Box>
          {loading && (
            <Box
              className="alert alert-borderless alert-warning text-center mb-2 mx-2 mt-4"
              role="alert"
            >
              Please wait sometimes as we are running a scan
            </Box>
          )}
        </Card.Body>
      </Card>
    </>
  );
};

export default RunScan;
