import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import {
  Box,
  Grid,
  TextField,
  Checkbox,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Button,
  Typography,
  Card,
  CardContent,
  FormHelperText,
  ListItemText,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import {
  addPackage,
  getAnalyticsServices,
  getPackageServicesById,
  getServices,
  updatePackage,
} from "../../helpers/Help/CommomApi";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { Form } from "reactstrap";
import { MenuHeight } from "../NewDashboard/constant";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { ToastMessage } from "../../Components/Common/ToastMessage";
import common from "../../Components/Common/common";

const AddPackage = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [services, setServices] = useState([]);
  const [analyticsServices, setAnalyticsServices] = useState([]);
  const id = params?.id;

  const fetchServices = async () => {
    try {
      const response = await getServices();
      const responseData = common.decrypt(response?.data);
      setServices(responseData?.rows || []);
    } catch (error) {
      console.error("Error fetching services:", error);
    }
  };

  const fetchAnalyticsServices = async () => {
    try {
      const response = await getAnalyticsServices();
      const responseData = common.decrypt(response?.data);
      if (responseData) {
        setAnalyticsServices(responseData);
      }
    } catch (error) {
      console.error("Error fetching analytics services:", error);
    }
  };

  useEffect(() => {
    fetchAnalyticsServices();
  }, []);

  useEffect(() => {
    fetchServices();
  }, []);

  const fetchPackageData = async () => {
    if (id) {
      try {
        const response = await getPackageServicesById(id);
        const responseData = common.decrypt(response?.data);
        if (responseData) {
          const packageData = responseData[0];
          formik.setValues({
            title: packageData.title || "",
            // status: packageData.status || false,
            status: packageData.status === 1,
            price_monthly: packageData.price_monthly || "",
            price_annually: packageData.price_annually || "",
            assets: "",
            users_limits: "",
            uptime_monitoring_interval: "",
            uptime_monitoring_unit: "Minutes",
            uptime_report: "false",
            domain_expiry_notification: "false",
            ssl_expiry_notification: "false",
            security_scan: "false",
            schedule_security_scan: "false",
            analytics_reports: "false",
            security_scan_report_limit: "",
            security_scan_report_limit_unit: "Month",
            // schedule_security_report_limit: "",
            // schedule_security_report_limit_unit: "Month",
            analytics_services: [],
            performance_report: "false",
            performance_report_limit: "",
            performance_report_limit_unit: "Month",
            isUserAvailableInThisPackage:
              packageData.isUserAvailableInThisPackage || false,
          });

          packageData.packageServiceData.forEach((service) => {
            const { slugName, value } = service;
            if (value) {
              const parsedValue = value;
              switch (slugName) {
                case "assets":
                  formik.setFieldValue("assets", parsedValue);
                  break;
                case "users_limits":
                  formik.setFieldValue("users_limits", parsedValue);
                  break;
                case "uptime_monitoring_interval":
                  formik.setFieldValue(
                    "uptime_monitoring_interval",
                    parsedValue
                  );
                  break;
                case "uptime_monitoring_unit":
                  formik.setFieldValue("uptime_monitoring_unit", parsedValue);
                  break;
                case "uptime_report":
                  formik.setFieldValue(
                    "uptime_report",
                    parsedValue === "true" ? "true" : "false"
                  );
                  break;
                case "domain_expiry_notification":
                  formik.setFieldValue(
                    "domain_expiry_notification",
                    parsedValue === "true" ? "true" : "false"
                  );
                  break;
                case "ssl_expiry_notification":
                  formik.setFieldValue(
                    "ssl_expiry_notification",
                    parsedValue === "true" ? "true" : "false"
                  );
                  break;
                case "security_scan":
                  formik.setFieldValue(
                    "security_scan",
                    parsedValue === "true" ? "true" : "false"
                  );
                  break;
                case "schedule_security_scan":
                  formik.setFieldValue(
                    "schedule_security_scan",
                    parsedValue === "true" ? "true" : "false"
                  );
                  break;
                case "analytics_reports":
                  formik.setFieldValue(
                    "analytics_reports",
                    parsedValue === "true" ? "true" : "false"
                  );
                  break;
                case "security_scan_report_limit":
                  formik.setFieldValue(
                    "security_scan_report_limit",
                    parsedValue
                  );
                  break;
                case "security_scan_report_limit_unit":
                  formik.setFieldValue(
                    "security_scan_report_limit_unit",
                    parsedValue || "Month"
                  );
                  break;
                // case "schedule_security_report_limit":
                //   formik.setFieldValue(
                //     "schedule_security_report_limit",
                //     parsedValue
                //   );
                //   break;
                // case "schedule_security_report_limit_unit":
                //   formik.setFieldValue(
                //     "schedule_security_report_limit_unit",
                //     parsedValue
                //   );
                //   break;
                case "analytics_services":
                  formik.setFieldValue("analytics_services", parsedValue);
                  break;
                case "performance_report":
                  formik.setFieldValue(
                    "performance_report",
                    parsedValue === "true" ? "true" : "false"
                  );
                  break;
                case "performance_report_limit":
                  formik.setFieldValue("performance_report_limit", parsedValue);
                  break;
                case "performance_report_limit_unit":
                  formik.setFieldValue(
                    "performance_report_limit_unit",
                    parsedValue || "Month"
                  );
                  break;
                default:
                  break;
              }
            }
          });

          // if (packageData.isUserAvailableInThisPackage) {
          //   formik.setFieldValue("status", packageData.status);
          // }
        }
      } catch (error) {
        console.error("Error fetching package data:", error);
        toast.error("Failed to fetch package data");
      }
    }
  };

  useEffect(() => {
    fetchPackageData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const onSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);
    const packageServicesData = services
      .map((service) => {
        switch (service.service_name) {
          case "Assets":
            return {
              service_id: service.service_id,
              value: values.assets,
              slug: service.slug,
            };
          case "Users Limits":
            return {
              service_id: service.service_id,
              value: values.users_limits,
              slug: service.slug,
            };
          case "Uptime Report":
            return {
              service_id: service.service_id,
              value: values.uptime_report,
              slug: service.slug,
            };
          case "Uptime Monitoring Interval":
            return {
              service_id: service.service_id,
              value: values.uptime_monitoring_interval,
              slug: service.slug,
            };
          case "Uptime Monitoring Unit":
            return {
              service_id: service.service_id,
              value: values.uptime_monitoring_unit,
              slug: service.slug,
            };
          case "Domain Expiry Notification":
            return {
              service_id: service.service_id,
              value: values.domain_expiry_notification,
              slug: service.slug,
            };
          case "SSL Expiry Notification":
            return {
              service_id: service.service_id,
              value: values.ssl_expiry_notification,
              slug: service.slug,
            };
          case "Security Scan":
            return {
              service_id: service.service_id,
              value: values.security_scan,
              slug: service.slug,
            };
          case "Security Scan Report Limit":
            return {
              service_id: service.service_id,
              value: values.security_scan_report_limit,
              slug: service.slug,
            };
          case "Security Scan Report Limit Unit":
            return {
              service_id: service.service_id,
              value: values.security_scan_report_limit_unit,
              slug: service.slug,
            };
          case "Schedule Security Scan":
            return {
              service_id: service.service_id,
              value: values.schedule_security_scan,
              slug: service.slug,
            };
          // case "Schedule Security Report Limit":
          //   return {
          //     service_id: service.service_id,
          //     value: values.schedule_security_report_limit,
          //     slug: service.slug,
          //   };
          // case "Schedule Security Report Limit Unit":
          //   return {
          //     service_id: service.service_id,
          //     value: values.schedule_security_report_limit_unit,
          //     slug: service.slug,
          //   };
          case "Performance Report":
            return {
              service_id: service.service_id,
              value: values.performance_report,
              slug: service.slug,
            };
          case "Performance Report Limit":
            return {
              service_id: service.service_id,
              value: values.performance_report_limit,
              slug: service.slug,
            };
          case "Performance Report Limit Unit":
            return {
              service_id: service.service_id,
              value: values.performance_report_limit_unit,
              slug: service.slug,
            };
          case "Analytics Reports":
            return {
              service_id: service.service_id,
              value: values.analytics_reports,
              slug: service.slug,
            };
          case "Analytics Services":
            return {
              service_id: service.service_id,
              value: values.analytics_services,
              slug: service.slug,
            };
          default:
            return null;
        }
      })
      .filter(Boolean);

    const finalData = {
      title: values.title,
      status: values.status,
      price_monthly: values.price_monthly,
      price_annually: values.price_annually,
      packageServicesData,
    };

    try {
      const response = id
        ? await updatePackage(id, finalData)
        : await addPackage(finalData);

      if (response?.status === "success") {
        toast.success(`Package ${id ? "updated" : "created"} successfully!`);
        navigate("/package");
      }
      if (response?.status === "fail") {
        ToastMessage.Error(response?.message);
      }
    } catch (error) {
      console.error(
        `Error ${id ? "updating" : "creating"} package:`,
        error.message
      );
      toast.error(
        `Error ${id ? "updating" : "creating"} package. Please try again.`
      );
    } finally {
      setSubmitting(false);
    }
  };

  const validationSchema = Yup.object({
    title: Yup.string()
      .trim()
      .max(50, "Title must be 50 characters or less.")
      .required("Please enter the title."),
    price_monthly: Yup.number()
      .typeError(
        "Price (Monthly) must be a valid number. Please enter only numbers."
      )
      .required("Please enter the price (Monthly).")
      .positive("Price (Monthly) must be positive")
      .test(
        "two-decimal-places",
        "Price (Monthly) must have at most two decimal places.",
        (value) => {
          if (value === undefined || value === null) return true; // Skip validation for empty values
          const decimalString = value.toString().split(".")[1];
          return decimalString ? decimalString.length <= 2 : true; // Ensure at most 2 decimal places
        }
      )
      .test(
        "max-six-digits",
        "Price (Monthly) must have at most six digits before the decimal.",
        (value) => {
          if (value === undefined || value === null) return true; // Skip validation for empty values
          const integerString = value.toString().split(".")[0];
          return integerString.length <= 6; // Ensure at most 6 digits before the decimal
        }
      ),

    price_annually: Yup.number()
      .typeError(
        "Price (Yearly) must be a valid number. Please enter only numbers."
      )
      .required("Please enter the price (Yearly).")
      .positive("Price (Yearly) must be positive")
      .test(
        "two-decimal-places",
        "Price (Yearly) must have at most two decimal places.",
        (value) => {
          if (value === undefined || value === null) return true; // Skip validation for empty values
          const decimalString = value.toString().split(".")[1];
          return decimalString ? decimalString.length <= 2 : true; // Ensure at most 2 decimal places
        }
      )
      .test(
        "max-six-digits",
        "Price (Yearly) must have at most six digits before the decimal.",
        (value) => {
          if (value === undefined || value === null) return true; // Skip validation for empty values
          const integerString = value.toString().split(".")[0];
          return integerString.length <= 6; // Ensure at most 6 digits before the decimal
        }
      ),
    assets: Yup.number()
      .typeError(
        "Assets (Limit) must be a valid number. Please enter only numbers."
      )
      .required("Please enter the assets limit.")
      .positive("Assets (Limit) must be positive")
      .max(999, "Assets (Limit) must be less than or equal to 999")
      .integer("Assets (Limit) must be an integer value."),
    users_limits: Yup.number()
      .typeError(
        "User (Limit) must be a valid number. Please enter only numbers."
      )
      .required("Please enter the user limit.")
      .positive("User (Limit) must be positive")
      .max(9999, "User (Limit) must be less than or equal to 9999")
      .integer("User (Limit) must be an integer value."),
    // uptime_monitoring_interval: Yup.number()
    //   .typeError(
    //     "Monitoring interval must be a valid number. Please enter only numbers."
    //   )
    //   .test("is-required", "Monitoring interval is required", function (value) {
    //     const { uptime_report } = this.parent;
    //     return uptime_report === "true" ? value != null && value > 0 : true;
    //   }),
    uptime_monitoring_interval: Yup.number()
      .typeError(
        "Monitoring interval must be a valid number. Please enter only numbers."
      )
      .test("is-required", "Monitoring interval is required", function (value) {
        const { uptime_report } = this.parent;
        return uptime_report === "true" ? value != null && value > 0 : true;
      })
      .test("max-value", function (value) {
        const { uptime_monitoring_unit } = this.parent;
        if (!value || !uptime_monitoring_unit) return true; // Skip validation if no value or unit
        if (uptime_monitoring_unit === "Hours" && value > 24) {
          return this.createError({
            message: "Monitoring interval cannot exceed 24 hours.",
          });
        }
        if (uptime_monitoring_unit === "Minutes" && value > 60) {
          return this.createError({
            message: "Monitoring interval cannot exceed 60 minutes.",
          });
        }
        return true; // Default case
      }),
    // .test(
    //   "max-value",
    //   "Monitoring interval exceeds the allowed limit.",
    //   function (value) {
    //     const { uptime_monitoring_unit } = this.parent;
    //     if (!value || !uptime_monitoring_unit) return true; // Skip validation if no value or unit
    //     if (uptime_monitoring_unit === "Hours") return value <= 24;
    //     if (uptime_monitoring_unit === "Minutes") return value <= 60;
    //     return true; // Default case for other units
    //   }
    // ),
    uptime_monitoring_unit: Yup.string()
      .trim()
      .test(
        "is-required",
        "Monitoring interval Unit is required",
        function (value) {
          const { uptime_report } = this.parent;
          return uptime_report === "true" ? !!value : true;
        }
      ),

    security_scan_report_limit: Yup.number()
      .typeError(
        "Security Scan Report Limit must be a valid number. Please enter only numbers."
      )
      .test(
        "is-required",
        "Security Scan Report Limit is required",
        function (value) {
          const { security_scan } = this.parent;
          return security_scan === "true" ? value != null && value > 0 : true;
        }
      )
      .max(99, "Security Scan Report Limit must be less than or equal to 99."),
    security_scan_report_limit_unit: Yup.string()
      .trim()
      .test(
        "is-required",
        "Security Scan Report Limit Unit is required",
        function (value) {
          const { security_scan } = this.parent;
          return security_scan === "true" ? !!value : true;
        }
      ),

    // schedule_security_report_limit: Yup.number()
    //   .typeError(
    //     "Schedule Security Report Limit must be a valid number. Please enter only numbers."
    //   )
    //   .test(
    //     "is-required",
    //     "Schedule Security Report Limit is required",
    //     function (value) {
    //       const { schedule_security_scan } = this.parent;
    //       return schedule_security_scan === "true"
    //         ? value != null && value > 0
    //         : true;
    //     }
    //   )
    //   .max(
    //     99,
    //     "Schedule Security Report Limit must be less than or equal to 99."
    //   )
    //   .test(
    //     "does-not-exceed-security-scan-limit",
    //     "Schedule Security Report Limit cannot exceed the Security Scan Report Limit",
    //     function (value) {
    //       const { security_scan_report_limit } = this.parent;
    //       // Only check if security_scan_report_limit is provided and the scan is enabled
    //       if (
    //         security_scan_report_limit &&
    //         value > security_scan_report_limit
    //       ) {
    //         return false; // Validation fails if value exceeds the security_scan_report_limit
    //       }
    //       return true; // Pass validation if the condition is met
    //     }
    //   ),
    // schedule_security_report_limit_unit: Yup.string()
    //   .trim()
    //   .test(
    //     "is-required",
    //     "Schedule Security Report Limit Unit is required",
    //     function (value) {
    //       const { schedule_security_scan, security_scan_report_limit_unit } =
    //         this.parent;
    //       if (schedule_security_scan === "true") {
    //         // Automatically validate that the unit matches
    //         return (
    //           !!value &&
    //           (value === security_scan_report_limit_unit ||
    //             ["Day", "Month"].includes(value))
    //         );
    //       }
    //       return true; // No validation required when `schedule_security_scan` is false
    //     }
    //   ),

    performance_report_limit: Yup.number()
      .typeError(
        "Performance Report Limit must be a valid number. Please enter only numbers."
      )
      .test(
        "is-required",
        "Performance Report Limit is required",
        function (value) {
          const { performance_report } = this.parent;
          return performance_report === "true"
            ? value != null && value > 0
            : true;
        }
      )
      .max(99, "Performance Report Limit must be less than or equal to 99."),
    performance_report_limit_unit: Yup.string()
      .trim()
      .test(
        "is-required",
        "Performance Report Limit Unit is required",
        function (value) {
          const { performance_report } = this.parent;
          return performance_report === "true" ? !!value : true;
        }
      ),

    analytics_services: Yup.array()
      .test(
        "is-required",
        "At least one Analytics Service is required",
        function (value) {
          const { analytics_reports } = this.parent;
          return analytics_reports === "true"
            ? value && value.length > 0
            : true;
        }
      )
      .of(
        Yup.number().typeError(
          "Analytics Service ID must be a valid number. Please enter only numbers."
        )
      ),
  });

  const formik = useFormik({
    initialValues: {
      title: "",
      status: true,
      price_monthly: "",
      price_annually: "",
      assets: "",
      users_limits: "",
      uptime_monitoring_interval: "",
      uptime_monitoring_unit: "",
      uptime_report: "false",
      domain_expiry_notification: "false",
      ssl_expiry_notification: "false",
      security_scan: "false",
      schedule_security_scan: "false",
      analytics_reports: "false",
      security_scan_report_limit: "",
      security_scan_report_limit_unit: "",
      // schedule_security_report_limit: "",
      // schedule_security_report_limit_unit: "",
      analytics_services: [],
      performance_report: "false",
      performance_report_limit: "",
      performance_report_limit_unit: "",
    },
    validationSchema: validationSchema,

    onSubmit,
  });

  useEffect(() => {
    if (formik.values.performance_report === "true") {
      formik.values.performance_report_limit_unit ||
        formik.setFieldValue("performance_report_limit_unit", "Month");
    } else {
      formik.setFieldValue("performance_report_limit_unit", "");
    }
    if (formik.values.security_scan === "true") {
      formik.values.security_scan_report_limit_unit ||
        formik.setFieldValue("security_scan_report_limit_unit", "Month");
    } else {
      formik.setFieldValue("security_scan_report_limit_unit", "");
      formik.setFieldValue("schedule_security_scan", "false");
    }
    // if (formik.values.schedule_security_scan === "true") {
    //   formik.values.schedule_security_report_limit_unit ||
    //     formik.setFieldValue("schedule_security_report_limit_unit", "Month");
    // } else {
    //   formik.setFieldValue("schedule_security_report_limit_unit", "");
    // }
    if (formik.values.uptime_report === "true") {
      formik.values.uptime_monitoring_unit ||
        formik.setFieldValue("uptime_monitoring_unit", "Minutes");
    } else {
      formik.setFieldValue("uptime_monitoring_unit", "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    formik.values.performance_report,
    formik.values.security_scan,
    formik.values.schedule_security_scan,
    formik.values.uptime_report,
  ]);

  const limitUnitOptions = [
    { value: "Month", label: "Month" },
    { value: "Day", label: "Day" },
  ];

  const uptimeUnitOptions = [
    { value: "Minutes", label: "Minutes" },
    { value: "Hours", label: "Hours" },
  ];

  return (
    <Box sx={{ padding: "20px" }}>
      <Card sx={{ padding: "20px" }}>
        <CardContent>
          <Form
            onSubmit={formik.handleSubmit}
            className="needs-validation"
            noValidate
            action="index"
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Title *"
                  name="title"
                  variant="outlined"
                  {...formik.getFieldProps("title")}
                  // value={formik.values.title}
                  // onChange={formik.handleChange}
                  // onBlur={formik.handleBlur}
                  error={formik.touched.title && !!formik.errors.title}
                  helperText={formik.touched.title && formik.errors.title}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <OverlayTrigger
                  overlay={
                    formik.values.isUserAvailableInThisPackage ? (
                      <Tooltip id="tooltip-disabled">
                        This package is assigned to the user, so the status
                        can't be changed.
                      </Tooltip>
                    ) : (
                      <></>
                    )
                  }
                  placement="top"
                >
                  <span>
                    {" "}
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={Boolean(formik.values.status)}
                          onChange={formik.handleChange}
                          name="status"
                          disabled={formik.values.isUserAvailableInThisPackage}
                        />
                      }
                      label="Active"
                    />
                  </span>
                </OverlayTrigger>
              </Grid>
              {/* Price Monthly and Annually */}
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="$ Price (Monthly) *"
                  name="price_monthly"
                  type="number"
                  inputProps={{
                    min: 0,
                    step: 0.01,
                    onKeyDown: (e) => {
                      // Prevent '+' and '-' keys
                      if (
                        (e.key === "." && e.target.value === "") ||
                        e.key === "+" ||
                        e.key === "-"
                      ) {
                        e.preventDefault();
                      }
                    },
                  }}
                  {...formik.getFieldProps("price_monthly")}
                  onInput={(e) => {
                    let value = e.target.value;

                    value = value.replace(/[+-]/g, "");
                    if (value.startsWith(".")) {
                      value = "";
                    }
                    // Check for the decimal point and limit decimal part to 2 digits
                    if (value.includes(".")) {
                      const [integerPart, decimalPart] = value.split(".");
                      if (decimalPart && decimalPart.length > 2) {
                        e.target.value = `${integerPart}.${decimalPart.slice(
                          0,
                          2
                        )}`;
                      }
                    }

                    // Limit integer part to 6 digits and prevent further input if exceeded
                    const [integerPart] = value.split(".");
                    if (integerPart && integerPart.length > 6) {
                      e.target.value =
                        integerPart.slice(0, 6) +
                        (value.includes(".") ? "." + value.split(".")[1] : "");
                    }

                    // Disable further input if the integer part has reached 6 digits
                    if (integerPart && integerPart.length > 6) {
                      e.preventDefault();
                    }
                  }}
                  error={
                    formik.touched.price_monthly &&
                    !!formik.errors.price_monthly
                  }
                  helperText={
                    formik.touched.price_monthly && formik.errors.price_monthly
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="$ Price (Yearly) *"
                  name="price_annually"
                  type="number"
                  inputProps={{
                    min: 0,
                    step: 0.01,
                    onKeyDown: (e) => {
                      // Prevent '+' and '-' keys
                      if (
                        (e.key === "." && e.target.value === "") ||
                        e.key === "+" ||
                        e.key === "-"
                      ) {
                        e.preventDefault();
                      }
                    },
                  }}
                  {...formik.getFieldProps("price_annually")}
                  onInput={(e) => {
                    let value = e.target.value;
                    value = value.replace(/[+-]/g, "");
                    if (value.startsWith(".")) {
                      value = "";
                    }
                    // Remove decimal point if entered first
                    if (value.startsWith(".")) {
                      e.target.value = "";
                      return;
                    }

                    // Check for the decimal point and limit decimal part to 2 digits
                    if (value.includes(".")) {
                      const [integerPart, decimalPart] = value.split(".");
                      if (decimalPart && decimalPart.length > 2) {
                        e.target.value = `${integerPart}.${decimalPart.slice(
                          0,
                          2
                        )}`;
                      }
                    }

                    // Limit integer part to 6 digits and prevent further input if exceeded
                    const [integerPart] = value.split(".");
                    if (integerPart && integerPart.length > 6) {
                      e.target.value =
                        integerPart.slice(0, 6) +
                        (value.includes(".") ? "." + value.split(".")[1] : "");
                    }

                    // Disable further input if the integer part has reached 6 digits
                    if (integerPart && integerPart.length > 6) {
                      e.preventDefault();
                    }
                  }}
                  error={
                    formik.touched.price_annually &&
                    !!formik.errors.price_annually
                  }
                  helperText={
                    formik.touched.price_annually &&
                    formik.errors.price_annually
                  }
                />
              </Grid>
              {/* Assets Limit and User Limit */}
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Assets (Limit) *"
                  name="assets"
                  variant="outlined"
                  type="number"
                  {...formik.getFieldProps("assets")}
                  onInput={(e) => {
                    const value = e.target.value.replace(/[^0-9]/g, ""); // Allow only digits
                    if (value <= 999) {
                      e.target.value = value; // Allow values up to the max limit
                    } else {
                      e.target.value = value.slice(0, 3); // Truncate if it exceeds max limit
                    }
                  }}
                  error={formik.touched.assets && !!formik.errors.assets}
                  helperText={formik.touched.assets && formik.errors.assets}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="User (Limit) *"
                  name="users_limits"
                  variant="outlined"
                  type="number"
                  {...formik.getFieldProps("users_limits")}
                  onInput={(e) => {
                    const value = e.target.value.replace(/[^0-9]/g, "");
                    if (value <= 9999) {
                      e.target.value = value;
                    } else {
                      e.target.value = value.slice(0, 4);
                    }
                  }}
                  error={
                    formik.touched.users_limits && !!formik.errors.users_limits
                  }
                  helperText={
                    formik.touched.users_limits && formik.errors.users_limits
                  }
                />
              </Grid>
              {/* Uptime Report and Monitoring Interval */}
              <Grid item xs={12} sm={6}>
                <Typography>Uptime Report</Typography>
                <RadioGroup
                  row
                  name="uptime_report"
                  value={formik.values.uptime_report}
                  onChange={formik.handleChange}
                >
                  <FormControlLabel
                    value="true"
                    control={<Radio />}
                    label="Enable"
                  />
                  <FormControlLabel
                    value="false"
                    control={<Radio />}
                    label="Disable"
                  />
                </RadioGroup>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label={`Monitoring interval${
                        formik.values.uptime_report === "false" ? "" : " *"
                      }`}
                      name="uptime_monitoring_interval"
                      variant="outlined"
                      type="number"
                      {...formik.getFieldProps("uptime_monitoring_interval")}
                      onInput={(e) => {
                        e.target.value = e.target.value.replace(/[^0-9]/g, "");
                      }}
                      error={
                        formik.touched.uptime_monitoring_interval &&
                        !!formik.errors.uptime_monitoring_interval
                      }
                      helperText={
                        formik.touched.uptime_monitoring_interval &&
                        formik.errors.uptime_monitoring_interval
                      }
                      disabled={formik.values.uptime_report === "false"}
                      value={
                        formik.values.uptime_report === "false"
                          ? (formik.values.uptime_monitoring_interval = "")
                          : formik.values.uptime_monitoring_interval
                      }
                      style={{
                        opacity:
                          formik.values.uptime_report === "false" ? 0.5 : 1, // Adjust opacity
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl
                      fullWidth
                      error={Boolean(
                        formik.errors.uptime_monitoring_unit &&
                          formik.touched.uptime_monitoring_unit
                      )}
                      style={{
                        opacity:
                          formik.values.uptime_report === "false" ? 0.5 : 1, // Adjust opacity
                      }}
                    >
                      <InputLabel>Interval Unit</InputLabel>
                      <Select
                        label="Interval Unit"
                        name="uptime_monitoring_unit"
                        value={
                          formik.values.uptime_report === "false"
                            ? (formik.values.uptime_monitoring_unit = "")
                            : formik.values.uptime_monitoring_unit
                        }
                        onChange={formik.handleChange}
                        disabled={formik.values.uptime_report === "false"}
                      >
                        {uptimeUnitOptions?.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                      {formik.errors.uptime_monitoring_unit &&
                        formik.touched.uptime_monitoring_unit && (
                          <FormHelperText className="error-text">
                            {formik.errors.uptime_monitoring_unit}
                          </FormHelperText>
                        )}
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              {/* Domain Expiry Notification and SSL Expiry Notification */}
              <Grid item xs={12} sm={6}>
                <Typography>Domain Expiry Notification</Typography>
                <RadioGroup
                  row
                  name="domain_expiry_notification"
                  value={formik.values.domain_expiry_notification}
                  onChange={formik.handleChange}
                >
                  <FormControlLabel
                    value="true"
                    control={<Radio />}
                    label="Enable"
                  />
                  <FormControlLabel
                    value="false"
                    control={<Radio />}
                    label="Disable"
                  />
                </RadioGroup>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography>SSL Expiry Notification</Typography>
                <RadioGroup
                  row
                  name="ssl_expiry_notification"
                  value={formik.values.ssl_expiry_notification}
                  onChange={formik.handleChange}
                >
                  <FormControlLabel
                    value="true"
                    control={<Radio />}
                    label="Enable"
                  />
                  <FormControlLabel
                    value="false"
                    control={<Radio />}
                    label="Disable"
                  />
                </RadioGroup>
              </Grid>
              {/*Security Scan and Report Limit*/}
              <Grid item xs={12} sm={6}>
                <Typography>Security Scan</Typography>
                <RadioGroup
                  row
                  name="security_scan"
                  value={formik.values.security_scan}
                  onChange={formik.handleChange}
                >
                  <FormControlLabel
                    value="true"
                    control={<Radio />}
                    label="Enable"
                  />
                  <FormControlLabel
                    value="false"
                    control={<Radio />}
                    label="Disable"
                  />
                </RadioGroup>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label={`Security Report Limit${
                        formik.values.security_scan === "false" ? "" : " *"
                      }`}
                      // label="Security Report Limit"
                      name="security_scan_report_limit"
                      variant="outlined"
                      type="number"
                      onInput={(e) => {
                        e.target.value = e.target.value.replace(/[^0-9]/g, "");
                      }}
                      {...formik.getFieldProps("security_scan_report_limit")}
                      error={
                        formik.touched.security_scan_report_limit &&
                        !!formik.errors.security_scan_report_limit
                      }
                      helperText={
                        formik.touched.security_scan_report_limit &&
                        formik.errors.security_scan_report_limit
                      }
                      value={
                        formik.values.security_scan === "false"
                          ? (formik.values.security_scan_report_limit = "")
                          : formik.values.security_scan_report_limit
                      }
                      disabled={formik.values.security_scan === "false"}
                      style={{
                        opacity:
                          formik.values.security_scan === "false" ? 0.5 : 1, // Adjust opacity
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl
                      fullWidth
                      error={Boolean(
                        formik.errors.security_scan_report_limit_unit &&
                          formik.touched.security_scan_report_limit_unit
                      )}
                      style={{
                        opacity:
                          formik.values.security_scan === "false" ? 0.5 : 1, // Adjust opacity
                      }}
                    >
                      <InputLabel>Limit Unit</InputLabel>
                      <Select
                        label="Limit Unit"
                        name="security_scan_report_limit_unit"
                        value={
                          formik.values.security_scan === "false"
                            ? (formik.values.security_scan_report_limit_unit =
                                "")
                            : formik.values.security_scan_report_limit_unit
                        }
                        // onChange={formik.handleChange}
                        // onChange={(e) => {
                        //   formik.setFieldValue(
                        //     "security_scan_report_limit_unit",
                        //     e.target.value
                        //   );
                        //   if (e.target.value === "Month") {
                        //     formik.setFieldValue(
                        //       "schedule_security_report_limit_unit",
                        //       "Month"
                        //     );
                        //   }
                        // }}
                        onChange={(e) => {
                          const selectedValue = e.target.value;
                          formik.setFieldValue(
                            "security_scan_report_limit_unit",
                            selectedValue
                          );

                          // Automatically set `schedule_security_report_limit_unit`
                          // if (
                          //   selectedValue === "Day" ||
                          //   selectedValue === "Month"
                          // ) {
                          //   formik.setFieldValue(
                          //     "schedule_security_report_limit_unit",
                          //     selectedValue
                          //   );
                          // }
                        }}
                        disabled={formik.values.security_scan === "false"}
                      >
                        {limitUnitOptions?.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                      {formik.errors.security_scan_report_limit_unit &&
                        formik.touched.security_scan_report_limit_unit && (
                          <FormHelperText className="error-text">
                            {formik.errors.security_scan_report_limit_unit}
                          </FormHelperText>
                        )}
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              {/* Schedule Security Scan and Report Limit */}
              <Grid item xs={12} sm={12}>
                <Typography>Schedule Security Scan</Typography>
                <RadioGroup
                  row
                  name="schedule_security_scan"
                  value={formik.values.schedule_security_scan}
                  onChange={formik.handleChange}
                >
                  <FormControlLabel
                    value="true"
                    disabled={formik.values.security_scan === "false"}
                    control={<Radio />}
                    label="Enable"
                  />
                  <FormControlLabel
                    value="false"
                    disabled={formik.values.security_scan === "false"}
                    control={<Radio />}
                    label="Disable"
                  />
                </RadioGroup>
              </Grid>

              {/* <Grid item xs={12} sm={6}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label={`Schedule Security Report Limit${
                        formik.values.schedule_security_scan === "false"
                          ? ""
                          : " *"
                      }`}
                      // label="Schedule Security Report Limit"
                      name="schedule_security_report_limit"
                      variant="outlined"
                      type="number"
                      {...formik.getFieldProps(
                        "schedule_security_report_limit"
                      )}
                      onInput={(e) => {
                        e.target.value = e.target.value.replace(/[^0-9]/g, "");
                      }}
                      error={
                        formik.touched.schedule_security_report_limit &&
                        !!formik.errors.schedule_security_report_limit
                      }
                      helperText={
                        formik.touched.schedule_security_report_limit &&
                        formik.errors.schedule_security_report_limit
                      }
                      disabled={
                        formik.values.schedule_security_scan === "false"
                      }
                      value={
                        formik.values.schedule_security_scan === "false"
                          ? (formik.values.schedule_security_report_limit = "")
                          : formik.values.schedule_security_report_limit
                      }
                      style={{
                        opacity:
                          formik.values.schedule_security_scan === "false"
                            ? 0.5
                            : 1, // Adjust opacity
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl
                      fullWidth
                      error={Boolean(
                        formik.errors.schedule_security_report_limit_unit &&
                          formik.touched.schedule_security_report_limit_unit
                      )}
                      style={{
                        opacity:
                          formik.values.schedule_security_scan === "false"
                            ? 0.5
                            : 1, // Adjust opacity
                      }}
                    >
                      <InputLabel>Limit Unit</InputLabel>
                      <Select
                        label="Limit Unit"
                        name="schedule_security_report_limit_unit"
                        value={
                          formik.values.schedule_security_scan === "false"
                            ? (formik.values.schedule_security_report_limit_unit =
                                "")
                            : formik.values.schedule_security_report_limit_unit
                        }
                        onChange={formik.handleChange}
                        // disabled={
                        //   formik.values.schedule_security_scan === "false"
                        // }
                        disabled={
                          formik.values.schedule_security_scan === "false" ||
                          formik.values.security_scan_report_limit_unit ===
                            "Month" ||
                          formik.values.security_scan_report_limit_unit ===
                            "Day"
                        }
                      >
                        {limitUnitOptions?.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                      {formik.errors.schedule_security_report_limit_unit &&
                        formik.touched.schedule_security_report_limit_unit && (
                          <FormHelperText className="error-text">
                            {formik.errors.schedule_security_report_limit_unit}
                          </FormHelperText>
                        )}
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid> */}

              {/* performance Scan and Report Limit */}
              <Grid item xs={12} sm={6}>
                <Typography>Performance Scan</Typography>
                <RadioGroup
                  row
                  name="performance_report"
                  value={formik.values.performance_report}
                  onChange={formik.handleChange}
                >
                  <FormControlLabel
                    value="true"
                    control={<Radio />}
                    label="Enable"
                  />
                  <FormControlLabel
                    value="false"
                    control={<Radio />}
                    label="Disable"
                  />
                </RadioGroup>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label={`Performance Limit${
                        formik.values.performance_report === "false" ? "" : " *"
                      }`}
                      name="performance_report_limit"
                      variant="outlined"
                      type="number"
                      {...formik.getFieldProps("performance_report_limit")}
                      onInput={(e) => {
                        e.target.value = e.target.value.replace(/[^0-9]/g, "");
                      }}
                      error={
                        formik.touched.performance_report_limit &&
                        !!formik.errors.performance_report_limit
                      }
                      helperText={
                        formik.touched.performance_report_limit &&
                        formik.errors.performance_report_limit
                      }
                      disabled={formik.values.performance_report === "false"}
                      value={
                        formik.values.performance_report === "false"
                          ? (formik.values.performance_report_limit = "")
                          : formik.values.performance_report_limit
                      }
                      style={{
                        opacity:
                          formik.values.performance_report === "false"
                            ? 0.5
                            : 1, // Adjust opacity
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl
                      fullWidth
                      error={Boolean(
                        formik.errors.performance_report_limit_unit &&
                          formik.touched.performance_report_limit_unit
                      )}
                      style={{
                        opacity:
                          formik.values.performance_report === "false"
                            ? 0.5
                            : 1, // Adjust opacity
                      }}
                    >
                      <InputLabel>Performance Limit Unit</InputLabel>
                      <Select
                        label="Performance Limit Unit"
                        name="performance_report_limit_unit"
                        value={
                          formik.values.performance_report === "false"
                            ? (formik.values.performance_report_limit_unit = "")
                            : formik.values.performance_report_limit_unit ||
                              "Month"
                        }
                        onChange={formik.handleChange}
                        disabled={formik.values.performance_report === "false"}
                      >
                        {limitUnitOptions?.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                      {formik.errors.performance_report_limit_unit &&
                        formik.touched.performance_report_limit_unit && (
                          <FormHelperText className="error-text">
                            {formik.errors.performance_report_limit_unit}
                          </FormHelperText>
                        )}
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              {/* Analytics Report */}
              <Grid item xs={12} sm={6}>
                <Typography>Analytics Report</Typography>
                <RadioGroup
                  row
                  name="analytics_reports"
                  value={formik.values.analytics_reports}
                  onChange={formik.handleChange}
                >
                  <FormControlLabel
                    value="true"
                    control={<Radio />}
                    label="Enable"
                  />
                  <FormControlLabel
                    value="false"
                    control={<Radio />}
                    label="Disable"
                  />
                </RadioGroup>
              </Grid>
              {/* Selected Services */}
              <Grid item xs={12} sm={6}>
                <FormControl
                  fullWidth
                  error={Boolean(
                    formik.errors.analytics_services &&
                      formik.touched.analytics_services
                  )}
                  style={{
                    opacity:
                      formik.values.analytics_reports === "false" ? 0.5 : 1, // Adjust opacity
                  }}
                >
                  <InputLabel>
                    {" "}
                    Analytics Services
                    {formik.values.analytics_reports === "true" ? " *" : ""}
                  </InputLabel>
                  <Select
                    label={`Analytics Services${
                      formik.values.analytics_reports === "true" ? " *" : ""
                    }`}
                    // label="Analytics Services "
                    name="analytics_services"
                    multiple
                    MenuProps={MenuHeight}
                    value={
                      formik.values.analytics_reports === "false"
                        ? (formik.values.analytics_services = [])
                        : formik.values.analytics_services
                    }
                    onChange={(event) => {
                      const selectedValues = event.target.value;
                      formik.setFieldValue(
                        "analytics_services",
                        selectedValues
                      );
                    }}
                    renderValue={(selected) =>
                      analyticsServices
                        .filter((service) =>
                          selected.includes(service.s_service_id)
                        )
                        .map((service) => service.s_service_name)
                        .join(", ")
                    }
                    disabled={formik.values.analytics_reports === "false"}
                  >
                    {analyticsServices?.map((service) => (
                      <MenuItem
                        key={service?.s_service_id}
                        value={service?.s_service_id}
                      >
                        <Checkbox
                          checked={formik.values.analytics_services.includes(
                            service.s_service_id
                          )}
                        />
                        <ListItemText primary={service?.s_service_name} />
                      </MenuItem>
                    ))}
                  </Select>
                  {formik.errors.analytics_services &&
                    formik.touched.analytics_services && (
                      <FormHelperText className="error-text">
                        {formik.errors.analytics_services}
                      </FormHelperText>
                    )}
                </FormControl>
              </Grid>
              <Grid item xs={12} container justifyContent="center">
                <Button
                  type="submit"
                  variant="contained"
                  className="btn btn-dark"
                  sx={{ marginRight: 2 }}
                >
                  Submit
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  className="btn btn-primary"
                  onClick={() => navigate("/package")}
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </Form>
        </CardContent>
      </Card>
    </Box>
  );
};

export default AddPackage;
