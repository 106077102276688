import React, { useState } from "react";
import ProfileImage from "../../../Components/Common/ProfileImage";
import { Form, FormFeedback, Button } from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import TextEditor from "../../../Components/Common/TextEditor";
import SendIcon from "@mui/icons-material/Send";
import { Box } from "@mui/material";

const Comments = ({
  comment,
  nestedComments,
  postComments,
  ticketId,
  openCommentId,
  setOpenCommentId,
  getNestedComments,
  showReplyButton,
  depth = 1, // Add a default depth value
}) => {
  function displayCommentTime(commentTime) {
    let secondsInMinute = 60;
    let secondsInHour = 60 * secondsInMinute; // 3600
    let secondsInDay = 24 * secondsInHour; // 86400
    let secondsInWeek = 7 * secondsInDay; // 604800

    let currentTime = new Date();
    let commentDateTime = new Date(commentTime);

    let timeDiff = currentTime - commentDateTime;

    if (timeDiff < 0) {
      return "In the future";
    }

    const timeDifferenceInSeconds = Math.floor(timeDiff / 1000);

    if (timeDifferenceInSeconds < secondsInMinute) {
      return `${timeDifferenceInSeconds} second${
        timeDifferenceInSeconds === 1 ? "" : "s"
      } ago`;
    } else if (timeDifferenceInSeconds < secondsInHour) {
      const minutesAgo = Math.floor(timeDifferenceInSeconds / secondsInMinute);
      return `${minutesAgo} minute${minutesAgo === 1 ? "" : "s"} ago`;
    } else if (timeDifferenceInSeconds < secondsInDay) {
      const hoursAgo = Math.floor(timeDifferenceInSeconds / secondsInHour);
      return `${hoursAgo} hour${hoursAgo === 1 ? "" : "s"} ago`;
    } else if (timeDifferenceInSeconds < secondsInWeek) {
      const daysAgo = Math.floor(timeDifferenceInSeconds / secondsInDay);
      return `${daysAgo} day${daysAgo === 1 ? "" : "s"} ago`;
    } else {
      const weeksAgo = Math.floor(timeDifferenceInSeconds / secondsInWeek);
      const remainingSeconds = timeDifferenceInSeconds % secondsInWeek;
      const daysAgo = Math.floor(remainingSeconds / secondsInDay);

      if (daysAgo === 0) {
        return `${weeksAgo} week${weeksAgo === 1 ? "" : "s"} ago`;
      } else {
        return `${weeksAgo} week${weeksAgo === 1 ? "" : "s"} - ${daysAgo} day${
          daysAgo === 1 ? "" : "s"
        } ago`;
      }
    }
  }

  const [replyId, setReplyId] = useState(null);

  const formik = useFormik({
    enableReinitialize: true,

    initialValues: {
      replyComments: "",
    },

    validationSchema: Yup.object().shape({
      replyComments: Yup.string().required("Please enter a reply."),
    }),

    onSubmit: (values) => {
      const { replyComments } = values;
      const data = {
        replyComments,
        replyId,
        ticketId,
      };

      postComments(data);
      formik.resetForm();
      setOpenCommentId(null);
    },
  });

  return (
    <Box className="comment-page">
      <Box className="d-flex mb-4">
        <Box className="flex-shrink-0 profile">
          {comment.user.user_image ? (
            <img
              src={`${process.env.REACT_APP_BASE_URL}${comment.user.user_image}`}
              className="profile-img"
              alt="profile"
            />
          ) : (
            <ProfileImage username={comment.user.user_name} />
          )}
        </Box>
        <Box className="flex-grow-1 ms-3">
          <h5>
            <a href="*">{comment.user.user_name}</a>
            <small className="text-muted ms-3 fs-12">
              {comment.createdAt
                ? `${displayCommentTime(comment.createdAt)}`
                : ""}
            </small>
          </h5>
          <Box dangerouslySetInnerHTML={{ __html: comment.comments }} />

          {/* Show reply button for levels 1 and 2 */}
          {depth <= 2 && (
            <a
              className="badge text-muted bg-light"
              data-bs-toggle="collapse"
              href={`#collapseExample${comment.id}`}
              aria-expanded={openCommentId === comment.id ? "true" : "false"}
              aria-controls={`#collapseExample${comment.id}`}
              onClick={() => setOpenCommentId(comment.id)}
              id="reply"
            >
              <i className="mdi mdi-reply"></i> Reply
            </a>
          )}

          <Box
            className={`collapse ${openCommentId === comment.id ? "show" : ""}`}
            id={`collapseExample${comment.id}`}
          >
            <Form onSubmit={formik.handleSubmit} style={{ margin: "10px" }}>
              <TextEditor
                value={formik.values.replyComments}
                onChange={(data) => {
                  formik.setFieldValue("replyComments", data);
                  setReplyId(comment.id);
                }}
                filename="ticket-comment-attechment"
                // apiUrl={`${process.env.REACT_APP_BASE_URL}Newticketing-system/ticket-comments/attachments`}
                apiUrl={`${process.env.REACT_APP_BASE_URL}Newticketing-system/ticket-comments/attechments`}
              />
              {formik.errors.replyComments && formik.touched.replyComments && (
                <FormFeedback
                  type="invalid"
                  className="d-block"
                  style={{ color: "red" }}
                >
                  {formik.errors.replyComments}
                </FormFeedback>
              )}
              <Button
                type="submit"
                className="btn btn-primary ms-auto mt-2 send-btn btn-sm"
                data-toggle="collapse"
                data-target={`#collapseExample${comment.id}`}
              >
                Reply <SendIcon />
              </Button>
            </Form>
          </Box>

          {nestedComments && nestedComments.length > 0 && depth < 3 && (
            <Box className="replies" style={{ margin: "15px 10px" }}>
              {nestedComments.map((nestedComment) => (
                <Comments
                  key={nestedComment.id}
                  comment={nestedComment}
                  nestedComments={getNestedComments(nestedComment.id)}
                  postComments={postComments}
                  ticketId={ticketId}
                  openCommentId={openCommentId}
                  setOpenCommentId={setOpenCommentId}
                  getNestedComments={getNestedComments}
                  showReplyButton={depth < 2} // Hide reply button on depth 3
                  depth={depth + 1} // Increase depth for nested comments
                />
              ))}
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default Comments;
