import common from "../../../Components/Common/common";

export const changeInternalUserStatusRes = (props) => {
  const { response, navigate, getInternalUsers, ToastMessage } = props;

  if (response.code === 403) {
    navigate("/auth-404-alt");
  }
  if (response.status === "success") {
    getInternalUsers();
    ToastMessage.Success(response.data);
  }
};

export const updateInternalUsersRes = (props) => {
  const {
    response,
    navigate,
    clearState,
    getInternalUsers,
    setShow,
    ToastMessage,
    id,
  } = props;

  if (response.code === 403) {
    navigate("/auth-404-cover");
  }
  if (response.status === "success") {
    setShow(false);
    getInternalUsers();
    clearState();
    navigate("/core-users");
    ToastMessage.Success(
      id ? response.message.message : response.message.message
    );
  }
  if (response.status === "fail") {
    // setShow(false);
    getInternalUsers();
    navigate("/core-users");
    ToastMessage.Error(response.message);
  }
};

export const DeleteDepartmentRes = (props) => {
  const { response, navigate, getInternalUsers } = props;
  if (response.code === 403) {
    navigate("/auth-404-cover");
  }
  if (response.status === "success") {
    navigate("/core-users");
    getInternalUsers();
  }
  if (response.status === "fail") {
    getInternalUsers();
    navigate("/core-users");
  }
};

export const getInternalUsersRes = (props) => {
  const { response, selectedDepartment, setData, navigate } = props;
  if (response.data.code === 403) {
    navigate("/auth-404-cover");
  }

  const responseData = common.decrypt(response.data);
  const filteredData = responseData.filter((user) =>
    selectedDepartment ? user.department_id === selectedDepartment.value : true
  );
  setData(filteredData);
};
