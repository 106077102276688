import React, { useState, useEffect, useCallback } from "react";
import { Col, Row, Card } from "react-bootstrap";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch } from "react-redux";
import { addSettingsData, setTimeZone } from "../../store/settings/action";
import timeZone from "./timeZones.json";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Button,
  Box,
} from "@mui/material";
import { MenuHeight, VisuallyHiddenInput } from "../NewDashboard/constant";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import common from "../../Components/Common/common";
import { Spinner } from "reactstrap";

const Other = () => {
  const token = localStorage.getItem("token");
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const intialValues = {
    DEFAULT_DATE_FORMAT: "",
    DEFAULT_TIME_FORMAT: "",
    DEFAULT_TIME_ZONE: "",
    GUIDELINE_PDF_IMAGE: null,
  };

  // const timeZones = Intl.supportedValuesOf("timeZone");
  const timeZones = timeZone.map((timezone) => ({
    label: timezone.text,
    value: timezone.utc[0],
  }));

  const navigate = useNavigate();
  // const [logoImage, setLogoImage] = useState(null);
  const values = intialValues;

  const options = {
    defaultDateFormat: [
      { label: "d/m/Y (Eg: 01/03/2023)", value: "d/m/Y" },
      { label: "m/d/Y (Eg: 03/01/2023)", value: "m/d/Y" },
      { label: "Y/m/d (Eg: 2023/03/01)", value: "Y/m/d" },
      { label: "Y/d/m (Eg: 2023/01/03)", value: "Y/d/m" },
      { label: "M/d/Y (Eg: Mar/01/2023)", value: "M/d/Y" },
      { label: "M d Y (Eg: Mar 01 2023)", value: "M d Y" },
    ],
    timeFormat: [
      { label: "12 Hours", value: "12" },
      { label: "24 Hours", value: "24" },
    ],
    timezone: timeZones,
  };

  const PostData = async ({
    DEFAULT_DATE_FORMAT,
    DEFAULT_TIME_FORMAT,
    DEFAULT_TIME_ZONE,
    GUIDELINE_PDF_IMAGE,
  }) => {
    try {
      setIsLoading(true);
      let url = `${process.env.REACT_APP_BASE_URL}settings/add-settings`;

      let formData = new FormData();
      formData.append("DEFAULT_DATE_FORMAT", DEFAULT_DATE_FORMAT);
      formData.append("DEFAULT_TIME_FORMAT", DEFAULT_TIME_FORMAT);
      formData.append("DEFAULT_TIME_ZONE", DEFAULT_TIME_ZONE);
      formData.append("GUIDELINE_PDF_IMAGE", GUIDELINE_PDF_IMAGE);

      let reqData = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      };

      let result = await fetch(url, reqData);
      let response = await result.json();

      if (response.code === 403) {
        navigate("/auth-404-cover");
      }
      if (response.code === "200") {
        toast.success(response.message);
        getSettings();
      }
    } catch (error) {
      setIsLoading(false);
      toast.error("An error occurred while processing your request.");
    } finally {
      setIsLoading(false);
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: values,

    onSubmit: async (values, { setSubmitting }) => {
      dispatch(setTimeZone(values.DEFAULT_TIME_ZONE));
      try {
        await PostData(values);
      } catch (error) {
        console.error(error);
      }
    },
  });

  const getSettings = useCallback(async () => {
    try {
      let url = `${process.env.REACT_APP_BASE_URL}settings/get-settings`;
      let reqData = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      let result = await fetch(url, reqData);
      let response = await result.json();

      const decryptedData = common.decrypt(response.data);
      response.data = decryptedData;

      if (response.code === 403) {
        navigate("/auth-404-cover");
      }

      if (response.code === "200") {
        const fieldValuesMap = {};
        response.data.rows.forEach((row) => {
          fieldValuesMap[row.field_name] = row.field_value;
        });

        const settingData = response?.data?.rows;
        dispatch(addSettingsData(settingData));

        formik.setValues({
          ...formik.values,
          DEFAULT_DATE_FORMAT: fieldValuesMap["DEFAULT_DATE_FORMAT"] || "",
          DEFAULT_TIME_FORMAT: fieldValuesMap["DEFAULT_TIME_FORMAT"] || "",
          DEFAULT_TIME_ZONE: fieldValuesMap["DEFAULT_TIME_ZONE"] || "",
          GUIDELINE_PDF_IMAGE: fieldValuesMap["GUIDELINE_PDF_IMAGE"] || "",
        });
        if (fieldValuesMap["GUIDELINE_PDF_IMAGE"]) {
          const logoUrl = `${process.env.REACT_APP_BASE_URL}${fieldValuesMap["GUIDELINE_PDF_IMAGE"]}`;
          setUploadedFile(logoUrl);
        }
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      return null;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, token, dispatch]);

  useEffect(() => {
    getSettings();
  }, [getSettings]);

  const handleFieldChange = (field, value) => {
    formik.setFieldValue(field, value);
  };

  const handleFieldTouched = (field) => formik.setFieldTouched(field, true);

  // const [timezone, setTimezone] = useState("select");
  // const [datevalue, setDatevalue] = useState("select");
  // const [datetime, setDatetime] = useState("select");
  const [uploadedFile, setUploadedFile] = useState(null);

  // const handleFileChange = (event) => {
  //   const file = event.target.files[0];
  //   if (file) {
  //     const reader = new FileReader();
  //     reader.onload = (e) => {
  //       setUploadedFile(e.target.result);
  //     };
  //     reader.readAsDataURL(file);
  //   }
  // };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const allowedFormats = [
        "image/jpeg",
        "image/jpg",
        "image/png",
        "image/svg+xml",
      ];
      const validSize = file.size <= 1024 * 1024; // Adjusted size limit to 2MB

      if (!allowedFormats.includes(file.type)) {
        toast.error(
          "Invalid file format. Only JPEG, JPG, PNG, and SVG files are allowed."
        );
      } else if (!validSize) {
        toast.error(
          "File too large. Please upload an image less than 1MB in size."
        );
      } else {
        // Set formik field value
        formik.setFieldValue("GUIDELINE_PDF_IMAGE", file);

        // Read file as data URL
        const reader = new FileReader();
        reader.onload = (e) => {
          setUploadedFile(e.target.result); // Assuming setUploadedFile sets the uploaded file as a data URL
        };
        reader.readAsDataURL(file);
      }
    }
  };

  return (
    <React.Fragment>
      <Card>
        <Card.Body className="setting-card-body">
          <form noValidate onSubmit={formik.handleSubmit}>
            <Row className="g-4">
              <Col md={6}>
                {/* <div className="cm-floating mb-3">
                  <label>Time Zone</label>
                  <Select
                    options={options.timezone}
                    value={options.timezone.find(
                      (option) =>
                        option.value === formik.values.DEFAULT_TIME_ZONE
                    )}
                    onChange={(selectedOption) =>
                      handleFieldChange(
                        "DEFAULT_TIME_ZONE",
                        selectedOption.value
                      )
                    }
                    onBlur={() => handleFieldTouched("DEFAULT_TIME_ZONE")}
                    placeholder="Select TimeZone"
                  />
                </div> */}

                {/* <FormControl fullWidth>
                  <InputLabel htmlFor="time-zone-select">Time Zone</InputLabel>
                  <Select
                    fullWidth
                    label="Time Zone"
                    MenuProps={MenuHeight}
                    id="time-zone-select"
                    value={formik.values.DEFAULT_TIME_ZONE}
                    onChange={(e) =>
                      handleFieldChange("DEFAULT_TIME_ZONE", e.target.value)
                    }
                    onBlur={() => handleFieldTouched("DEFAULT_TIME_ZONE")}
                    placeholder="Select TimeZone"
                  >
                    {options?.timezone?.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl> */}
                <FormControl fullWidth>
                  <InputLabel htmlFor="time-zone-select">Time Zone</InputLabel>
                  <Select
                    fullWidth
                    label="Time Zone"
                    MenuProps={MenuHeight}
                    id="time-zone-select"
                    value={formik.values.DEFAULT_TIME_ZONE}
                    onChange={(e) =>
                      handleFieldChange("DEFAULT_TIME_ZONE", e.target.value)
                    }
                    onBlur={() => handleFieldTouched("DEFAULT_TIME_ZONE")}
                    placeholder="Select TimeZone"
                  >
                    {options?.timezone?.map((option, idx) => (
                      <MenuItem key={idx} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Col>

              <Col md={6}>
                {/* <div className="cm-floating mb-3">
                  <label>Default Date Format (d/m/Y)</label>
                  <Select
                    options={options.defaultDateFormat}
                    value={options.defaultDateFormat.find(
                      (option) =>
                        option.value === formik.values.DEFAULT_DATE_FORMAT
                    )}
                    onChange={(selectedOption) =>
                      handleFieldChange(
                        "DEFAULT_DATE_FORMAT",
                        selectedOption.value
                      )
                    }
                    onBlur={() => handleFieldTouched("DEFAULT_DATE_FORMAT")}
                    placeholder="Select Date Format"
                  />
                </div> */}
                <FormControl fullWidth>
                  <InputLabel>Default Date Format (d/m/Y)</InputLabel>
                  <Select
                    fullWidth
                    label="Default Date Format (d/m/Y)"
                    MenuProps={MenuHeight}
                    // value={datevalue}
                    value={formik.values.DEFAULT_DATE_FORMAT}
                    // onChange={(event) => {
                    //   setDatevalue(event.target.value);
                    // }}
                    onChange={(event) => {
                      formik.setFieldValue(
                        "DEFAULT_DATE_FORMAT",
                        event.target.value
                      );
                    }}
                    onBlur={() =>
                      formik.setFieldTouched("DEFAULT_DATE_FORMAT", true)
                    }
                  >
                    <MenuItem disabled value="select">
                      select
                    </MenuItem>
                    {options.defaultDateFormat.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Col>
              <Col md={6}>
                {/* <div className="cm-floating mb-3">
                  <label>Default Time Format</label>
                  <Select
                    options={options.timeFormat}
                    value={options.timeFormat.find(
                      (option) =>
                        option.value === formik.values.DEFAULT_TIME_FORMAT
                    )}
                    onChange={(selectedOption) =>
                      handleFieldChange(
                        "DEFAULT_TIME_FORMAT",
                        selectedOption.value
                      )
                    }
                    onBlur={() => handleFieldTouched("DEFAULT_TIME_FORMAT")}
                    placeholder="Select Time Format"
                  />
                </div> */}
                <FormControl fullWidth>
                  <InputLabel>Default Time Format</InputLabel>
                  <Select
                    fullWidth
                    label="Default Time Format"
                    MenuProps={MenuHeight}
                    value={formik.values.DEFAULT_TIME_FORMAT}
                    // onChange={(event) => {
                    //   setDatetime(event.target.value);
                    // }}
                    onChange={(event) => {
                      formik.setFieldValue(
                        "DEFAULT_TIME_FORMAT",
                        event.target.value
                      );
                    }}
                    onBlur={() =>
                      formik.setFieldTouched("DEFAULT_TIME_FORMAT", true)
                    }
                  >
                    <MenuItem disabled value="select">
                      select
                    </MenuItem>
                    {options.timeFormat.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Col>

              <Col md={6}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "start",
                    flexWrap: "wrap",
                    gap: "10px",
                  }}
                >
                  <Button
                    component="label"
                    role={undefined}
                    className="btn btn-outline-primary"
                    tabIndex={-1}
                    startIcon={<CloudUploadIcon />}
                  >
                    Default Image
                    <VisuallyHiddenInput
                      type="file"
                      onChange={handleFileChange}
                    />
                  </Button>
                  {uploadedFile && (
                    <Box
                      sx={{
                        background: "#f8f8f8",
                        padding: "5px",
                        width: "150px",
                        height: "110px",
                      }}
                    >
                      <img
                        src={uploadedFile}
                        alt="Uploaded"
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "contain",
                        }}
                      />
                    </Box>
                  )}
                </Box>
                {/* <div className="mb-30 pb-1" style={{ marginBottom: "30px" }}>
                  <div className="d-flex align-items-cenrter">
                    <label className="form_title" htmlFor="logo">
                      Default Image
                    </label>
                    <OverlayTrigger placement="bottom" overlay={logoTooltip}>
                      <span className="information_icon d-inline-block ms-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                          className="bi bi-info-circle ml-5"
                          viewBox="0 0 16 16"
                          width="24px"
                          height="24px"
                        >
                          <path d="M8 1.5a6.5 6.5 0 1 0 0 13 6.5 6.5 0 0 0 0-13zm0 12a5.5 5.5 0 1 1 0-11 5.5 5.5 0 0 1 0 11zm0-8a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 1 0v-3a.5.5 0 0 0-.5-.5zm0 6a.5.5 0 0 0-.5.5.5.5 0 0 0 1 0 .5.5 0 0 0-.5-.5z" />
                        </svg>
                      </span>
                    </OverlayTrigger>
                  </div>
                  <Input
                    className="form-control"
                    accept="image/*"
                    name="logo"
                    type="file"
                    onChange={handleLogoChange}
                  />
                  <div className="clearfix"></div>
                  <div className="mt-3">
                    <div className="image_thamb_150">
                      {logoImage && (
                        <img
                          src={logoImage}
                          className="mw-100"
                          // height="100px"
                          // width="150px"
                          alt="Default"
                        />
                      )}
                    </div>
                  </div>
                </div>
                {formik.errors.GUIDELINE_PDF_IMAGE &&
                  formik.touched.GUIDELINE_PDF_IMAGE && (
                    <span style={{ color: "red", float: "right" }}>
                      {formik.errors.GUIDELINE_PDF_IMAGE}
                    </span>
                  )} */}
              </Col>
              <Col sm={12}>
                <Button
                  className="btn btn-primary ms-auto d-block"
                  type="submit"
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <Spinner size="sm" variant="light" />
                  ) : (
                    <>Save &amp; Keep Editing</>
                  )}
                </Button>
              </Col>
            </Row>
          </form>
        </Card.Body>
      </Card>
    </React.Fragment>
  );
};

export default Other;
