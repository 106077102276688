// import common from "../../../Components/Common/common";
import { API_ROUTES } from "../../ApiRoutes";

export const AddEditWebsiteGuidelineReq = (props) => {
  let url = !props.id
    ? API_ROUTES.ADD_WEBSITE_GUIDELINE
    : API_ROUTES.UPDATE_WEBSITE_GUIDELINE + props.id;

  const formData = new FormData();
  if (props.values.imageFile && props.values.imageFile.constructor === File) {
    formData.append("website-guideline-image", props.values.imageFile);
  }
  if (props.values.pdfFile && props.values.pdfFile.constructor === File) {
    formData.append("website-guideline-pdf", props.values.pdfFile);
  }
  formData.append("user_id", props.userId);
  formData.append("website_guideline_title", props.values.title);
  formData.append("assets_id", props.values.asset_url_management);
  formData.append("website_guideline_description", props.values.description);

  const reqData = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    body: formData,
  };

  return { url: url, reqData };
};

export const ListWebsiteGuidelineReq = (props) => {
  const url = `${API_ROUTES.GET_WEBSITE_GUIDELINE}?searchParams=${props.query}`;

  let reqData = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };

  return { url: url, reqData };
};

export const DeleteWebsiteGuidelineReq = (props) => {
  let url = API_ROUTES.DELETE_WEBSITE_GUIDELINE + props.id;

  let reqData = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    body: JSON.stringify({ email: props.email }),
  };

  return { url: url, reqData };
};
