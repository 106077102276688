import React, { useCallback, useEffect, useState } from "react";
import { Card, Row, Col, Form, Button } from "react-bootstrap";
import { ToastMessage } from "../../../Components/Common/ToastMessage";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { API_ROUTES } from "../../../Api/ApiRoutes";
import { WebSecuTabStyle } from "../../NewDashboard/constant";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, TextField } from "@mui/material";
import Tab from "@mui/material/Tab";
import common from "../../../Components/Common/common";
import { Spinner } from "reactstrap";
import { useSelector } from "react-redux";
import axios from "axios";

const AddRole = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { id } = params;

  const [modules, setModules] = useState([]);
  const [rolePermission, setRolePermission] = useState([]);
  const [valuerole, setValuerole] = useState("1");
  const [roleName, setRoleName] = useState({ name: "", isAdmin: "" });
  const [isAdmin, setIsAdmin] = useState(false);
  const [loading, setLoading] = useState(false);

  const userData = useSelector((state) => state.userDetails.userData);
  const userPermission = useSelector(
    (state) => state.userDetails.userPermission
  );

  // Fetch role permission by ID
  const getRolePermissionById = useCallback(async () => {
    if (!id) return;
    try {
      const response = await axios.post(API_ROUTES.GET_ROLES_PERMISSION, {
        id,
      });
      if (response.status === "success") {
        const decryptedPermissions = common.decrypt(response.data);
        const decryptedRoleDetails = common.decrypt(response.message);

        setRolePermission(decryptedPermissions);
        setRoleName(decryptedRoleDetails);
        setIsAdmin(decryptedRoleDetails.chr_is_admin === "Y");
        validation.setFieldValue("name", decryptedRoleDetails?.name);
      }
    } catch (error) {
      console.error("Error in getting the data...", error.message);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    getRolePermissionById();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getRolePermissionById]);

  // Fetch module data
  const getData = useCallback(async () => {
    try {
      const response = await axios.post(API_ROUTES.GET_MODULE, {
        userPermission,
      });

      if (response.status === "success") {
        const decryptedData = common.decrypt(response.data);
        let updatedModules = decryptedData;

        if (rolePermission.length > 0) {
          updatedModules = updatedModules.map((module) => {
            const updatedPermissions = module.permissions.map((permission) => {
              if (rolePermission.includes(permission.id)) {
                return {
                  ...permission,
                  selected: true,
                };
              }
              return permission;
            });

            return {
              ...module,
              permissions: updatedPermissions,
            };
          });
        }

        setModules(updatedModules);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rolePermission]);

  useEffect(() => {
    getData();
  }, [getData]);

  const handlePermissionToggle = (moduleId, permissionName) => {
    const updatedModules = modules.map((module) => {
      if (module.module_id === moduleId) {
        const updatedPermissions = module.permissions.map((permission) => {
          if (permission.name === permissionName) {
            return {
              ...permission,
              selected: !permission.selected,
            };
          }
          return permission;
        });

        return {
          ...module,
          permissions: updatedPermissions,
        };
      }
      return module;
    });

    setModules(updatedModules);
  };

  const handleSelectAllToggle = (moduleId) => {
    const updatedModules = modules.map((module) => {
      if (module.module_id === moduleId) {
        const updatedPermissions = module.permissions.map((permission) => ({
          ...permission,
          selected: !module.permissions.every((p) => p.selected),
        }));

        return {
          ...module,
          permissions: updatedPermissions,
        };
      }
      return module;
    });
    setModules(updatedModules);
  };

  // Generate selected module permissions
  const generateData = useCallback(() => {
    const selectedModules = modules.filter((module) =>
      module.permissions.some((permission) => permission.selected)
    );

    return selectedModules.map((selectedModule) => ({
      name: selectedModule.module_name,
      canList:
        selectedModule.permissions.find(
          (permission) => permission.name === "list" && permission.selected
        )?.id || "",
      canCreate:
        selectedModule.permissions.find(
          (permission) => permission.name === "create" && permission.selected
        )?.id || "",
      canModify:
        selectedModule.permissions.find(
          (permission) => permission.name === "modify" && permission.selected
        )?.id || "",
      canDelete:
        selectedModule.permissions.find(
          (permission) => permission.name === "delete" && permission.selected
        )?.id || "",
    }));
  }, [modules]);

  const validation = useFormik({
    initialValues: { name: "", chr_is_admin: "" },
    validationSchema: Yup.object({
      name: Yup.string().required("please enter the name"),
    }),
    onSubmit: () => {
      postData();
    },
  });

  const postData = async (keepEditing = false) => {
    if (!validation.values.name.trim())
      return ToastMessage.Error("role name cannot be empty");

    let userData = {
      name: id ? roleName.name : validation.values.name,
      chr_is_admin: isAdmin ? "Y" : "N",
      modulePermissions: generateData(),
    };

    try {
      setLoading(true);

      const url = id ? `role/roles/${id}` : "role/roles";
      const response = await axios.post(url, userData);

      if (response.status === "success") {
        ToastMessage.Success(
          `Role has been successfully ${id ? "updated" : "added"}`
        );

        if (!keepEditing) {
          setTimeout(() => navigate("/role-manager"), 500);
        } else {
          getRolePermissionById();
          getData();
        }
      } else {
        ToastMessage.Error(response.message);
      }
    } catch (error) {
      ToastMessage.Error(error?.message || "An error occurred");
    } finally {
      setLoading(false);
    }
  };

  const renderModuleRows = (category) => {
    const crudArr =
      category === "General" ? ["list", "create", "modify", "delete"] : [];
    return modules
      .filter((module) => module.category === category)
      .map((module) => (
        <tr key={module.module_id}>
          <td>{module.module_name}</td>
          <td className="text-center">
            <Box>
              <input
                className="form-check-input"
                type="checkbox"
                checked={module.permissions.every((p) => p.selected)}
                onChange={() => handleSelectAllToggle(module.module_id)}
              />
            </Box>
          </td>
          {crudArr.map((permissionName) => {
            const permission = module.permissions.find(
              (p) => p.name === permissionName
            );
            return (
              <td key={permissionName} className="text-center">
                <Box>
                  {permission ? (
                    <input
                      checked={permission.selected}
                      onChange={() =>
                        handlePermissionToggle(
                          module.module_id,
                          permission.name
                        )
                      }
                      className="form-check-input"
                      type="checkbox"
                    />
                  ) : (
                    <> - </>
                  )}
                </Box>
              </td>
            );
          })}
        </tr>
      ));
  };

  return (
    <Box className="add-role-page">
      <Form>
        <Row className="g-3 mb-4 align-items-center">
          <Col md={6}>
            <TextField
              fullWidth
              label="Name *"
              name="name"
              placeholder="Name"
              value={id ? roleName.name : validation.values.name}
              onChange={(e) =>
                id
                  ? setRoleName({ ...roleName, name: e.target.value })
                  : validation.handleChange(e)
              }
            />
          </Col>
          <Col md={6} sm={12}>
            <Box className="d-flex justify-content-end fs-16">
              <strong>Is Admin Role ? </strong>
              <span style={{ paddingLeft: "10px" }}>
                <Form.Check
                  type="switch"
                  id="custom-switch"
                  checked={isAdmin}
                  onChange={() => setIsAdmin(!isAdmin)}
                />
              </span>
            </Box>
          </Col>
        </Row>
      </Form>

      <Card>
        <TabContext value={valuerole}>
          <TabList
            onChange={(event, value) => setValuerole(value)}
            variant="fullWidth"
            sx={WebSecuTabStyle}
          >
            {["Super Admin"].includes(userData?.role) && (
              <Tab label="General" value="1" />
            )}

            {["Super Admin"].includes(userData?.role) && (
              <Tab label="Other" value="2" />
            )}
          </TabList>
          <TabPanel value="1" className="tab-panel">
            <Box className="table-responsive table-design">
              <table className="table table-hover table-nowrap mb-0">
                <thead>
                  <tr>
                    <th scope="col">Module & Access</th>
                    <th
                      scope="col"
                      className="text-center"
                      style={{ width: "110px" }}
                    >
                      All
                    </th>
                    <th
                      scope="col"
                      className="text-center"
                      style={{ width: "110px" }}
                    >
                      List
                    </th>
                    <th
                      scope="col"
                      className="text-center"
                      style={{ width: "110px" }}
                    >
                      Create
                    </th>
                    <th
                      scope="col"
                      className="text-center"
                      style={{ width: "110px" }}
                    >
                      Modify
                    </th>
                    <th
                      scope="col"
                      className="text-center"
                      style={{ width: "110px" }}
                    >
                      Delete
                    </th>
                  </tr>
                </thead>
                <tbody>{renderModuleRows("General")}</tbody>
              </table>
            </Box>
          </TabPanel>
          <TabPanel value="2" className="tab-panel">
            <Box className="table-responsive table-design">
              <table className="table table-hover table-nowrap mb-0">
                <thead>
                  <tr>
                    <th scope="col">Module &amp; Access</th>
                    <th style={{ width: "40px" }}>&nbsp;</th>
                  </tr>
                </thead>
                <tbody>{renderModuleRows("Other")}</tbody>
              </table>
            </Box>
          </TabPanel>
        </TabContext>
      </Card>

      <Card.Footer className="border-0 mt-3">
        <Box className="d-flex flex-wrap justify-content-end">
          <Button
            onClick={() => postData(true)}
            className="btn btn-warning me-2 mb-2 flex-grow-1 flex-sm-grow-0"
          >
            Save & Keep Editing
          </Button>
          <Button
            onClick={() => postData()}
            className="btn btn-success me-2 mb-2 flex-grow-1 flex-sm-grow-0"
            disabled={loading}
          >
            {loading ? <Spinner size="sm" variant="light" /> : "Save & Exit"}
          </Button>

          <Button
            onClick={() => navigate("/role-manager")}
            className="btn btn-light me-2 mb-2 flex-grow-1 flex-sm-grow-0"
          >
            Cancel
          </Button>
        </Box>
      </Card.Footer>
    </Box>
  );
};

export default AddRole;
