import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Card, CardBody, Row } from "reactstrap";
import SwiperCore, { Autoplay } from "swiper";
import { ToastMessage } from "../../../../Components/Common/ToastMessage";

import ProfileImage from "../../../../Components/Common/ProfileImage";
// import { useSelector } from "react-redux";
// import { USERS } from "../../../../Components/Common/ModuleName";
import axios from "axios";
import {
  Avatar,
  Badge,
  Box,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Skeleton,
  Tab,
} from "@mui/material";
import { EditIcon } from "../../../../Components/Common/SvgIcons";
import coverImg from "../../../../assets/images/profile-bg.jpg";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import CallIcon from "@mui/icons-material/Call";
import EmailIcon from "@mui/icons-material/Email";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { PerformanceTabStyle } from "../../../NewDashboard/constant";
import ProfileDetail from "../../../userManagement/DeveloperProfile/ProfileDetail";
import EditProfile from "./EditProfile";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { addUserData } from "../../../../store/actions";
import { Modal } from "react-bootstrap";
import ChangePassword from "./ChangePassword";
import common from "../../../../Components/Common/common";

const SimplePage = (props) => {
  const token = localStorage.getItem("token");
  // const permissionData = useSelector(
  //   (state) => state.userDetails.userPermission
  // );
  // const hasPermission = permissionData?.find(
  //   (item) => item.module_name === USERS
  // );

  const navigate = useNavigate();
  const params = useParams();

  SwiperCore.use([Autoplay]);
  const [updatedValue, setUpdatedValue] = useState(false);

  const [data, setData] = useState([]);
  const [websiteCategory, setWebsiteCategory] = useState([]);
  // const [dataLoading, setDataLoading] = useState(false);
  const [selectedCoverImage, setSelectedCoverImage] = useState(null);
  const [show, setShow] = useState(false);
  // const [selectedCoverImage, setSelectedCoverImage] = useState(null);
  const [BgImage, setBgImage] = useState(false);
  const [selectedBgImage, setSelectedBgImage] = useState(null);
  const [dataLoading, setDataLoading] = useState(false);

  const dispatch = useDispatch();

  const getUserData = useCallback(async () => {
    setDataLoading(true);
    let url = `${process.env.REACT_APP_BASE_URL}users-website/user-management-users/${params.id}`;
    let reqData = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    let result = await fetch(url, reqData);
    let response = await result.json();
    if (response.status === "success") {
      const responseData = common.decrypt(response.data);
      setData(responseData);
      setDataLoading(false);
    }
    if (response.status === "fail") {
      setDataLoading(false);
      ToastMessage.Error("Error in fetching the data, Please try again later");
    }
  }, [token, params.id]);

  const getWebsiteData = useCallback(async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}websiteCategories/get-category`
      );
      const responseData = common.decrypt(response.data);

      if (response.status === "success" && responseData?.length > 0) {
        const formattedData = responseData.map((item) => ({
          id: item.id,
          category: item.category,
        }));
        setWebsiteCategory(formattedData);
      } else {
        console.error("No data found for website categories.");
      }
    } catch (error) {
      console.error("Error fetching website data:", error);
    }
  }, []);

  useEffect(() => {
    getUserData();
    getWebsiteData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getUserData, updatedValue]);

  let departmentList = websiteCategory.filter(
    (option) => option.id === data.department_id
  );

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      fullName: data ? data.user_name : "",
      contactNo: data.contact_no,
      country: data.country,
      email: data.email,
      department: data.department_id,
      organization: data.organization,
      // role: data.role,
      organizationAddress: data.organization_address || "",
      status: data.status,
      description: data.description,
      user_image: data ? data.user_image : "",
      profile_picture: data ? data.profile_picture : "",
    },

    onSubmit: (values) => {
      updateUsers(values);
    },
  });

  const handleChangeImage = (event) => {
    const file = event.target.files[0];
    if (file) {
      const allowedFormats = [
        "image/jpeg",
        "image/jpg",
        "image/png",
        "image/svg+xml",
      ];
      const validSize = file.size <= 1024 * 1024;

      if (!allowedFormats.includes(file.type)) {
        validation.setFieldError(
          "user_image",
          "Invalid file format. Only JPEG, JPG, PNG, and SVG files are allowed."
        );
        event.target.value = null;
      } else if (!validSize) {
        validation.setFieldError(
          "user_image",
          "Failed to upload an image. The image maximum size is 1MB."
        );
        event.target.value = null;
      } else {
        validation.setFieldError("user_image", "");
        validation.setFieldValue("user_image", file);
        const imageUrl = URL.createObjectURL(file);
        setSelectedCoverImage(imageUrl);
      }
    }
  };

  const handleChangeCoverImage = (event) => {
    const file = event.target.files[0];
    if (file) {
      const allowedFormats = [
        "image/jpeg",
        "image/jpg",
        "image/png",
        "image/svg+xml",
      ];
      const validSize = file.size <= 1024 * 1024;

      if (!allowedFormats.includes(file.type)) {
        validation.setFieldError(
          "profile_picture",
          "Invalid file format. Only JPEG, JPG, PNG, and SVG files are allowed."
        );
        event.target.value = null;
      } else if (!validSize) {
        validation.setFieldError(
          "profile_picture",
          "Failed to upload an image. The image maximum size is 1MB."
        );
        event.target.value = null;
      } else {
        validation.setFieldError("profile_picture", "");
        validation.setFieldValue("profile_picture", file);
        const imageUrl = URL.createObjectURL(file);

        setSelectedBgImage(imageUrl);
      }
    }
  };

  const handleShow = () => setShow(true);
  const handleBg = () => setBgImage(true);

  const handleClose = () => {
    validation.setFieldValue("departmentImage", null);
    setShow(false);
    setSelectedCoverImage(false);
    setBgImage(false);
    setSelectedBgImage(false);
  };

  const handleSaveChanges = () => {
    validation.handleSubmit();
    handleClose();
  };

  const updateUsers = async (values) => {
    try {
      let data = new FormData();
      if (values.user_image && values.user_image.constructor === File) {
        data.append("user_image", values.user_image);
      }

      if (
        values.profile_picture &&
        values.profile_picture.constructor === File
      ) {
        data.append("profile_picture", values.profile_picture);
      }
      data.append("user_name", values.fullName);
      data.append("organization", values.organization);
      data.append("contact_no", values.contactNo);
      data.append("country", values.country);
      data.append("email", values.email);
      data.append("organization_address", values.organizationAddress);
      data.append("department_id", values.department);
      // data.append("role", values.role);
      data.append("status", values.status);
      data.append("description", values.description);

      let reqData = {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: data,
      };

      let result = await fetch(
        `${process.env.REACT_APP_BASE_URL}users-website/update-user-management-users/${params.id}`,
        reqData
      );
      let response = await result.json();
      if (response.status === "success") {
        ToastMessage.Success("Details has been updated successfully.");
        dispatch(addUserData(response.data));
        localStorage.setItem("user_name", values.fullName);
        setTimeout(() => {
          navigate("/pages-profile/" + params.id);
        }, 2000);
        getUserData();
      } else {
        getUserData();
        ToastMessage.Error("An error occurred. Please try again later.");
      }
    } catch (error) {
      return null;
    }
  };

  const [profilevalue, setProfilevalue] = useState("1");
  const handleChangeProfile = (event, newValue) => {
    setProfilevalue(newValue);
  };

  return (
    <Box className="developerprofile-details myprofile-page">
      <Card className="custom-card">
        <CardBody>
          <div className="panel profile-cover">
            <div className="profile-cover__img">
              <Badge
                overlap="circular"
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                badgeContent={
                  <span className="cursor-pointer" onClick={handleShow}>
                    <EditIcon />
                  </span>
                }
                sx={{
                  "& .MuiBadge-badge": {
                    width: "24px",
                    height: "24px",
                    background: "#9ea3ab",
                    outline: "2px solid #fff",
                    padding: "4px",
                    borderRadius: "50%",
                    "svg path": {
                      fill: "#fff",
                    },
                    svg: {
                      width: "12px",
                    },
                  },
                }}
              >
                {data.user_image != null ? (
                  <Avatar
                    src={`${process.env.REACT_APP_BASE_URL}${data.user_image}`}
                    alt="profile"
                    sx={{ width: 74, height: 74, background: "#1A2232" }}
                    className="avatar-img"
                  />
                ) : (
                  <ProfileImage
                    username={data.user_name || "Demo User"}
                    height="6rem"
                    width="6rem"
                    fontSize="30px"
                  />
                )}
              </Badge>

              <h3 className="h3">{data.user_name}</h3>
            </div>
            <div className="profile-cover_action">
              {data.profile_picture !== null && data.profile_picture !== "" ? (
                <img
                  src={`${process.env.REACT_APP_BASE_URL}${data.profile_picture}`}
                  alt="profile"
                  sx={{ width: 100, height: 100 }}
                />
              ) : (
                <img src={coverImg} alt="Cover img" />
              )}
              <IconButton
                sx={{ marginBottom: "175px" }}
                className="edit-icon"
                onClick={handleBg}
              >
                <EditIcon />
              </IconButton>
            </div>
            <div className="profile-cover__info d-none">
              <ul className="nav">
                <li>
                  <strong>123</strong>Projects
                </li>
                <li>
                  <strong>213</strong>Skills
                </li>
                <li>
                  <strong>545</strong>Tasks
                </li>
              </ul>
            </div>
          </div>
        </CardBody>
      </Card>
      <Card style={{ minHeight: "400px" }}>
        <TabContext value={profilevalue}>
          <Box>
            <TabList
              onChange={handleChangeProfile}
              variant="scrollable"
              allowScrollButtonsMobile
              sx={PerformanceTabStyle}
            >
              <Tab label="About" value="1" />
              <Tab label="Personal Details" value="2" />
              <Tab label="Change Password" value="3" />
            </TabList>
          </Box>
          <TabPanel value="1" className="performance-tab-panel">
            <Box>
              <h5 className="headeing">Info</h5>
              {dataLoading ? (
                <>
                  <Skeleton variant="text" width={600} height={30} />
                  <Skeleton variant="text" width={300} height={30} />
                </>
              ) : (
                <>
                  <p>{data.description}</p>
                </>
              )}
              <List>
                {data?.contact_no && (
                  <ListItem>
                    <ListItemIcon>
                      <CallIcon />
                    </ListItemIcon>
                    {dataLoading ? (
                      <Skeleton variant="text" width={100} height={30} />
                    ) : (
                      <>
                        <a
                          href={`tel:${data.contact_no}`}
                          style={{ marginRight: "8px" }}
                        >
                          <ListItemText
                            primary={data.contact_no ? data.contact_no : "-"}
                          />
                        </a>
                      </>
                    )}
                  </ListItem>
                )}
                {data?.email && (
                  <ListItem>
                    <ListItemIcon>
                      <EmailIcon />
                    </ListItemIcon>
                    {dataLoading ? (
                      <Skeleton variant="text" width={100} height={30} />
                    ) : (
                      <>
                        <a
                          href={`mailto:${data.email}`}
                          style={{ marginRight: "8px" }}
                        >
                          <ListItemText
                            primary={data.email ? data.email : "-"}
                          />
                        </a>
                      </>
                    )}
                  </ListItem>
                )}
                {data?.organization_address && (
                  <ListItem>
                    <ListItemIcon>
                      <LocationOnIcon />
                    </ListItemIcon>
                    {dataLoading ? (
                      <Skeleton variant="text" width={100} height={30} />
                    ) : (
                      <>
                        <ListItemText
                          primary={
                            data.organization_address
                              ? data.organization_address
                              : "-"
                          }
                        />
                      </>
                    )}
                  </ListItem>
                )}
              </List>
            </Box>
            <Box>
              <h5 className="headeing">Details</h5>
              <Row>
                <Box className="col-sm-12 col-md-12 col-lg-8 col-xl-6 col-xxl-5 col-xxxl-4">
                  <ProfileDetail data={data} departmentList={departmentList} />
                </Box>
              </Row>
            </Box>
          </TabPanel>
          <TabPanel value="2" className="performance-tab-panel">
            <EditProfile
              setUpdatedValue={setUpdatedValue}
              updatedValue={updatedValue}
              setProfilevalue={setProfilevalue}
            />
          </TabPanel>
          <TabPanel value="3" className="performance-tab-panel">
            <ChangePassword />
          </TabPanel>
        </TabContext>
      </Card>
      <Modal show={show} onHide={handleClose}>
        <Modal.Body className="p-3 profile-setting-img">
          <Box className="overlay-content mb-2">
            <Box className="profile-photo-edit">
              <input
                id="profile-cover-img-file-input"
                type="file"
                className="profile-foreground-img-file-input"
                name="user_image"
                accept="image/*"
                onChange={(e) => {
                  handleChangeImage(e);
                  validation.setFieldTouched("user_image", true, false);
                }}
                onBlur={validation.handleBlur}
              />
              <label
                htmlFor="profile-cover-img-file-input"
                className="profile-photo-edit d-flex gap-2 mb-0"
              >
                <CloudUploadIcon />
                Upload
              </label>
            </Box>
            {validation.errors.user_image && validation.touched.user_image ? (
              <span className="error-text">{validation.errors.user_image}</span>
            ) : null}
          </Box>
          {selectedCoverImage || data.user_image ? (
            <Box className="profile-wid-img">
              <img
                name="user_image"
                src={
                  selectedCoverImage ||
                  `${process.env.REACT_APP_BASE_URL}${data.user_image}`
                }
                alt="user-profile"
              />
            </Box>
          ) : (
            <Box className="profile-wid-img">
              <ProfileImage
                username={data.user_name || "Demo User"}
                height="6rem"
                width="6rem"
                fontSize="30px"
              />
            </Box>
          )}
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-primary" onClick={handleClose}>
            Close
          </button>
          <button
            className="btn btn-dark"
            type="submit"
            onClick={handleSaveChanges}
          >
            Save Changes
          </button>
        </Modal.Footer>
      </Modal>
      <Modal show={BgImage} onHide={handleClose}>
        <Modal.Body className="text-center p-3 position-relative">
          <Box className="profile-setting-img">
            {selectedBgImage || data.profile_picture ? (
              <img
                name="profile_picture"
                src={
                  selectedBgImage ||
                  `${process.env.REACT_APP_BASE_URL}${data.profile_picture}`
                }
                className="profile-wid-img"
                alt="user-profile"
                style={{ height: "100%", width: "100%" }}
              />
            ) : (
              <img
                src={coverImg}
                alt="Cover img"
                style={{ height: "100%", width: "100%" }}
              />
            )}
            <Box className="overlay-content">
              <Box className="text-end">
                <Box className="p-0 ms-auto rounded-circle profile-photo-edit">
                  <input
                    id="profile-cover-img-file-input"
                    type="file"
                    className="profile-foreground-img-file-input"
                    name="profile_picture"
                    accept="image/*"
                    onChange={(e) => {
                      handleChangeCoverImage(e);
                      validation.setFieldTouched(
                        "profile_picture",
                        true,
                        false
                      );
                    }}
                    onBlur={validation.handleBlur}
                  />
                  <label
                    htmlFor="profile-cover-img-file-input"
                    className="profile-photo-edit d-flex gap-2"
                  >
                    <CloudUploadIcon />
                    Upload
                  </label>
                </Box>
              </Box>
              {validation.errors.profile_picture &&
              validation.touched.profile_picture ? (
                <span style={{ color: "red" }}>
                  {validation.errors.profile_picture}
                </span>
              ) : null}
            </Box>
          </Box>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-primary" onClick={handleClose}>
            Close
          </button>
          <button
            className="btn btn-dark"
            type="submit"
            onClick={handleSaveChanges}
          >
            Save Changes
          </button>
        </Modal.Footer>
      </Modal>
    </Box>
  );
};

export default SimplePage;
