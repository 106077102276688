import common from "../../../Components/Common/common";
import { socket } from "../../../Components/Common/socket";

export const fetchOnlineAssetsRes = (props) => {
  const { response, setAssetsData, navigate, setNotesPermission, sendData } =
    props;
  const responseData = common.decrypt(response.data);
  const responseData1 = common.decrypt(response.message);
  switch (response.code) {
    case "401":
      navigate("/auth-404-cover");
      break;
    case "403":
      navigate("/auth-404-cover");
      break;
    case "404":
      navigate("/auth-404-cover");
      break;
    case "200":
      setAssetsData(responseData);
      sendData && sendData(responseData);
      setNotesPermission && setNotesPermission(responseData1);
      break;
    default:
      navigate("/dashboard");
  }
};

export const refreshTestApiRes = (props) => {
  const { response, setTest, ToastMessage } = props;

  if (response.status === "success") {
    setTest(response.data);
    ToastMessage.Success("Security scanning completed.");
  }

  if (response.status === "fail") {
    ToastMessage.Error(response.message);
  }
};

export const postScheduleDataRes = (props) => {
  const {
    response,
    ToastMessage,
    fetchSecurityHistory,
    fetchSecurityScanTime,
    setScheduleDate,
    setShowFirstModal,
    setScanType,
  } = props;

  if (response.status === "success") {
    ToastMessage.Success(response.message);
    fetchSecurityHistory();
    fetchSecurityScanTime();
    setScheduleDate("");
    setShowFirstModal(false);
    setScanType("false");
    socket.emit("websecurityscan");
  }

  if (response.status === "fail") {
    ToastMessage.Error(response.message);
    setScheduleDate("");
    setShowFirstModal(false);
  }
};

export const fetchSecurityHistoryRes = (props) => {
  const { response, setSecurityHistory, setSecurityHistoryCount } = props;

  if (response.status === "success") {
    const decryptedData = common.decrypt(response.data);
    response.data = decryptedData;
    setSecurityHistory(response?.data?.rows);
    setSecurityHistoryCount(response?.data?.count);
  }

  if (response.status === "fail") {
    return null;
  }
};

export const fetchSecurityScanTimeRes = (props) => {
  const { response, setSecurityHistoryScanTime } = props;

  if (response.status === "success") {
    setSecurityHistoryScanTime(response?.data?.rows);
  }

  if (response.status === "fail") {
    return null;
  }
};
