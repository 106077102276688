import { useCallback, useEffect, useRef, useState } from "react";
import { LinewithDataLabels } from "../../../Charts/ApexCharts/LineCharts/LineCharts";
import { Box } from "@mui/material";
import { Card } from "react-bootstrap";
// import { useSelector } from "react-redux";
// import { useParams } from "react-router-dom";
import axios from "axios";
import common from "../../../../Components/Common/common";

const WebsiteAudienceMetrics = (props) => {
  // const params = useParams();

  // const { apiDataLoading, apiLoadingAsset } = useSelector(
  //   (state) => state.onlineAssets
  // );

  const [currentYear, setCurrentYear] = useState(1);
  const [googleData, setGoogleData] = useState(null);

  const googleDataRef = useRef(googleData);

  useEffect(() => {
    if (googleData !== null) {
      if (Object.keys(googleData)?.length <= 6) return;
      googleDataRef.current = googleData;
    }
  }, [googleData]);

  const fetchAnalyticsData = useCallback(
    async (days) => {
      try {
        let response = await axios.post("asset/analytics-data", {
          assets_id: props.paramsId,
          day: days || "30",
        });

        const decryptedData = common.decrypt(response.data);
        response.data = decryptedData;

        if (response.status === "success") {
          setGoogleData(response.data);
        }
      } catch (error) {
        console.error("Error fetching analytics data:", error);
      }
    },
    [props.paramsId]
  );

  useEffect(() => {
    fetchAnalyticsData();
  }, [fetchAnalyticsData]);

  //Polling is done using the useEffect
  useEffect(() => {
    let intervalId;

    const startPolling = () => {
      intervalId = setInterval(() => {
        if (googleDataRef.current === null) fetchAnalyticsData();
        else clearInterval(intervalId);
      }, 30000);
    };

    if (props.assetsData?.isAPIFetched === false) {
      startPolling();
    } else if (props.assetsData?.isAPIFetched === true && intervalId) {
      clearInterval(intervalId);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [props.assetsData?.isAPIFetched, fetchAnalyticsData]);

  return (
    <>
      <Card className="main-card metric-card">
        <Card.Header className="d-flex align-items-center justify-content-between flex-wrap gap-3">
          <Box>
            <h4 className="card-title mb-0">Website Audience Metrics</h4>
            <p className="mb-0 mt-2">
              Audience to which the users belonged while on the current range.
            </p>
          </Box>
          {props.assetsData?.isAPIFetched === false ||
          googleData === null ? null : (
            <Box className="ms-auto">
              <button
                className="btn btn-primary btn-sm"
                onClick={() => setCurrentYear("0")}
              >
                Current Year
              </button>
              <button
                className="btn btn-sm"
                onClick={() => setCurrentYear("1")}
              >
                1Y
              </button>
              <button
                className="btn btn-sm"
                onClick={() => setCurrentYear("3")}
              >
                3Y
              </button>
              <button
                className="btn btn-sm"
                onClick={() => setCurrentYear("5")}
              >
                5Y
              </button>
            </Box>
          )}
        </Card.Header>
        <Box className="card-body">
          {props.assetsData?.isAPIFetched === false || googleData === null ? (
            <div className="d-flex flex-column flex-grow-1 justify-content-center align-items-center mt-5">
              <div className="card-body text-center">
                <h5 className="text-muted">
                  We are currently retrieving the analytics data. Please hold on
                  for a moment while we gather all the details.
                </h5>
              </div>
            </div>
          ) : (
            <>
              <LinewithDataLabels
                year={currentYear}
                dataColors='["--vz-primary", "--vz-info"]'
              />
            </>
          )}
        </Box>
      </Card>
    </>
  );
};

export default WebsiteAudienceMetrics;
