import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Card, Nav } from "react-bootstrap";
import { Box, Grid, Skeleton } from "@mui/material";
import { getSiteStatus } from "../../../../helpers/Help/CommomApi";
import common from "../../../../Components/Common/common";
import { ToastMessage } from "../../../../Components/Common/ToastMessage";
import ReactApexChart from "react-apexcharts";
import RunScan from "./runScan";

const UpTime = (props) => {
  const token = localStorage.getItem("token");
  const role = localStorage.getItem("role");

  const [uptimeRobot, setUptimeData] = useState("");
  const [chartData, setChartData] = useState([]);
  const [timeFilter, setTimeFilter] = useState("30");
  const [filterDays, setFilterDays] = useState(30);
  const [loading, setLoading] = useState(false);
  const [skeleton, setSkeleton] = useState(false);

  const uptimeRobotRef = useRef(uptimeRobot);
  const chartDataRef = useRef(chartData);

  useEffect(() => {
    if (
      Object.keys(uptimeRobot).length === 2 &&
      Object.hasOwn(uptimeRobot, "avg_avg_uptime") &&
      Object.hasOwn(uptimeRobot, "avg_response_time")
    )
      return;

    uptimeRobotRef.current = uptimeRobot;
  }, [uptimeRobot]);

  useEffect(() => {
    chartDataRef.current = chartData;
  }, [chartData]);

  const handleClickDays = (days, module) => {
    setFilterDays(days);
    if (module === "upTime") {
      setTimeFilter(days);
    }
  };

  var requestOptions = useMemo(() => {
    var urlencoded = new URLSearchParams();
    urlencoded.append("assets_id", props.assetId);
    urlencoded.append("uptimeDays", timeFilter);

    return {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: urlencoded,
      redirect: "follow",
    };
  }, [props.assetId, timeFilter, token]);

  const fetchUptimeData = useCallback(async () => {
    try {
      setSkeleton(true);
      let url = `${process.env.REACT_APP_BASE_URL}asset/site-status-days`;
      const response = await fetch(url, requestOptions);
      let data = await response.json();
      const decryptedData = common.decrypt(data.data.result);
      const decryptedChartData = common.decrypt(data.data.chartData);
      data.data.result = decryptedData;
      data.data.chartData = decryptedChartData;

      if (data.status === "success") {
        setUptimeData(data.data.result);
        setChartData(data.data.chartData);
        // Call the parent function with the data
        props.onDataReceived(data.data.result);
      } else {
        console.error("error in getting the data...");
      }
    } catch (error) {
      setSkeleton(false);
      console.error(error.message);
    } finally {
      setSkeleton(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestOptions]);

  useEffect(() => {
    fetchUptimeData();
  }, [fetchUptimeData]);

  useEffect(() => {
    let intervalId;

    const startPolling = () => {
      intervalId = setInterval(() => {
        if (
          uptimeRobotRef.current === "" ||
          chartDataRef.current.length === 0
        ) {
          fetchUptimeData();
        } else {
          clearInterval(intervalId);
        }
      }, 30000);
    };

    if (props?.assetsData?.isAPIFetched === false) {
      startPolling();
    } else if (props?.assetsData?.isAPIFetched === true && intervalId) {
      clearInterval(intervalId);
    }
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [props?.assetsData?.isAPIFetched, fetchUptimeData]);

  // useMemo(() => {
  //   let intervalId;

  //   const startPolling = () => {
  //     intervalId = setInterval(() => {
  //       if (
  //         uptimeRobotRef.current === "" ||
  //         chartDataRef.current.length === 0
  //       ) {
  //         fetchUptimeData();
  //       } else {
  //         clearInterval(intervalId);
  //       }
  //     }, 30000);
  //   };

  //   if (props?.assetsData?.isAPIFetched === false) {
  //     startPolling();
  //   }

  //   return () => {
  //     if (intervalId) {
  //       clearInterval(intervalId);
  //     }
  //   };
  // }, [props?.assetsData?.isAPIFetched, fetchUptimeData]);

  let dataForDays = [];

  let uptimeData = chartData.map((item) => item.avg_uptime);
  let downtimeData = chartData.map((item) => item.downtime);
  let dynamicDate = chartData.map((item) => item.date);

  // const currentDate = new Date().getTime();
  const staticUptimeValue = uptimeData.reverse() || [];
  const staticDowntimeValue = downtimeData || [];

  for (let i = 0; i < filterDays; i++) {
    const index = dynamicDate.length - i - 1; // Start from the latest date in your data
    const timestamp = new Date(dynamicDate[index]).getTime();
    // const timestamp = currentDate - i * 86400000;
    const uptimeValue = staticUptimeValue[i % staticUptimeValue.length];
    const downtimeValue = staticDowntimeValue[i % staticDowntimeValue.length];
    dataForDays.push({
      timestamp: timestamp,
      uptime: uptimeValue,
      downtime: downtimeValue,
    });
  }

  function formatTimestamp(timestamp) {
    // Create a new Date object using the timestamp
    const date = new Date(timestamp);

    // Get the day and month as numbers
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "short" }); // Use 'short' for abbreviated month names

    // Format the output as 'day - month'
    return `${day}-${month}`;
  }

  let allSeries;
  let allCategories;
  let allWeekRangeArr = [];

  //1st way
  if (dataForDays && dataForDays.length !== 0) {
    const getDataSeries = (data) => {
      if (data.length > 7) {
        const combinedSeries = [];
        for (let i = 0; i < data.length; i += 7) {
          const slice = data.slice(i, i + 7);
          allWeekRangeArr.push({
            startDate: formatTimestamp(slice[0].timestamp),
            endDate: formatTimestamp(slice[slice.length - 1].timestamp),
          });
          const combinedValue = slice.reduce(
            (acc, item) =>
              acc +
              (typeof item.uptime === "number" ? item.uptime.toFixed(2) : 0),
            0
          );
          combinedSeries.push(combinedValue);
        }
        return combinedSeries;
      } else {
        return data.map((item) =>
          typeof item.uptime === "number" ? item.uptime : 0
        );
      }
    };

    // allSeries = getDataSeries(dataForDays)
    allSeries = getDataSeries(dataForDays).reverse();
    allWeekRangeArr = allWeekRangeArr.reverse();

    const getCategories = (data) => {
      if (data.length === 1) {
        let category = [];
        let tempAllSeries = new Array(12).fill(0);
        category = Array.from(
          { length: 12 },
          (_, i) => `${i * 2}-${i * 2 + 2}h`
        );

        chartData.forEach((item) => {
          const time = item?.time?.split(":")[0];
          const hour = parseInt(time, 10);

          if (!isNaN(hour) && hour >= 0 && hour < 24) {
            const intervalIndex = Math.floor(hour / 2);
            tempAllSeries[intervalIndex] += parseFloat(item?.avg_uptime || 0);
          }
        });

        tempAllSeries = tempAllSeries.map((uptime) => uptime / 2);

        allSeries = tempAllSeries;
        return category;
      } else if (data.length > 7) {
        const weeks = Math.ceil(data.length / 7);
        return Array.from({ length: weeks }, (_, i) => `${i + 1}-Week`);
      } else {
        return data.map((item) => {
          const formattedDate = formatTimestamp(item.timestamp);
          return formattedDate !== "Invalid date" ? formattedDate : "N/A"; // Provide fallback for invalid dates
        });
      }
    };

    // allCategories = getCategories(dataForDays);
    allCategories =
      dataForDays.length !== 1
        ? getCategories(dataForDays).reverse()
        : getCategories(dataForDays);
  } else {
    allSeries = [];
    allCategories = [];
  }

  const series = [
    {
      name: "Uptime",
      data: allSeries,
    },
  ];

  const options = {
    chart: {
      id: "area-chart",
    },
    xaxis: {
      categories: allCategories,
      labels: {
        show: true, // Ensure x-axis labels are shown
        style: {
          fontSize: "12px", // Customize font size if needed
          fontFamily: "Arial, sans-serif", // Customize font family if needed
        },
      },
    },
    yaxis: {
      show: true, // Hide the y-axis
    },
    title: {
      text: "Uptime Report",
      align: "left",
    },
    tooltip: {
      enabled: true,
      theme: "light", // Ensure the tooltip uses a light theme
      style: {
        fontSize: "14px", // Increase font size for better readability
        fontFamily: "Arial, sans-serif", // Ensure a readable font
      },
      x: {
        formatter: function (value) {
          return dataForDays.length === 1
            ? `hours: ${allCategories[value - 1]}`
            : dataForDays.length > 7
            ? // ? `Week: ${value} (${allWeekRangeArr[value - 1].startDate}-${
              //     allWeekRangeArr[value - 1].endDate
              //   })`
              `Week: ${allWeekRangeArr.length - value + 1} (${
                allWeekRangeArr[value - 1].endDate
              }-${allWeekRangeArr[value - 1].startDate})`
            : // `Week: ${allWeekRangeArr.length - value + 1}`
              // : `Date: ${formatTimestamp(dataForDays[value - 1].timestamp)}`;
              `Date: ${formatTimestamp(
                dataForDays[dataForDays.length - value].timestamp
              )}`;
        },
      },
      y: {
        formatter: function (value) {
          return `${value}`; // Display the y-axis value on hover
        },
        title: {
          formatter: (seriesName) => seriesName, // Format the title
        },
      },
      marker: {
        show: true, // Show the tooltip marker
      },
      fillSeriesColor: false, // Fill the tooltip background with series color
      border: {
        color: "#333", // Dark border color for better visibility
        width: 1, // Border width
      },
      background: "#000", // Dark background color for the tooltip
      textColor: "#FFF", // White text color for contrast
      followCursor: true,
      intersect: false,
    },
    dataLabels: {
      enabled: false, // Disable data labels on the chart
    },
    grid: {
      show: true, // Hide grid lines
    },
  };

  const getRunTestApiData = async () => {
    try {
      setLoading(true);
      await getSiteStatus(props.assetId);
      fetchUptimeData();
      ToastMessage.Success("your data has been updated.");
    } catch (error) {
      ToastMessage.Error("your data hasn't been updated. Please try again");
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const MetricsDashboardNav = ({ handleClickDays, timeFilter }) => {
    return (
      <Nav className="nav nav-pills nav-justified mb-3 w-100" role="tablist">
        <Nav.Item>
          <button
            onClick={() => handleClickDays("1", "upTime")}
            className={`nav-link text-truncate ${
              timeFilter === "1" ? "active" : ""
            }`}
            title="1d"
            data-bs-toggle="tab"
            role="tab"
          >
            Last 24h
          </button>
        </Nav.Item>
        <Nav.Item>
          <button
            onClick={() => handleClickDays("7", "upTime")}
            className={`nav-link text-truncate ${
              timeFilter === "7" ? "active" : ""
            }`}
            title="7d"
            data-bs-toggle="tab"
            role="tab"
          >
            Last 7d
          </button>
        </Nav.Item>
        <Nav.Item>
          <button
            onClick={() => handleClickDays("30", "upTime")}
            className={`nav-link text-truncate ${
              timeFilter === "30" ? "active" : ""
            }`}
            title="30d"
            data-bs-toggle="tab"
            role="tab"
          >
            Last 30d
          </button>
        </Nav.Item>
      </Nav>
    );
  };

  return (
    <>
      <Card className="main-card">
        <Card.Header>
          <h4 className="card-title">Up Time</h4>
        </Card.Header>
        <>
          <Card.Body className="nv_tab_com">
            {skeleton ? (
              <>
                <Skeleton
                  variant="rectangular"
                  height={40}
                  className="w-100 mb-3"
                />
                <Box className="d-flex align-items-center mb-3">
                  <Skeleton variant="text" width={100} height={30} />
                </Box>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Skeleton variant="text" width={100} height={30} />
                  </Grid>
                  <Grid item xs={6}>
                    <Skeleton variant="text" width={100} height={30} />
                  </Grid>
                </Grid>
                <Skeleton variant="rectangular" height={245} className="mt-3" />
              </>
            ) : typeof uptimeRobot?.id === "number" ||
              props?.assetsData?.isAPIFetched === true ? (
              <>
                <MetricsDashboardNav
                  handleClickDays={handleClickDays}
                  timeFilter={timeFilter}
                />

                <Box id="up-time" role="tabpanel">
                  <Box className="d-flex align-items-center mb-3">
                    <h6 className="mb-0 me-3 fs-16 fw-semibold">Status</h6>
                    {skeleton ? (
                      <Skeleton variant="text" width={100} height={30} />
                    ) : (
                      <h6 className="mb-0">
                        {uptimeRobot?.status === "D" ? (
                          <>
                            <i
                              className="ri-checkbox-blank-circle-fill align-middle me-2"
                              style={{ color: "red" }}
                            ></i>
                            Down
                          </>
                        ) : uptimeRobot?.status === "U" ? (
                          <>
                            <i className="ri-checkbox-blank-circle-fill text-success align-middle me-2"></i>
                            UP
                          </>
                        ) : (
                          <> - </>
                        )}
                      </h6>
                    )}
                  </Box>

                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Box className="flex-grow-1">
                        <h6 className="fs-16 fw-semibold">Average Uptime</h6>
                        {skeleton ? (
                          <Skeleton variant="text" width={100} height={30} />
                        ) : (
                          <h6 className="mb-0">
                            <strong>
                              <i className="ri-checkbox-blank-circle-fill text-success align-middle me-2"></i>
                              {uptimeRobot?.avg_avg_uptime ? (
                                <>{uptimeRobot?.avg_avg_uptime}</>
                              ) : (
                                <> - </>
                              )}
                            </strong>
                          </h6>
                        )}
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box className="flex-grow-1">
                        <h6 className="fs-16 fw-semibold">Avg. resp. time</h6>
                        {skeleton ? (
                          <Skeleton variant="text" width={100} height={30} />
                        ) : (
                          <h6 className="mb-0">
                            <i className="ri-checkbox-blank-circle-fill text-info align-middle me-2"></i>
                            {uptimeRobot?.avg_response_time ? (
                              <>{uptimeRobot?.avg_response_time}</>
                            ) : (
                              <> - </>
                            )}
                          </h6>
                        )}
                      </Box>
                    </Grid>
                  </Grid>

                  <Box className="mt-3">
                    <ReactApexChart
                      options={options}
                      series={series}
                      type="area"
                      height={245}
                    />
                  </Box>
                </Box>
              </>
            ) : (
              // Show message if no data is available after loading
              <div className="d-flex flex-column flex-grow-1 justify-content-center align-items-center mt-5">
                <div className="card-body text-center">
                  <h5 className="text-muted">
                    We are currently generating the website status report.
                    Please hold on for a moment while we gather the necessary
                    data.
                  </h5>
                </div>
              </div>
            )}

            {role && <RunScan loading={loading} onClick={getRunTestApiData} />}
          </Card.Body>
        </>
      </Card>
    </>
  );
};

export default UpTime;
