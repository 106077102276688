import { Box } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "reactstrap";

const CoverLogout = () => {
  const navigate = useNavigate();
  return (
    <React.Fragment>
      <Box className="right-side">
        {/* <lord-icon
          src="https://cdn.lordicon.com/hzomhqxz.json"
          trigger="loop"
          colors="primary:#405189,secondary:#08a88a"
          style={{ width: "180px", height: "180px" }}
        ></lord-icon> */}

        <Box>
          <h5 className="main-title">You are Logged Out</h5>
          <p className="main-subtitle">Thank you for using ControlBase</p>
          <Box className="mt-5">
            <Button
              onClick={() => navigate("/login")}
              color="primary"
              className="submit-btn btn-dark"
            >
              Sign In
            </Button>
          </Box>
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default CoverLogout;
