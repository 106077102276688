import { Box, FormControl, MenuItem, Select, Skeleton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import AnalyticsTable from "../common/AnalyticsTable";
import LiveUsersByCountry from "../common/LiveUsersByCountry";
import {
  DesktopIcon,
  MobileIcon,
  TabletIcon,
} from "../../../../Components/Common/SvgIcons";
import {
  DonutChart,
  SplineAreaChart,
} from "../../../Charts/ApexCharts/LineCharts/LineCharts";
import google from "../../../../assets/images/browsers/chrome.svg";
import safari from "../../../../assets/images/browsers/safari.svg";
import brave from "../../../../assets/images/browsers/brave.svg";
import edge from "../../../../assets/images/browsers/edge.svg";
import samsung from "../../../../assets/images/browsers/samsung.svg";
import firefox from "../../../../assets/images/browsers/firefox.svg";
import android from "../../../../assets/images/browsers/android.svg";
import opera from "../../../../assets/images/browsers/opera.svg";
import ucBrowser from "../../../../assets/images/browsers/uc-browser.svg";
import { fetchAnalyticsData } from "../../../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { getVisitorData } from "../../../../Api/NewDashboard/DashboardAnalyticTab/DvdlTabsReq";

const WebAnalytics = (props) => {
  const dispatch = useDispatch();
  const params = useParams();
  const { id } = params;
  const analyticData = useSelector(
    (state) => state.analyticsData.analyticsData?.reportByBrowser
  );

  // Redux
  const [device, setDevice] = useState({ desktop: 0, tablet: 0, mobile: 0 });
  const [visitorData, setVisitorData] = useState(7);

  // const analyticsData = useSelector(
  //   (state) => state.analyticsData?.analyticsData
  // );

  const { analyticsData, loading, fetch_analytics_data_loading } = useSelector(
    (state) => ({
      analyticsData: state.analyticsData.analyticsData,
      loading: state.analyticsData.loading,

      fetch_analytics_data_loading:
        state.analyticsData.fetch_analytics_data_loading,
    })
  );

  useEffect(() => {
    let data = analyticsData?.usersByDevice;
    const { desktop, mobile } = data || {};
    const DESKTOP = parseInt(desktop);
    const MOBILE = parseInt(mobile);
    const tablet = parseInt(data?.tablet) || 0;

    if (!isNaN(DESKTOP) && !isNaN(MOBILE)) {
      const total = DESKTOP + tablet + MOBILE;
      const DESKTOPPercentage = ((DESKTOP / total) * 100).toFixed(2);
      const tabletPercentage = ((tablet / total) * 100).toFixed(2);
      const MOBILEPercentage = ((MOBILE / total) * 100).toFixed(2);
      // setDevice({
      //   ...device,
      //   desktop: DESKTOPPercentage,
      //   tablet: tabletPercentage,
      //   mobile: MOBILEPercentage,
      // });
      setDevice((prevDevice) => ({
        ...prevDevice,
        desktop: DESKTOPPercentage,
        tablet: tabletPercentage,
        mobile: MOBILEPercentage,
      }));
    }
  }, [analyticsData]);

  const browserReport = analyticData?.averageData
    ? JSON.parse(analyticData?.averageData)
    : [];

  const [month, setMonth] = useState({ value: "365", label: "Last Year" });

  useEffect(() => {
    dispatch(fetchAnalyticsData({ assetID: id, day: "30" }));
  }, [dispatch, id]);

  const handleChangeMonth = (event) => {
    const valueToLabelMap = {
      1: "Today",
      7: "Last Week",
      30: "Last 30 Days",
      180: "Last Six Months",
      365: "Last Year",
    };
    const selectedValue = event.target.value;

    const selectedLabel = valueToLabelMap[selectedValue] || "Select";
    setMonth({ value: selectedValue, label: selectedLabel });
  };

  let browserType = (data) => {
    let newData;

    switch (data?.browser) {
      case "Chrome":
        newData = google;
        break;
      case "Safari":
      case "Safari (in-app)":
        newData = safari;
        break;
      case "Edge":
        newData = edge;
        break;
      case "Samsung Internet":
        newData = samsung;
        break;
      case "Firefox":
      case "Mozilla Compatible Agent":
        newData = firefox;
        break;
      case "Android Webview":
        newData = android;
        break;
      case "Opera":
        newData = opera;
        break;
      case "UC Browser":
        newData = ucBrowser;
        break;
      default:
        newData = brave;
        break;
    }

    return newData;
  };

  const ScrollableBox = styled(Box)({
    height: "440px",
    overflowY: "auto",
  });

  // Calculate total active users
  const totalActiveUsers = browserReport.reduce(
    (sum, item) => sum + JSON.parse(item.activeUsers),
    0
  );

  // Calculate percentage for each browser
  const dataWithPercentage = browserReport.map((item) => ({
    ...item,
    percentage: ((item.activeUsers / totalActiveUsers) * 100).toFixed(2),
  }));

  const renderAnalytics =
    loading || fetch_analytics_data_loading ? (
      // Show skeleton loader when data is loading
      <>
        {Array.from(new Array(4)).map((_, index) => (
          <Box className="analytics-bar" key={index}>
            <Box className="d-flex justify-content-between align-items-center">
              <Box className="d-flex align-items-center">
                <Skeleton variant="circular" width={40} height={40} />
                <Box sx={{ ml: 2 }}>
                  <Skeleton variant="text" width={100} />
                  <Skeleton variant="text" width={60} />
                </Box>
              </Box>
              <Skeleton variant="text" width={30} />
            </Box>
          </Box>
        ))}
      </>
    ) : dataWithPercentage.length > 0 ? (
      // Show actual data when it is available
      dataWithPercentage.map((data, index) => {
        return (
          <Box className="analytics-bar" key={data?.id || index}>
            <Box className="d-flex justify-content-between align-items-center">
              <Box className="d-flex align-items-center">
                <img alt="logo" className="avatar" src={browserType(data)} />
                <Box>
                  <h6 className="title mb-0">{data?.browser}</h6>
                  <p className="subtitle mb-0">{data?.subtitle}</p>
                </Box>
              </Box>
              <span>
                <h6
                  className="value"
                  style={{
                    display: "flex",
                    alignItems: "start",
                    gap: "1px",
                    lineHeight: "0.5",
                  }}
                >
                  {data?.percentage}
                  <span style={{ fontSize: "14px", paddingRight: "15px" }}>
                    %
                  </span>
                </h6>
              </span>
            </Box>
          </Box>
        );
      })
    ) : (
      // Show "No Data Available" message when no data is found
      <Box
        className="no-data-box"
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          padding: "20px",
          margin: "20px",
          marginTop: "180px",
        }}
      >
        <h5>No Data Available</h5>
        <p>Please check back later for updated analytics.</p>
      </Box>
    );

  const getVisitors = async (id, month) => {
    try {
      await getVisitorData(id, parseInt(month.value)).then((data) =>
        setVisitorData(data)
      );
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getVisitors(id, month);
  }, [id, month]);

  return (
    <>
      <Box className="row g-4">
        <Box className="col-md-6 col-xxl-4 order-md-1 order-xxl-1">
          <AnalyticsTable paramsId={props.id} assetsData={props.assetsData} />
        </Box>
        <Box className="col-md-12 col-xxl-8 order-md-3 order-xxl-2">
          <LiveUsersByCountry />
        </Box>
        <Box className="col-md-6 col-xxl-4 order-md-2 order-xxl-3">
          <Card className="p-2">
            <Card.Header>
              <h4 className="card-title">Session by Device</h4>
            </Card.Header>
            {/* <Card.Body>
             
              <DonutChart
                width="380px"
                data={analyticsData && analyticsData.usersByDevice}
              />

              <Box className="d-flex align-content-start justify-content-center gap-3 mt-3">
                {device?.desktop?.toString() !== "0" &&
                  device?.desktop?.toString() !== "0.00" &&
                  device?.desktop?.toString() !== "NaN" && (
                    <Box className="pagespeed-card">
                      <DesktopIcon />
                      <h4 className="time">{device.desktop}%</h4>
                      <p className="title">Desktop</p>
                    </Box>
                  )}
                {device?.tablet?.toString() !== "0" &&
                  device?.tablet?.toString() !== "0.00" &&
                  device?.tablet?.toString() !== "NaN" && (
                    <Box className="pagespeed-card">
                      <TabletIcon />
                      <h4 className="time">{device.tablet}%</h4>
                      <p className="title">Tablet</p>
                    </Box>
                  )}
                {device?.mobile?.toString() !== "0" &&
                  device?.mobile?.toString() !== "0.00" &&
                  device?.mobile?.toString() !== "NaN" && (
                    <Box className="pagespeed-card">
                      <MobileIcon />
                      <h4 className="time">{device.mobile}%</h4>
                      <p className="title">Mobile</p>
                    </Box>
                  )}
              </Box>
            </Card.Body> */}

            <Card.Body>
              {/* Show skeleton when data is loading */}
              {loading || fetch_analytics_data_loading ? (
                <>
                  <Skeleton variant="circular" width={300} height={300} />
                  <Box className="d-flex align-content-start justify-content-center gap-3 mt-3">
                    <Skeleton variant="rectangular" width={100} height={100} />
                    <Skeleton variant="rectangular" width={100} height={100} />
                    <Skeleton variant="rectangular" width={100} height={100} />
                  </Box>
                </>
              ) : analyticsData && analyticsData.usersByDevice ? (
                // Check if all device values (desktop, tablet, mobile) are 0 or NaN
                (device?.desktop === 0 &&
                  device?.tablet === 0 &&
                  device?.mobile === 0) ||
                (isNaN(device?.desktop) &&
                  isNaN(device?.tablet) &&
                  isNaN(device?.mobile)) ? (
                  <>
                    <Box
                      className="no-data-box"
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        textAlign: "center",
                        padding: "20px",
                        margin: "20px",
                        marginTop: "180px",
                      }}
                    >
                      <h5>No Data Available</h5>
                      <p>Please check back later for updated analytics.</p>
                    </Box>
                  </>
                ) : (
                  <>
                    <DonutChart
                      width="380px"
                      data={analyticsData.usersByDevice}
                    />

                    <Box className="d-flex align-content-start justify-content-center gap-3 mt-3">
                      {device?.desktop &&
                        device?.desktop !== 0 &&
                        !isNaN(device.desktop) && (
                          <Box className="pagespeed-card">
                            <DesktopIcon />
                            <h4 className="time">{device.desktop}%</h4>
                            <p className="title">Desktop</p>
                          </Box>
                        )}
                      {device?.tablet &&
                        device?.tablet !== 0 &&
                        !isNaN(device.tablet) && (
                          <Box className="pagespeed-card">
                            <TabletIcon />
                            <h4 className="time">{device.tablet}%</h4>
                            <p className="title">Tablet</p>
                          </Box>
                        )}
                      {device?.mobile &&
                        device?.mobile !== 0 &&
                        !isNaN(device.mobile) && (
                          <Box className="pagespeed-card">
                            <MobileIcon />
                            <h4 className="time">{device.mobile}%</h4>
                            <p className="title">Mobile</p>
                          </Box>
                        )}
                    </Box>
                  </>
                )
              ) : (
                <>
                  <Box
                    className="no-data-box"
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      textAlign: "center",
                      padding: "20px",
                      margin: "20px",
                      marginTop: "180px",
                    }}
                  >
                    <h5>No Data Available</h5>
                    <p>Please check back later for updated analytics.</p>
                  </Box>
                </>
              )}
            </Card.Body>
          </Card>
        </Box>
        <Box className="col-md-6 col-xxl-4 order-md-4 order-xxl-4">
          <Card className="p-2">
            <Card.Header>
              <h4 className="card-title">Search Engines</h4>
            </Card.Header>
            <Card.Body>
              {" "}
              <ScrollableBox>{renderAnalytics}</ScrollableBox>
            </Card.Body>
          </Card>
        </Box>
        <Box className="col-md-6 col-xxl-4 order-md-5 order-xxl-5">
          <Card className="p-2">
            <Card.Header className="d-flex align-items-start justify-content-between flex-wrap">
              <h4 className="card-title">Visitors</h4>
              <FormControl sx={{ minWidth: 100 }}>
                <Select
                  displayEmpty
                  value={month.value}
                  onChange={handleChangeMonth}
                  renderValue={(selected) => {
                    if (!selected) {
                      return <>Select</>;
                    }

                    return month.label;
                  }}
                  sx={{
                    fieldset: {
                      border: "0 !important",
                    },
                    "&.MuiInputBase-root": {
                      background: "#F1F3F6",
                      fontSize: "14px",
                    },
                    "& .MuiSelect-select": {
                      padding: "5px 14px",
                    },
                  }}
                >
                  <MenuItem value="1">Today</MenuItem>
                  <MenuItem value="7">Last Week</MenuItem>
                  <MenuItem value="30">Last 30 Days</MenuItem>
                  <MenuItem value="180">Last Six Months</MenuItem>
                  <MenuItem value="365">Last Year</MenuItem>
                </Select>
              </FormControl>
            </Card.Header>
            <Card.Body>
              <SplineAreaChart data={visitorData} filter={month.value} />
            </Card.Body>
          </Card>
        </Box>
      </Box>
    </>
  );
};
export { WebAnalytics };
