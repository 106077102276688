import { Spinner } from "reactstrap";
import ProfileImage from "../../../Components/Common/ProfileImage";
import { useNavigate } from "react-router-dom";
import { ToastMessage } from "../../../Components/Common/ToastMessage";
import Swal from "sweetalert2";
import Loader from "../../../Components/Common/Loader";
import { socket } from "../../../Components/Common/socket";
import { Box, Skeleton } from "@mui/material";
import { Card } from "react-bootstrap";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { DeleteIcon } from "../../../Components/Common/SvgIcons";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { changeSidebarState, setUserManagement } from "../../../store/actions";
import { useDispatch } from "react-redux";

const TeamCard = (props) => {
  const token = localStorage.getItem("token");
  const { hasPermission } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const deleteUsers = async (id, email, query) => {
    try {
      let url = `${process.env.REACT_APP_BASE_URL}developer-profile/delete-developer-profile/${id}`;
      let reqData = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ email: email }),
      };
      const result = await fetch(url, reqData);
      let response = await result.json();
      if (response.code === 403) {
        navigate("/auth-404-cover");
      }
      if (response.status === "success") {
        props.getInternalUsers(props.page, 1, 5, true, id, query);
        socket.emit("deleteDeveloperProfile");
        ToastMessage.Success(response.message);
      }
      if (response.status === "fail") {
        ToastMessage.Error(response.message);
      }
    } catch (err) {
      return null;
    }
  };

  const handleDeleteAlert = (id, email) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-dark mx-2",
        cancelButton: "btn btn-primary mx-2",
      },
      buttonsStyling: false,
    });

    swalWithBootstrapButtons
      .fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          deleteUsers(id, email);
          swalWithBootstrapButtons.fire(
            "Deleted!",
            "User has been deleted.",
            "success"
          );
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          swalWithBootstrapButtons.fire(
            "Cancelled",
            "User is safe :)",
            "error"
          );
        }
      });
  };

  const handleOpenNewComponent = (id) => {
    dispatch(setUserManagement(true));
    dispatch(changeSidebarState("Usermanagement"));
    navigate("/developer-profile/" + id);
  };

  const handleEditForOpenNewComponent = (id) => {
    navigate(`/developer-profile/${id}?tab=2`);
  };

  return (
    <>
      {props?.teamList?.length > 0 ? (
        props.teamList.map((index, key) => (
          <Box className="col-sm-6 col-xl-4 col-xxxl-3" key={key}>
            <Card className="team-box">
              <Card.Body>
                <Box className="align-items-center d-flex flex-column h-100 justify-content-between team-row position-relative">
                  {hasPermission &&
                  (hasPermission.canList != null ||
                    hasPermission.canModify != null ||
                    hasPermission.canDelete != null) ? (
                    <>
                      <Box className="team-settings">
                        <span
                          id="dropdownMenuLink2"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          className="cursor-pointer"
                        >
                          <MoreVertOutlinedIcon />
                        </span>
                        <ul
                          className="dropdown-menu dropdown-menu-end"
                          aria-labelledby="dropdownMenuLink2"
                        >
                          {hasPermission && hasPermission?.canList != null ? (
                            <>
                              <li
                                onClick={() => handleOpenNewComponent(index.id)}
                              >
                                <span className="dropdown-item">
                                  <VisibilityOutlinedIcon />
                                  View
                                </span>
                              </li>
                            </>
                          ) : null}

                          {hasPermission && hasPermission?.canModify != null ? (
                            <>
                              <li>
                                <span
                                  onClick={() =>
                                    handleEditForOpenNewComponent(index.id)
                                  }
                                  className="dropdown-item"
                                >
                                  <EditOutlinedIcon />
                                  Edit
                                </span>
                              </li>
                            </>
                          ) : null}
                          {hasPermission && hasPermission?.canDelete != null ? (
                            <>
                              <li
                                onClick={() =>
                                  handleDeleteAlert(index.id, index.email)
                                }
                                className="dropdown-item"
                              >
                                <DeleteIcon width="13px" />
                                Delete
                              </li>
                            </>
                          ) : null}
                        </ul>
                      </Box>
                    </>
                  ) : (
                    ""
                  )}

                  <Box className="team-profile-img">
                    {props.isLoading ? (
                      <Skeleton
                        variant="circular"
                        width={80}
                        height={80}
                        sx={{ bgcolor: "grey.700" }}
                      />
                    ) : (
                      <>
                        {index.developers_profile_image != null ? (
                          <Box className="profile-main-box">
                            <Box className="profile-box">
                              <img
                                src={`${process.env.REACT_APP_BASE_URL}${index.developers_profile_image}`}
                                alt="avatar"
                                className="avatar img-fluid"
                              />
                            </Box>
                            <div className="progress-ring"></div>
                          </Box>
                        ) : (
                          // <Avatar className="avatar">{index.user_name}</Avatar>

                          <Box className="profile-main-box">
                            <Box className="profile-box">
                              <ProfileImage username={index.user_name} />
                            </Box>
                            <div className="progress-ring"></div>
                          </Box>
                        )}
                      </>
                    )}
                    <Box className="team-content">
                      {props.isLoading ? (
                        <Skeleton
                          sx={{ bgcolor: "grey.700" }}
                          variant="text"
                          width={130}
                          height={30}
                        />
                      ) : (
                        <>
                          <h5
                            className="name cursor-pointer"
                            onClick={() => handleOpenNewComponent(index.id)}
                          >
                            {index.user_name}
                          </h5>
                          <p className="designation text-muted mb-0">
                            {index.designation}
                          </p>
                         
                        </>
                      )}
                    </Box>
                  </Box>
                  {props.isLoading ? (
                      <Skeleton
                        sx={{ bgcolor: "grey.700" }}
                        variant="text"
                        width='100%'
                        height={30}
                      />
                    ) : (
                      <>
                          <p className="mb-0 project">
                          Projects - <span>{index.projects}</span>
                        </p>
                      </>
                    )}
                </Box>
              </Card.Body>
            </Card>
          </Box>
        ))
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "50vh",
          }}
        >
          <Spinner height={100} width={100} />
        </div>
      )}
      {props.isLoading && <Loader />}
      {!props.isLoading &&
        props.teamList.length > 0 &&
        props.teamList.length < props.totalRows && (
          <Box className="list-group-item ps-0 pe-0 mt-4 text-center h5">
            <button
              type="button"
              onClick={props.handleLoadMore}
              className="btn btn-primary mt-3"
            >
              Load More
            </button>
          </Box>
        )}
    </>
  );
};

export default TeamCard;
