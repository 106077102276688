import { useEffect, useRef, useState } from "react";
import { Card, Col, Image, Row, Spinner } from "react-bootstrap";
import {
  fetchDevelopersRequest,
  fetchFilteredDataRequest,
  fetchSiteStatusRequest,
} from "../../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { formatDateTime } from "../../../Components/Common/FormatDateTIme";
import {
  BasicBarChart,
  UptimeRobotLineChart,
} from "../../Charts/ApexCharts/LineCharts/LineCharts";
import DataTable from "react-data-table-component";
import Flatpickr from "react-flatpickr";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
// import "./ReportDetails.scss";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import {
  Box,
  FormControl,
  MenuItem,
  Modal,
  Paper,
  Typography,
  Select,
  Skeleton,
} from "@mui/material";
import ProfileImage from "../../../Components/Common/ProfileImage";
import { formatTime } from "../../../Components/Common/FormateTime";
import { CustomTableStyle } from "../../../Components/Common/constants";
import {
  cancelMaintenanceTimes,
  getMaintenanceTimes,
  updateMaintenanceTimes,
} from "../../../helpers/Help/CommomApi";
import { ToastMessage } from "../../../Components/Common/ToastMessage";
import common from "../../../Components/Common/common";
import Swal from "sweetalert2";

const UptimeReportDetails = ({ isOnlineAsset = false }) => {
  const dispatch = useDispatch();
  const params = useParams();
  // const navigate = useNavigate();
  const { siteStatus, filteredData } = useSelector(
    (state) => state.uptimeReport
  );

  const developerData = useSelector(
    (state) => state.onlineAssets.developerData
  );

  const assetId =
    siteStatus.data?.latestRecord?.assets_id || "No asset ID available";

  useEffect(() => {
    dispatch(fetchDevelopersRequest(assetId));
  }, [dispatch, assetId]);

  const incidentLogs = filteredData.data && filteredData.data?.incidentLogs;
  const responseTime = filteredData.data && filteredData.data?.responseTime;

  const [open, setOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [responseFilterDate, setResponseFilterDate] = useState("365");

  const handleDateChange = (selectedDates) => {
    setSelectedDate(selectedDates);
    if (selectedDates[1] !== undefined) {
      dispatch(
        fetchFilteredDataRequest(
          params.id,
          responseFilterDate,
          selectedDates[0],
          selectedDates[1]
        )
      );
    }
  };

  useEffect(() => {
    dispatch(fetchFilteredDataRequest(params.id, responseFilterDate));
  }, [dispatch, responseFilterDate, params]);

  useEffect(() => {
    dispatch(fetchSiteStatusRequest(params.id));
    dispatch(fetchFilteredDataRequest(params.id));
  }, [dispatch, params.id]);

  const generalSettingData = useSelector(
    (state) => state.settingsData.settingsData
  );
  const otherSettingsData = generalSettingData?.filter(
    (item) => item.settings_category === "other"
  );
  const dateFormat = otherSettingsData?.find(
    (date) => date.field_name === "DEFAULT_DATE_FORMAT"
  )?.field_value;
  const timeZone = otherSettingsData?.find(
    (time) => time.field_name === "DEFAULT_TIME_FORMAT"
  )?.field_value;
  const timeFormat = otherSettingsData?.find(
    (zone) => zone.field_name === "DEFAULT_TIME_ZONE"
  )?.field_value;

  const time = common.formateTime(timeZone);

  const formattedDateTime = formatDateTime(
    siteStatus.data && siteStatus.data?.latestRecord?.updatedAt,
    dateFormat,
    timeZone,
    timeFormat
  );

  const realTimeDifferenceRef = useRef({
    years: 0,
    months: 0,
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });
  const timeDisplayRef = useRef(null);
  const downtimeRef = useRef(null);

  useEffect(() => {
    const updateRealTimeDifference = () => {
      const dayDifference = siteStatus?.data?.dayDifference;
      if (dayDifference) {
        const difference = common.calculateRealTimeDifference(dayDifference);
        // setRealTimeDifference(difference);
        realTimeDifferenceRef.current = difference;

        // Update the DOM directly using the ref
        if (timeDisplayRef.current) {
          timeDisplayRef.current.textContent =
            common.formatTimeDifference(difference);
        }
        if (downtimeRef.current) {
          downtimeRef.current.textContent =
            common.formatTimeDifference(difference);
        }
      }
    };

    updateRealTimeDifference();

    const intervalId = setInterval(updateRealTimeDifference, 1000);

    return () => clearInterval(intervalId);
  }, [siteStatus]);

  let cardsArray = [
    {
      headerName: "Current status",
      bodyName: siteStatus.loading ? (
        <Skeleton width={150} />
      ) : siteStatus.data && siteStatus?.data?.latestRecord?.status === "D" ? (
        <>
          <i className="ri-checkbox-blank-circle-fill text-danger align-middle me-2"></i>{" "}
          Down{" "}
        </>
      ) : siteStatus.data && siteStatus?.data?.latestRecord?.status === "U" ? (
        <>
          <i
            className="ri-checkbox-blank-circle-fill align-middle me-2"
            style={{ color: "#3bd671" }}
          ></i>{" "}
          Active{" "}
        </>
      ) : (
        <> - </>
      ),
      footerName: (
        <>
          {siteStatus.data && siteStatus?.data?.latestRecord?.status === "D"
            ? `Currently down for `
            : siteStatus.data && siteStatus?.data?.latestRecord?.status === "U"
            ? `Currently up for `
            : "-"}
          <span ref={timeDisplayRef}>
            {common.formatTimeDifference(realTimeDifferenceRef.current) || "-"}
          </span>
        </>
      ),
    },
    {
      headerName: "Last check",
      bodyName: siteStatus.loading ? (
        <Skeleton width={150} />
      ) : (
        <h5 className="mt-0 mb-1 me-2 fs-18 d-inline-block">
          <span className="me-2">
            {formattedDateTime?.timeZone?.formattedDateFinal}
          </span>
          <span>{formattedDateTime?.timeZone?.formattedTimed}</span>
        </h5>
      ),
    },
    {
      headerName: "Last 24 Hours",
      bodyName: filteredData.loading ? (
        <Skeleton width={150} height={50} />
      ) : (
        <>
          {filteredData?.data?.responseTime24Hours?.avg_status === null
            ? 0
            : `${filteredData?.data?.responseTime24Hours?.avg_status} %`}
          <BasicBarChart
            data={filteredData?.data?.past24Hours?.past24Hours}
            lastStatus={siteStatus?.data?.latestRecord?.status}
          />
        </>
      ),
      footerName: (
        <>
          {filteredData.data?.responseTime24Hours?.incidents === null
            ? "0 incidents"
            : `${filteredData.data?.responseTime24Hours?.incidents} incidents`}

          {siteStatus?.data?.latestRecord?.status === "D" ? (
            <>
              {", "}
              {realTimeDifferenceRef.current ? (
                <span ref={downtimeRef}>
                  {" "}
                  {common.formatTimeDifference(realTimeDifferenceRef.current)}
                </span>
              ) : (
                "-"
              )}
              {""} down
            </>
          ) : siteStatus.data &&
            siteStatus?.data?.latestRecord?.status === "U" ? (
            ", 0 min down"
          ) : (
            "-"
          )}
        </>
      ),
    },
  ];

  let daysFilterArray = [
    {
      headerName: "Last 7 Days",
      bodyName: filteredData.loading ? (
        <Skeleton width={100} />
      ) : filteredData.data?.last7Days?.avg_status === null ? (
        0
      ) : (
        `${filteredData.data?.last7Days?.avg_status} %`
      ),
      footerName: filteredData.loading ? (
        <Skeleton width={100} />
      ) : filteredData.data?.last7Days?.incidents === null ? (
        "0 incidents"
      ) : (
        `${filteredData.data?.last7Days?.incidents} incidents`
      ),
    },
    {
      headerName: "Last 30 Days",
      bodyName: filteredData.loading ? (
        <Skeleton width={100} />
      ) : filteredData.data?.last30Days?.avg_status === null ? (
        0
      ) : (
        `${filteredData.data?.last30Days?.avg_status} %`
      ),
      footerName: filteredData.loading ? (
        <Skeleton width={100} />
      ) : filteredData.data?.last30Days?.incidents === null ? (
        "0 incidents"
      ) : (
        `${filteredData.data?.last30Days?.incidents} incidents`
      ),
    },
    {
      headerName: "Last 365 Days",
      bodyName: filteredData.loading ? (
        <Skeleton width={100} />
      ) : filteredData.data?.oneYearAgo?.avg_status === null ? (
        0
      ) : (
        `${filteredData.data?.oneYearAgo?.avg_status} %`
      ),
      footerName: filteredData.loading ? (
        <Skeleton width={100} />
      ) : filteredData.data?.oneYearAgo?.incidents === null ? (
        "0 incidents"
      ) : (
        `${filteredData.data?.oneYearAgo?.incidents} incidents`
      ),
    },
    {
      headerName: (
        <Flatpickr
          placeholder="Select Date Range"
          className="form-control border-0 cursor-pointer py-1 fs-11"
          options={{
            dateFormat: `${dateFormat}`,
            mode: "range",
          }}
          value={selectedDate}
          onChange={handleDateChange}
        />
      ),
      bodyName: filteredData.loading ? (
        <Skeleton width={100} />
      ) : filteredData.data?.customRange?.avg_status === null ? (
        0
      ) : (
        `${filteredData.data?.customRange?.avg_status} %`
      ),
      footerName: filteredData.loading ? (
        <Skeleton width={100} />
      ) : filteredData.data?.customRange?.incidents === null ? (
        "0 incidents"
      ) : (
        `${filteredData.data?.customRange?.incidents} incidents`
      ),
    },
  ];

  const columns = [
    {
      name: <span>Status </span>,
      selector: (row) => (
        <span>
          {row.id === 1 && siteStatus?.data?.latestRecord?.status === "D" ? (
            <>
              {" "}
              <span
                className="fw-bold cursor-pointer"
                style={{ color: "#9e1111" }}
              >
                <i
                  className="ri-checkbox-blank-circle-fill text-red align-middle me-2"
                  style={{ fontSize: "20px" }}
                ></i>
                Ongoing
              </span>
            </>
          ) : (
            <>
              {" "}
              <span
                className="fw-bold cursor-pointer"
                style={{ color: "#26a553" }}
              >
                <CheckCircleOutlineOutlinedIcon style={{ fontSize: "20px" }} />{" "}
                Resolved{" "}
              </span>
            </>
          )}
        </span>
      ),
      grow: 2,
      minWidth: "150px",
    },
    {
      name: <span>Root Cause</span>,
      selector: (row) => (
        <span className="badge badge-soft-danger fs-13">{row?.detail}</span>
      ),
      minWidth: "250px",
    },
    {
      name: <span>started</span>,
      selector: (row) => (
        <span>
          {
            formatDateTime(row.datetime, dateFormat, timeZone, timeFormat)
              .timeZone.formattedDateFinal
          }{" "}
          {
            formatDateTime(row.datetime, dateFormat, timeZone, timeFormat)
              .timeZone.formattedTimed
          }
        </span>
      ),
    },
    {
      name: <span>Duration</span>,
      selector: (row) => <span>{formatTime(row?.duration)}</span>,
    },
  ];

  const responseFilterOptions = [
    {
      label: "Last 24 hours",
      value: "1",
    },
    {
      label: "Last 7 days",
      value: "7",
    },
    {
      label: "Last 6 months",
      value: "180",
    },
    {
      label: "last 1 year",
      value: "365",
    },
  ];

  // const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    textAlign: "center",
    overflowY: "auto",
    maxHeight: "80vh",
  };

  // const [maintenanceTimes, setMaintenanceTimes] = useState(null);
  const [isModify, setIsModify] = useState(false);
  const [isMaintenanceSet, setIsMaintenanceSet] = useState(false);
  const [selectedMaintenance, setSelectedMaintenance] = useState([]);

  const [isFlatpickrVisible, setIsFlatpickrVisible] = useState(false);

  const fetchMaintenanceTimes = async () => {
    try {
      let data = await getMaintenanceTimes(assetId);
      if (data.data) {
        const decryptedData = common.decrypt(data?.data);
        data.data = decryptedData;
        // setMaintenanceTimes(data.data);
        if (data?.data?.start_maintenance && data?.data?.end_maintenance) {
          setSelectedMaintenance([
            new Date(data?.data?.start_maintenance),
            new Date(data?.data?.end_maintenance),
          ]);
          setIsMaintenanceSet(true);
        } else {
          setSelectedMaintenance([]);
          setIsMaintenanceSet(false);
        }
      }
    } catch (err) {
      console.error("Failed to fetch maintenance times", err);
    }
  };

  useEffect(() => {
    fetchMaintenanceTimes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetId]);

  const handleDateChange1 = (dates) => {
    setSelectedMaintenance(dates);
  };

  const handleButtonClick = async () => {
    setIsModify(true);
    if (isFlatpickrVisible) {
      if (selectedMaintenance?.length < 2) {
        ToastMessage.Error("Please select both start and end dates.");
        return;
      }

      const [startMaintenance, endMaintenance] = selectedMaintenance;

      try {
        const data = await updateMaintenanceTimes(
          assetId,
          startMaintenance,
          endMaintenance
        );

        if (data.status === "success") {
          setIsMaintenanceSet(true);
          ToastMessage.Success("Maintenance times updated successfully!");
          setIsModify(false);
          setIsFlatpickrVisible(false); // Hide Flatpickr after submitting
        }
      } catch (err) {
        ToastMessage.Error("Failed to update maintenance times.");
        console.error("Error updating maintenance times:", err);
      }
    } else {
      // Show Flatpickr on the first click
      setIsFlatpickrVisible(true);
    }
  };

  const cancelMaintenance = async () => {
    try {
      const data = await cancelMaintenanceTimes(assetId);

      if (data.code === "200") {
        // setIsMaintenanceSet(false);
        setSelectedMaintenance([]);
        setIsFlatpickrVisible(false);
        ToastMessage.Success("Maintenance schedule cancelled successfully!");
        fetchMaintenanceTimes();
      } else {
        ToastMessage.Error("Failed to cancel maintenance schedule.");
      }
    } catch (error) {
      console.error("Error canceling maintenance", error);
    }
  };

  const handleCancelClick = () => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-dark mx-2",
        cancelButton: "btn btn-primary mx-2",
      },
      buttonsStyling: false,
    });

    swalWithBootstrapButtons
      .fire({
        title: "Are you sure?",
        text: "This will cancel the maintenance schedule.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, cancel it!",
        cancelButtonText: "No, keep it!",
        reverseButtons: true,
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          cancelMaintenance(assetId);
          swalWithBootstrapButtons.fire(
            "Cancelled!",
            "Maintenance has been canceled.",
            "success"
          );
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          swalWithBootstrapButtons.fire(
            "Cancelled",
            "Maintenance schedule is still active.",
            "info"
          );
        }
      });
  };

  const handleCancelReset = () => {
    setIsModify(false);
    setIsFlatpickrVisible(false);
    setSelectedMaintenance([]);
    fetchMaintenanceTimes();
  };

  return (
    <>
      <Box className="uptimeReport-detail">
        <Box className="row">
          <Box className="col-12 col-xl-12 col-xxl-9">
            {!isOnlineAsset && (
              <Row className="mb-4">
                <Col lg={12}>
                  <Card
                    style={{
                      maxWidth: "100%",
                      background: "transparent",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "row",
                      marginBottom: "10px",
                      paddingLeft: "10px",
                    }}
                  >
                    <Box
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "row",
                      }}
                    >
                      <Box className="circle">
                        <ArrowDropUpIcon />
                      </Box>
                      <Box sx={{ paddingLeft: "15px" }}>
                        <Card.Title
                          style={{
                            fontSize: "20px",
                            fontWeight: "bold",
                            margin: "0",
                          }}
                        >
                          {siteStatus.loading ? (
                            <Skeleton width={150} />
                          ) : (
                            siteStatus.data &&
                            siteStatus.data?.latestRecord?.friendly_name
                          )}
                        </Card.Title>
                        {siteStatus.loading ? (
                          <Box style={{ paddingLeft: "10px" }}>
                            <Skeleton width={200} height={20} />
                          </Box>
                        ) : (
                          <a
                            href={
                              siteStatus.data &&
                              siteStatus.data?.latestRecord?.url
                            }
                            target="_blank"
                            rel="noreferrer"
                            style={{
                              fontSize: "16px",
                              color: "#333",
                            }}
                          >
                            {siteStatus.data &&
                              siteStatus.data?.latestRecord?.url}
                          </a>
                        )}
                      </Box>
                    </Box>
                  </Card>
                </Col>
              </Row>
            )}

            <Row className="g-3 mb-4">
              {Array.isArray(cardsArray) &&
                cardsArray.map((item, index) => (
                  <Col xs={12} md={4} key={index}>
                    <Card className="status-card">
                      <Card.Body>
                        <span
                          className="title"
                          style={{
                            marginBottom:
                              index === 0 || index === 1 ? "20px" : "5px",
                          }}
                        >
                          {item.headerName}
                        </span>
                        <span
                          className="value"
                          style={{
                            marginBottom:
                              index === 0 || index === 1 ? "20px" : "5px",
                          }}
                        >
                          {item.bodyName}
                        </span>
                        <span>{item?.footerName}</span>
                      </Card.Body>
                    </Card>
                  </Col>
                ))}
            </Row>

            <Row className="g-4 mb-4">
              <Col lg={12}>
                <Card>
                  <Card.Body>
                    <Row className="g-4">
                      {Array.isArray(daysFilterArray) &&
                        daysFilterArray.map((item, index) => (
                          <Col
                            md={6}
                            xl={3}
                            key={index}
                            className="incident-column"
                          >
                            <Box className="incident-card">
                              <span className="title">{item?.headerName}</span>
                              <span className="value">{item?.bodyName}</span>
                              <span className="subtitle">
                                {item?.footerName}
                              </span>
                            </Box>
                          </Col>
                        ))}
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>

            <Row className="g-4 mb-4">
              <Col lg={12}>
                <Card>
                  <Card.Header className="d-flex flex-wrap align-items-center">
                    <h3 className="card-title">Response Time</h3>
                    <FormControl
                      fullWidth
                      variant="filled"
                      sx={{
                        width: "175px",
                        display: "inline-block",
                        marginLeft: "auto",
                      }}
                    >
                      <Select
                        displayEmpty
                        fullWidth
                        placeholder="select filter duration"
                        value={
                          responseFilterOptions.find(
                            (option) => option.value === responseFilterDate
                          )?.value || ""
                        }
                        onChange={(responseFilterOptions, m) =>
                          setResponseFilterDate(m?.props.value)
                        }
                        sx={{
                          "& .MuiSelect-select": {
                            paddingTop: "9px",
                            background: "transparent",
                            fontSize: "14px",
                          },
                          "&.MuiInputBase-root": {
                            borderRadius: "8px",
                            background: "#F5F7F8",
                            "&::before, &::after": {
                              borderBottom: "0 !important",
                            },
                          },
                        }}
                      >
                        {responseFilterOptions.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Card.Header>
                  <Box style={{ overflow: "hidden" }}>
                    <UptimeRobotLineChart
                      data={responseTime}
                      filterDate={responseFilterDate}
                      timeFormat={timeFormat}
                      timeZone={timeZone}
                      dateFormat={dateFormat}
                    />
                  </Box>
                </Card>
              </Col>
            </Row>

            {incidentLogs?.length > 0 && (
              <Row className="mb-4">
                <Col lg={12}>
                  <Card>
                    <Card.Header>
                      <h3 className="card-title">Latest Incidents</h3>
                    </Card.Header>
                    <Card.Body>
                      {filteredData.loading ? (
                        <Box
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "50vh",
                          }}
                        >
                          <Spinner height={100} width={100} />
                        </Box>
                      ) : (
                        <DataTable
                          columns={columns}
                          data={incidentLogs?.length > 0 ? incidentLogs : []}
                          fixedHeader
                          fixedHeaderScrollHeight="700px"
                          pagination
                          customStyles={CustomTableStyle}
                          className="table-design"
                        />
                      )}
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            )}
          </Box>

          <Box className="col-12 col-md-6 col-lg-5 col-xl-4 col-xxl-3">
            <Row className="g-4">
              <Col xs={12}>
                <Card className="domin-card">
                  <Card.Body>
                    <h5 className="main-title">Domain & SSL</h5>
                    <Box
                      sx={{
                        padding: "0 0 20px 0",
                        borderBottom: "1px solid #f1f1f1",
                      }}
                    >
                      <p className="title">Domain valid until</p>
                      <h4 className="value">
                        {siteStatus.loading ? (
                          <Skeleton width={100} />
                        ) : (
                          <>
                            <InfoOutlinedIcon />
                            {siteStatus?.data?.latestRecord?.domain_expiry_date
                              ? formatDateTime(
                                  siteStatus?.data?.latestRecord
                                    ?.domain_expiry_date,
                                  dateFormat,
                                  timeZone,
                                  timeFormat
                                ).timeZone.formattedDateFinal
                              : "-"}
                          </>
                        )}
                      </h4>
                    </Box>
                    <Box sx={{ padding: "20px 0 0 0" }}>
                      <p className="title">SSL Certificate valid until</p>
                      <h4 className="value">
                        {siteStatus.loading ? (
                          <Skeleton width={100} />
                        ) : (
                          <>
                            <InfoOutlinedIcon />
                            {siteStatus?.data?.latestRecord?.ssl_expiry_date
                              ? formatDateTime(
                                  siteStatus?.data?.latestRecord
                                    ?.ssl_expiry_date,
                                  dateFormat,
                                  timeZone,
                                  timeFormat
                                ).timeZone.formattedDateFinal
                              : "-"}
                          </>
                        )}
                      </h4>
                    </Box>
                  </Card.Body>
                </Card>
              </Col>

              <Col xs={12}>
                <Card className="domin-card">
                  <Card.Body>
                    <h5 className="main-title">Next Maintenance</h5>
                    <div>
                      <p className="title my-4 text-center">
                        {selectedMaintenance?.length === 2
                          ? `From ${
                              formatDateTime(
                                selectedMaintenance?.[0] || "",
                                dateFormat,
                                timeZone,
                                timeFormat
                              ).timeZone.formattedDateFinal
                            } ${
                              formatDateTime(
                                selectedMaintenance?.[0] || "",
                                dateFormat,
                                timeZone,
                                timeFormat
                              ).timeZone.formattedTimed
                            } To ${
                              formatDateTime(
                                selectedMaintenance?.[1] || "",
                                dateFormat,
                                timeZone,
                                timeFormat
                              ).timeZone.formattedDateFinal
                            } ${
                              formatDateTime(
                                selectedMaintenance?.[1] || "",
                                dateFormat,
                                timeZone,
                                timeFormat
                              ).timeZone.formattedTimed
                            }`
                          : "No maintenance Planned"}
                      </p>

                      <>
                        {isFlatpickrVisible && (
                          <Flatpickr
                            placeholder="Select Start Date and End Date Time"
                            className="form-control bg-light border-light border-0"
                            value={selectedMaintenance}
                            options={{
                              enableTime: true,
                              dateFormat: `${dateFormat} ${time}`,
                              mode: "range",
                              minDate: "today",
                            }}
                            onChange={handleDateChange1}
                            style={{
                              borderRadius: "8px",
                              padding: "0.7rem 0.9rem",
                            }}
                          />
                        )}

                        <div className="mt-4">
                          {isMaintenanceSet ? (
                            <div className="d-flex justify-content-between">
                              <button
                                className="btn btn-secondary w-50 me-2"
                                onClick={handleButtonClick}
                              >
                                {isModify ? "Submit" : "Modify"}
                              </button>
                              <button
                                className="btn btn-secondary w-50 ms-2"
                                onClick={
                                  isModify
                                    ? handleCancelReset
                                    : handleCancelClick
                                }
                              >
                                {isModify ? "Cancel" : "Remove"}
                              </button>
                            </div>
                          ) : (
                            <button
                              className="btn btn-primary w-100 mb-2"
                              onClick={handleButtonClick}
                            >
                              {isFlatpickrVisible
                                ? "Set up maintenance"
                                : "Set up maintenance"}
                            </button>
                          )}
                        </div>
                      </>
                    </div>
                  </Card.Body>
                </Card>
              </Col>

              <Col xs={12}>
                <Card className="domin-card">
                  <Card.Body>
                    <h5 className="main-title">To be notified</h5>

                    {developerData?.length > 0 ? (
                      developerData.map((developer, index) => (
                        <Box key={index} className="user-box">
                          {developer.image ? (
                            <Image
                              src={`${process.env.REACT_APP_BASE_URL}${developer.image}`}
                              roundedCircle
                              style={{
                                marginRight: "7px",
                                width: "40px",
                                height: "40px",
                              }}
                            />
                          ) : (
                            <ProfileImage
                              username={developer.name || "Demo User"}
                              height="40px"
                              width="40px"
                              fontSize="20px"
                              style={{ marginRight: "7px" }}
                            />
                          )}
                          <Box>
                            <h4 className="name"> {developer.name}</h4>
                            <p className="designation">
                              {" "}
                              {developer.designation}
                            </p>
                          </Box>
                        </Box>
                      ))
                    ) : (
                      <Typography variant="body1">
                        Not assigned any developer
                      </Typography>
                    )}
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Box>
        </Box>

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography variant="h5" gutterBottom>
              Developer Details
            </Typography>
            <Paper style={{ padding: "10px", marginBottom: "10px" }}>
              {developerData?.length > 0 ? (
                developerData.map((developer, index) => (
                  <Box key={index} className="d-flex align-items-center mb-4">
                    {developer.image ? (
                      <Image
                        src={`${process.env.REACT_APP_BASE_URL}${developer.image}`}
                        roundedCircle
                        style={{
                          marginRight: "7px",
                          width: "50px",
                          height: "50px",
                        }}
                      />
                    ) : (
                      <ProfileImage
                        username={developer.name || "Demo User"}
                        height="50px"
                        width="50px"
                        fontSize="30px"
                        style={{ marginRight: "7px" }}
                      />
                    )}
                    <Box>
                      <Typography variant="h6" gutterBottom>
                        {developer.name}
                      </Typography>
                      <Typography variant="body1">
                        {developer.designation}
                      </Typography>
                    </Box>
                  </Box>
                ))
              ) : (
                <Typography variant="body1">
                  Not assigned any developer
                </Typography>
              )}
            </Paper>
          </Box>
        </Modal>
      </Box>
    </>
  );
};

export default UptimeReportDetails;
