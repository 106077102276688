// export function formatTime(ms) {
//   // Convert milliseconds to seconds
//   let seconds = ms > 1000 ? Math.floor(ms / 1000) : ms / 1000;

//   // Calculate hours, minutes, and remaining seconds
//   let hours = Math.floor(seconds / 3600);
//   seconds %= 3600;
//   let minutes = Math.floor(seconds / 60);
//   seconds %= 60;

//   // Format the result
//   let formattedTime = `${hours}h ${minutes}m ${seconds}s`;

//   return formattedTime;
// }

export function formatTime(ms) {
  // Convert milliseconds to seconds
  let seconds = ms > 1000 ? Math.floor(ms / 1000) : ms / 1000;

  // Calculate hours, minutes, and remaining seconds
  let hours = Math.floor(seconds / 3600);
  seconds %= 3600;
  let minutes = Math.floor(seconds / 60);
  seconds %= 60;

  // Format seconds: show one decimal place only if it's not a whole number
  let formattedSeconds = Number.isInteger(seconds)
    ? seconds.toString()
    : seconds.toFixed(1);

  // Format the result
  let formattedTime = `${hours}h ${minutes}m ${formattedSeconds}s`;

  return formattedTime;
}
