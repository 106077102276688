export const ListHitReportRes = (props) => {
  const { result, setData, responseData } = props;
  if (result.status === "success") {
    setData(responseData);
  } else {
    console.error("Error in getting the data...");
  }
};
export const DashboardListHitReportRes = (props) => {
  const { result, setData, responseData } = props;
  if (result.status === "success") {
    setData(responseData);
  } else {
    console.error("Error in getting the data...");
  }
};
