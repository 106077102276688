export const UptimeReportListReq = (props) => {
  const { startDate, endDate, params, page, limit } = props;
  const url = new URL(
    `${process.env.REACT_APP_BASE_URL}asset/site-status?searchParams=${params}&page=${page}&limit=${limit}`
  );

  let userData = {
    startDate: startDate,
    endDate: endDate,
  };

  let reqData = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    body: JSON.stringify(userData),
  };
  return { url, reqData };
};
