import React from "react";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import { formatDateTime } from "../../Components/Common/FormatDateTIme";
import { useSelector } from "react-redux";
import Loader from "../../Components/Common/Loader";
import { TICKETING_SYSTEM } from "../../Components/Common/ModuleName";
import { CustomTableStyle } from "../../Components/Common/constants.jsx";
import { FormControl, MenuItem, Select } from "@mui/material";
import { DeleteIcon } from "../../Components/Common/SvgIcons.js";
// import { Spinner } from "react-bootstrap";
import { Visibility } from "@mui/icons-material";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

function TicketingSystemTable({
  assetID = null,
  ticketsData = [],
  isDashboardView = false,
  updateTicketStatus = () => {},
  updateTicketPriority = () => {},
  isLoad = false,
  isPriorityLoad = false,
  handleDeleteAlert,
}) {
  const navigate = useNavigate();

  const permissionData = useSelector(
    (state) => state.userDetails.userPermission
  );
  const hasPermission = permissionData?.find(
    (item) => item.module_name === TICKETING_SYSTEM
  );

  const generalSettingData = useSelector(
    (state) => state.settingsData.settingsData
  );

  const otherSettingsData = generalSettingData?.filter(
    (item) => item.settings_category === "other"
  );

  const dateFormat = otherSettingsData?.find(
    (date) => date.field_name === "DEFAULT_DATE_FORMAT"
  )?.field_value;

  const timeZone = otherSettingsData?.find(
    (time) => time.field_name === "DEFAULT_TIME_FORMAT"
  )?.field_value;

  const timeFormat = otherSettingsData?.find(
    (zone) => zone.field_name === "DEFAULT_TIME_ZONE"
  )?.field_value;

  const statusOptions = [
    { label: "Close", value: "Closed" },
    { label: "Active", value: "Active" },
    { label: "Pending", value: "Pending" },
    { label: "Hold", value: "Hold" },
  ];

  const priortyOptions = [
    { label: "High", value: "High" },
    { label: "Medium", value: "Medium" },
    { label: "Low", value: "Low" },
  ];

  const handleOpenTicketDetail = (id) => {
    navigate("/ticketing-system/" + id);
  };

  const getColor = (status) => {
    switch (status) {
      case "closed":
        return "rgb(0,189,157)";
      case "active":
        return "rgb(255,188,10)";
      case "pending":
        return "rgb(240,0,0)";
      case "hold":
        return "rgb(156,114,114)";
      default:
        return "";
    }
  };

  const columns = [
    {
      name: "ID",
      cell: (row) => (
        <h6
          className="mb-0 cursor-pointer"
          onClick={() => handleOpenTicketDetail(row.ticket_id)}
          style={{
            display: "inline-block",
            padding: "0.35em 0.7em",
            lineHeight: "1",
            color: "#fff",
            textAlign: "center",
            whiteSpace: "nowrap",
            verticalAlign: "baseline",
            borderRadius: "30px",
            backgroundColor: getColor(row.status),
          }}
        >
          #{row.ticket_id}
        </h6>
      ),
      grow: 0.5,
    },
    assetID !== null && {
      name: <span>Project</span>,
      selector: (row) => (
        <span>
          {" "}
          <a href={row?.website_url} target="_blank" rel="noopener noreferrer">
            {row.website_url}
          </a>
        </span>
      ),
      // omit: assetID ? true : false,
      style: {
        div: {
          whiteSpace: "normal !important",
        },
      },
    },
    {
      name: <span>Subject</span>,
      selector: (row) => row.title || "-",
      style: {
        div: {
          whiteSpace: "normal !important",
          fontWeight: 700,
        },
      },
      minWidth: "200px",
    },
    {
      name: (
        <span className="text-center">
          Priority
          {/* {hasPermission && hasPermission?.canModify != null ? "Priority" : ""} */}
        </span>
      ),
      cell: (row) =>
        isPriorityLoad !== null && isPriorityLoad === row.id ? (
          <Loader />
        ) : (
          <FormControl
            fullWidth
            variant="filled"
            sx={{
              width: "150px",
              display: "inline-block",
            }}
          >
            <Select
              displayEmpty
              fullWidth
              value={
                priortyOptions.find(
                  (option) =>
                    option.value.toLowerCase() === row?.priority?.toLowerCase()
                )?.value || ""
              }
              onChange={(priorityOption) =>
                updateTicketPriority(
                  row.ticket_id,
                  priorityOption.target.value.toLowerCase(),
                  row.id
                )
              }
              disabled={!hasPermission?.canModify}
              sx={{
                "& .MuiSelect-select": {
                  paddingTop: "4px",
                  paddingBottom: "4px",
                  background: "transparent",
                  fontSize: "14px",
                  "&:focus": {
                    background: "transparent",
                  },
                },
                "&.MuiInputBase-root": {
                  background: "#F5F7F8",
                  borderRadius: "30px",
                  "&::before, &::after": {
                    borderBottom: "0 !important",
                  },
                },
              }}
            >
              {priortyOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ),
      minWidth: "180px",
    },

    {
      name: <span>Created Date</span>,
      selector: (row) => (
        <span>
          {
            formatDateTime(row.createdAt, dateFormat, timeZone, timeFormat)
              .timeZone.formattedDateFinal
          }{" "}
          {
            formatDateTime(row.createdAt, dateFormat, timeZone, timeFormat)
              .timeZone.formattedTimed
          }
        </span>
      ),
      style: {
        div: {
          whiteSpace: "normal !important",
        },
      },
      minWidth: "170px",
    },
    {
      name: <span>Website Category</span>,
      selector: (row) => row.category || "-",
      // style: {
      //   div: {
      //     whiteSpace: "normal !important",
      //     fontWeight: 700,
      //   },
      // },
      minWidth: "200px",
    },
    {
      name: <span>Created By</span>,
      selector: (row) => `${row.user_name || "-"}`,
      style: {
        div: {
          whiteSpace: "normal !important",
        },
      },
      minWidth: "150px",
    },
    {
      name: (
        <span className="text-center">
          {/* {hasPermission && hasPermission?.canModify != null ? "Status" : ""} */}
          Status
        </span>
      ),
      cell: (row) =>
        isLoad !== null && isLoad === row.id ? (
          <Loader />
        ) : (
          <FormControl
            fullWidth
            variant="filled"
            sx={{
              width: "150px",
              display: "inline-block",
            }}
          >
            <Select
              displayEmpty
              fullWidth
              value={
                statusOptions.find(
                  (option) =>
                    option.value.toLowerCase() === row?.status?.toLowerCase()
                )?.value || ""
              }
              onChange={(statusOptions) =>
                updateTicketStatus(
                  row.ticket_id,
                  statusOptions.target.value.toLowerCase(),
                  // statusOptions.value,
                  row.id
                )
              }
              disabled={!hasPermission?.canModify}
              sx={{
                "& .MuiSelect-select": {
                  paddingTop: "4px",
                  paddingBottom: "4px",
                  background: "transparent",
                  fontSize: "14px",
                  "&::focus": {
                    background: "transparent",
                  },
                },
                "&.MuiInputBase-root": {
                  // borderRadius: "8px",
                  background: "#F5F7F8",
                  borderRadius: "30px",
                  "&::before, &::after": {
                    borderBottom: "0 !important",
                  },
                },
              }}
            >
              {statusOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ),
      minWidth: "150px",
    },
    {
      name: <span className="text-center">Actions</span>,
      cell: (row) => (
        <span className="table-action">
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip id={`tooltip-visibility-${row.ticket_id}`}>View</Tooltip>
            }
          >
            <span
              className="cursor-pointer ms-2"
              onClick={() => handleOpenTicketDetail(row.ticket_id)}
            >
              <Visibility />
            </span>
          </OverlayTrigger>

          {hasPermission && hasPermission?.canDelete != null ? (
            <>
              {/* <span
                className="cursor-pointer ms-2"
                onClick={() => handleDeleteAlert(row.ticket_id)}
                style={{ marginLeft: "20px", cursor: "pointer" }}
              >
                <DeleteIcon />
              </span> */}
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id={`tooltip-delete-${row.ticket_id}`}>
                    Delete
                  </Tooltip>
                }
              >
                <span
                  className="cursor-pointer ms-2"
                  onClick={() => handleDeleteAlert(row.ticket_id)}
                  style={{ marginLeft: "20px", cursor: "pointer" }}
                >
                  <DeleteIcon />
                </span>
              </OverlayTrigger>
            </>
          ) : (
            ""
          )}
        </span>
      ),
      width: "85px",
      center: true,
    },
  ].filter((column) => column);

  return (
    <>
      <DataTable
        columns={columns}
        // data={isDashboardView ? ticketsData.slice(0, 5) : ticketsData}
        data={
          isDashboardView
            ? ticketsData
                .filter((ticket) => ticket?.status.toLowerCase() !== "closed") // Exclude closed tickets
                .slice(0, 5) // Get only the first 5 tickets
            : ticketsData
        }
        responsive
        customStyles={CustomTableStyle}
        // pointerOnHover
        // pagination
        pagination={!isDashboardView}
        className="table-design"
      />
    </>
  );
}

export default TicketingSystemTable;
