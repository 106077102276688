import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { CustomTableStyle } from "../../Components/Common/constants";
import axios from "axios";
import { DeleteIcon } from "../../Components/Common/SvgIcons";
import * as Yup from "yup";
import { Card, Offcanvas, OverlayTrigger, Tooltip } from "react-bootstrap";
import {
  Button,
  Col,
  Row,
  Form,
  Label,
  Input,
  FormFeedback,
  Spinner,
} from "reactstrap";
import { Box } from "@mui/material";

import { ToastMessage } from "../../Components/Common/ToastMessage";
import { useFormik } from "formik";
import Swal from "sweetalert2";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";

const AllServicesPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [totalRowCount, setTotalRowCount] = useState(0);
  const [allServicesData, setAllServicesData] = useState([]);
  const [show, setShow] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [id, setId] = useState(null);

  const handleDeleteAlert = (id) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-dark mx-2",
        cancelButton: "btn btn-primary mx-2",
      },
      buttonsStyling: false,
    });

    swalWithBootstrapButtons
      .fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          deleteService(id);
          swalWithBootstrapButtons.fire(
            "Deleted!",
            "Service has been deleted.",
            "success"
          );
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          swalWithBootstrapButtons.fire(
            "Cancelled",
            "Service is safe :)",
            "error"
          );
        }
      });
  };

  //Fetch the services
  const getAllServices = async (page = 1, perPage = 10) => {
    try {
      setIsLoading(true);
      const { status, data } = await axios.get(
        `/package/get-services?page=${page}&limit=${perPage}`
      );
      if (status === "success") {
        setTotalRowCount(data?.count);
        setAllServicesData(data?.rows);
      }
    } catch (error) {
      ToastMessage.Error("Something went wrong...!");
      console.error(
        "Something went wrong while getting the services -> ",
        error.message
      );
    } finally {
      setIsLoading(false);
    }
  };

  //Create the services
  const createOrUpdateService = async (value) => {
    const { serviceName } = value;
    try {
      const { data, status } = await axios.post(
        "/package/create-update-services",
        {
          serviceName,
          id,
        }
      );
      if (status === "success") {
        setShow(false);
        ToastMessage.Success(data?.message);
        validation.resetForm();
        getAllServices(currentPage, perPage);
      }
    } catch (error) {
      console.error(
        "Something went wrong while creating the service -->",
        error.message
      );
    }
  };

  //Delete the services
  const deleteService = async (id) => {
    try {
      const { data, status } = await axios.patch(
        `/package/delete-service/${id}`
      );
      if (status === "success") {
        ToastMessage.Success(data?.message);
        //Below if block is used if the current page records got empty by deleting so fetch the prev. records if any.
        if (allServicesData.length === 1 && currentPage !== 1) {
          setCurrentPage((prev) => prev - 1);
        } else {
          getAllServices(currentPage, perPage);
        }
      }
    } catch (error) {
      console.error("Error while deleting the service -->", error.message);
    }
  };

  const handleServiceEdit = async (row) => {
    const { service_id, service_name } = row;
    setShow(true);
    setId(service_id);
    setIsEdit(true);
    validation.setFieldValue("serviceName", service_name);
  };

  useEffect(() => {
    getAllServices(currentPage, perPage);
  }, [currentPage, perPage]);

  //   const columns = [
  //     {
  //       name: "Service Name",
  //       selector: (row) => (
  //         <span>
  //           <span>{row?.service_name}</span>
  //         </span>
  //       ),
  //     },
  //     {
  //       name: "Action",
  //       cell: (row) => (
  //         <span className="table-action">
  //           <OverlayTrigger
  //             placement="top"
  //             overlay={
  //               <Tooltip id={`tooltip-delete-${row.service_id}`}>Delete</Tooltip>
  //             }
  //           >
  //             <span
  //               className="cursor-pointer ms-2"
  //               onClick={() => handleDeleteAlert(row.service_id)}
  //               style={{ marginLeft: "20px", cursor: "pointer" }}
  //             >
  //               <DeleteIcon />
  //             </span>
  //           </OverlayTrigger>
  //         </span>
  //       ),
  //       //   minWidth: "150px",
  //     },
  //     {
  //       name: "Edit",
  //       cell: (row) => {
  //         <span className="table-action">
  //           <EditOutlinedIcon />
  //         </span>;
  //       },
  //     },
  //   ];

  const columns = [
    {
      name: "Service Name",
      selector: (row) => (
        <span>
          <span>{row?.service_name}</span>
        </span>
      ),
      minWidth: "900px",
    },
    {
      name: "Action",
      center: true,
      cell: (row) => (
        <span className="table-action">
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip id={`tooltip-edit-${row.service_id}`}>Edit</Tooltip>
            }
          >
            <span
              className="cursor-pointer ms-2"
              onClick={() => handleServiceEdit(row)}
              style={{ marginLeft: "20px", cursor: "pointer" }}
            >
              <EditOutlinedIcon />
            </span>
          </OverlayTrigger>
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip id={`tooltip-delete-${row.service_id}`}>Delete</Tooltip>
            }
          >
            <span
              className="cursor-pointer ms-2"
              onClick={() => handleDeleteAlert(row.service_id)}
              style={{ marginLeft: "20px", cursor: "pointer" }}
            >
              <DeleteIcon />
            </span>
          </OverlayTrigger>
        </span>
      ),
    },
  ];

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handlePerRowsChange = (perPage) => {
    setCurrentPage(1);
    setPerPage(perPage);
  };

  const handleShow = () => {
    setShow(true);
  };

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      serviceName: null,
    },

    validationSchema: Yup.object({
      serviceName: Yup.string().required("Please Enter the Service name."),
    }),

    onSubmit: (value) => createOrUpdateService(value),
  });

  const handleClose = () => {
    setShow(false);
    setIsEdit(false);
    validation.resetForm();
  };

  return (
    <React.Fragment>
      <Card className="table-main-card">
        <Box
          className="table-card-header"
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <button
            type="button"
            className="btn btn-primary view-btn"
            onClick={handleShow}
          >
            Add Service
          </button>
        </Box>

        {isLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "50vh",
            }}
          >
            <Spinner height={100} width={100} />
          </div>
        ) : (
          <Card.Body>
            <DataTable
              columns={columns}
              data={allServicesData}
              progressPending={isLoading}
              pagination
              paginationServer
              paginationDefaultPage={currentPage}
              paginationTotalRows={totalRowCount}
              onChangePage={handlePageChange}
              onChangeRowsPerPage={handlePerRowsChange}
              customStyles={CustomTableStyle}
              paginationRowsPerPageOptions={[10, 15, 20]}
              className="table-design"
            />
          </Card.Body>
        )}
      </Card>

      <Offcanvas
        style={{ width: "31rem" }}
        show={show}
        onHide={handleClose}
        placement="end"
        backdrop="static"
      >
        <Offcanvas.Header>
          <Offcanvas.Title>
            {isEdit ? "Edit Service" : "Add Service"}
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Form onSubmit={validation.handleSubmit}>
            <Row>
              <Col lg={12} className="mb-4">
                <Label> {isEdit ? "Edit Service *" : "Add Service *"} </Label>
                <Input
                  name="serviceName"
                  id="outlined-size-small"
                  placeholder="Enter Service Name"
                  value={validation.values.serviceName || ""}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  invalid={
                    validation.errors.serviceName &&
                    validation.touched.serviceName
                      ? true
                      : false
                  }
                />
                {validation.errors.serviceName &&
                  validation.touched.serviceName && (
                    <FormFeedback type="d-block" style={{ color: "red" }}>
                      {validation.errors.serviceName}
                    </FormFeedback>
                  )}
              </Col>
              <Col lg={12} style={{ alignItems: "center" }}>
                <Button
                  className="btn btn-dark"
                  type="submit"
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <Spinner size="sm" variant="light" />
                  ) : isEdit ? (
                    "Update"
                  ) : (
                    "Submit"
                  )}
                </Button>
                <Button className="btn btn-primary ms-2" onClick={handleClose}>
                  Cancel
                </Button>
              </Col>
            </Row>
          </Form>
        </Offcanvas.Body>
      </Offcanvas>
    </React.Fragment>
  );
};
export default AllServicesPage;
