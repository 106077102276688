import { useEffect, useState } from "react";
import {
  getPackagePurchaseHistory,
  updatePurchaseStatus,
} from "../../helpers/Help/CommomApi";
import { Button, Card } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { Box, FormControl, MenuItem, Select } from "@mui/material";
import { CustomTableStyle } from "../../Components/Common/constants";
import { useNavigate } from "react-router-dom";
import { Spinner } from "reactstrap";
import { DropdownStyle } from "../NewDashboard/constant";
import LoopIcon from "@mui/icons-material/Loop";
import { ToastMessage } from "../../Components/Common/ToastMessage";
import common from "../../Components/Common/common";
import { useSelector } from "react-redux";
import { formatDateTime } from "../../Components/Common/FormatDateTIme";

const PurchaseHistory = () => {
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [query, setQuery] = useState("");
  const [statusFilter, setStatusFilter] = useState("All");

  const generalSettingData = useSelector(
    (state) => state.settingsData.settingsData
  );
  const otherSettingsData = generalSettingData?.filter(
    (item) => item.settings_category === "other"
  );
  const dateFormat = otherSettingsData?.find(
    (date) => date.field_name === "DEFAULT_DATE_FORMAT"
  )?.field_value;
  const timeZone = otherSettingsData?.find(
    (time) => time.field_name === "DEFAULT_TIME_FORMAT"
  )?.field_value;
  const timeFormat = otherSettingsData?.find(
    (zone) => zone.field_name === "DEFAULT_TIME_ZONE"
  )?.field_value;

  const fetchPackageData = async () => {
    try {
      const response = await getPackagePurchaseHistory(query);
      const responseData = common.decrypt(response.data);
      const filteredData =
        statusFilter === "All"
          ? responseData
          : responseData?.filter((pkg) =>
              statusFilter === "Active"
                ? pkg.package_status === "Active"
                : pkg.package_status === "Pending"
            );

      setData(filteredData);
    } catch (error) {
      console.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchPackageData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, statusFilter]);

  const handleUpdateStatus = async (id, status) => {
    try {
      const response = await updatePurchaseStatus(id, status);
      if (response.status === "success") {
        ToastMessage.Success("Package status updated successfully.");
        fetchPackageData();
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  const handleReset = () => {
    setStatusFilter("All");
    setQuery("");
  };

  const handleSelectPlan = (plan) => {
    navigate("/my-package", {
      state: {
        selectedPlan: plan,
      },
    });
  };

  const statusOptions = [
    { label: "Active", value: "Active" },
    { label: "Pending", value: "Pending" },
    { label: "Expired", value: "Expired" },
    // { label: "Close", value: "Closed" },
  ];

  const columns = [
    {
      name: "User Name",
      selector: (row) => (row.user ? row.user.user_name : "N/A"), // Display user name if available
      sortable: true,
      grow: 1.5,
    },
    {
      name: "User Email",
      selector: (row) => (row.user ? row.user.email : "N/A"), // Display user email if available
      sortable: true,
      grow: 1.5,
    },
    {
      name: "Package Name",
      selector: (row) => (
        <div
          style={{ cursor: "pointer" }}
          onClick={() => handleSelectPlan(row.Package)}
        >
          {row.Package ? row.Package.title : "-"}{" "}
        </div>
      ),
      sortable: true,
      grow: 1.5,
    },
    {
      name: "Package Price",
      cell: (row) => (
        <>
          {row.package_price ? (
            <>
              <span style={{ marginRight: "8px" }}>
                ${row.package_price || "-"}
              </span>
              {row.billing_cycle && (
                <span style={{ fontStyle: "italic", color: "#777" }}>
                  ({row.billing_cycle})
                </span>
              )}
            </>
          ) : (
            "-"
          )}{" "}
        </>
      ),
      sortable: true,
      grow: 1.5,
    },
    {
      name: <span>Expire Date</span>,
      selector: (row) => (
        <span>
          {
            formatDateTime(
              row.package_expired_date,
              dateFormat,
              timeZone,
              timeFormat
            ).timeZone.formattedDateFinal
          }{" "}
          {
            formatDateTime(
              row.package_expired_date,
              dateFormat,
              timeZone,
              timeFormat
            ).timeZone.formattedTimed
          }
        </span>
      ),
    },
    {
      name: <span className="text-center">Status</span>,
      cell: (row) => (
        <FormControl
          fullWidth
          variant="filled"
          sx={{
            width: "150px",
            display: "inline-block",
          }}
        >
          <Select
            displayEmpty
            fullWidth
            value={
              statusOptions.find(
                (option) => option.value === row?.package_status
              )?.value || ""
            }
            onChange={async (event) => {
              const selectedStatus = event.target.value;
              await handleUpdateStatus(row.id, selectedStatus);
            }}
            sx={{
              "& .MuiSelect-select": {
                paddingTop: "4px",
                paddingBottom: "4px",
                background: "transparent",
                fontSize: "14px",
                "&::focus": {
                  background: "transparent",
                },
              },
              "&.MuiInputBase-root": {
                background: "#F5F7F8",
                borderRadius: "30px",
                "&::before, &::after": {
                  borderBottom: "0 !important",
                },
              },
            }}
            disabled={row?.package_status === "Expired"}
          >
            {statusOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ),
      minWidth: "150px",
    },
  ];

  return (
    <>
      <Card className="table-main-card">
        <Box className="table-card-header">
          <Box className="left-side">
            <Box className="search-box">
              <input
                type="text"
                className="form-control"
                placeholder="Search package name"
                value={query}
                onChange={(e) => setQuery(e.target.value)}
              />
              <i className="ri-search-line search-icon"></i>
            </Box>
            <FormControl
              fullWidth
              variant="filled"
              sx={{
                width: "150px",
                minWidth: "150px",
              }}
            >
              <Select
                value={statusFilter}
                sx={DropdownStyle}
                onChange={(e) => setStatusFilter(e.target.value)}
                label="Status"
              >
                <MenuItem value="All">All</MenuItem>
                <MenuItem value="Active">Active</MenuItem>
                <MenuItem value="Pending">Pending</MenuItem>
              </Select>
            </FormControl>
            <Button className="btn btn-primary reset-btn" onClick={handleReset}>
              <LoopIcon />
            </Button>
          </Box>

          {/* <Button
            className="btn btn-primary view-btn"
            style={{ minWidth: "162px" }}
            onClick={() => navigate("/package/add")}
          >
            <i className="ri-add-line align-bottom me-1"></i>
            Create Package
          </Button> */}
        </Box>
        <Card.Body>
          {data?.length <= 0 && isLoading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "50vh",
              }}
            >
              <Spinner height={100} width={100} />
            </div>
          ) : (
            <DataTable
              fixedHeight="500px"
              pagination
              columns={columns}
              data={data}
              customStyles={CustomTableStyle}
              className="table-design"
            />
          )}
        </Card.Body>
      </Card>
    </>
  );
};

export default PurchaseHistory;
