import React, { useEffect, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import { CustomTableStyle } from "../../Components/Common/constants";
import { Alert } from "@mui/material";
import axios from "axios";
import { useSelector } from "react-redux";
import { formatDateTime } from "../../Components/Common/FormatDateTIme";
import ViewEmailLogModal from "./ViewEmailLogModal";
import VisibilityIcon from "@mui/icons-material/Visibility";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import LoopIcon from "@mui/icons-material/Loop";
import Flatpickr from "react-flatpickr";
import moment from "moment";
import { Box, FormControl, MenuItem, Select } from "@mui/material";
import { Button, Spinner } from "reactstrap";
import { Card } from "react-bootstrap";
import common from "../../Components/Common/common";
import { DropdownStyle, MenuHeight } from "../NewDashboard/constant";

export const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);
    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);
  return debouncedValue;
};

const EmailLogs = () => {
  const token = localStorage.getItem("token");

  const [emailLogData, setEmailLogData] = useState([]);
  const [totalRowCount, setTotalRowCount] = useState("");
  const [loading, setLoading] = useState(true);
  const [perPage, setPerPage] = useState(10);
  const [modalShow, setModalShow] = useState(false);
  const [currentRowSelected, setCurrentRowSelected] = useState("");
  const [previewTempletFlag, setPreviewTempleFlag] = useState(false);
  const [selectedDate, setSelectedDate] = useState([]);
  const [searchedUserEmail, setSearchedUserEmail] = useState("");
  const [selectedStatusOption, setSelectedStatusOption] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [dataWithDividers, setDataWithDividers] = useState([]);
  const flatpickrRef = useRef(null);

  const generalSettingData = useSelector(
    (state) => state.settingsData.settingsData
  );
  const otherSettingsData = generalSettingData?.filter(
    (item) => item.settings_category === "other"
  );

  const dateFormat = otherSettingsData?.find(
    (date) => date.field_name === "DEFAULT_DATE_FORMAT"
  )?.field_value;

  const timeZone = otherSettingsData?.find(
    (time) => time.field_name === "DEFAULT_TIME_FORMAT"
  )?.field_value;

  const timeFormat = otherSettingsData?.find(
    (zone) => zone.field_name === "DEFAULT_TIME_ZONE"
  )?.field_value;

  const handleDateChange = (selectedDates) => {
    if (selectedDates[0] !== undefined) {
      setCurrentPage(1);
      setSelectedDate(selectedDates);
    }
  };

  const debouncedQuerySearchUser = useDebounce(searchedUserEmail, 500);

  const statusOptions = [
    { label: "SUCCESS", value: "SUCCESS" },
    { label: "FAILURE", value: "FAILURE" },
  ];

  let [startDate, endDate] = selectedDate;
  if (startDate) {
    startDate = moment(startDate).format("YYYY-MM-DD");
  }
  if (endDate) {
    endDate = moment(endDate).format("YYYY-MM-DD");
  }

  const processApiData = (apiData) => {
    const processedData = [];
    let previousDate = null;

    apiData.forEach((item) => {
      // Convert 'createdAt' to a date string (e.g., "Tue Oct 15 2024")
      const currentDate = new Date(item.createdAt).toDateString();

      // If the date is different from the previous date, insert a date divider
      if (currentDate !== previousDate) {
        processedData.push({
          type: "date-divider",
          date: currentDate, // Insert the formatted date
        });
        previousDate = currentDate; // Update previousDate to the current date
      }

      // Add the actual data row
      processedData.push(item);
    });

    return processedData;
  };

  const getAllEmailLogsData = async (
    page,
    perPageSize,
    startDate,
    endDate,
    searchedUserEmail,
    selectedStatusOption
  ) => {
    try {
      // setLoading(true);
      let params = {
        page,
        limit: perPageSize,
      };
      if (startDate) params.startDate = startDate;
      if (endDate) params.endDate = endDate;
      if (searchedUserEmail) params.searchedUserEmail = searchedUserEmail;
      if (selectedStatusOption) params.status = selectedStatusOption;

      let response = await axios.get(`/emailLogs/getEmailLogs`, {
        params,
        headers: { Authorization: `Bearer ${token}` },
      });

      const decryptedData = common.decrypt(response.data);
      response.data = decryptedData;

      if (response.status === "success") {
        setTotalRowCount(response.data?.count);
        const finalResponse = processApiData(response?.data?.rows);
        setCurrentPage(page);
        setEmailLogData(response.data?.rows);
        setDataWithDividers(finalResponse);
      }
    } catch (error) {
      console.error("Errror -->", error.message);
    } finally {
      setLoading(false);
    }
  };

  // Custom styles for the date divider row
  const conditionalRowStyles = [
    {
      when: (row) => row.type === "date-divider",
      style: {
        backgroundColor: "#f0f0f0", // Light grey background
        fontWeight: "bold", // Bold text for the date divider
        textAlign: "center", // Center the content
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "50px", // Adjust the height to center vertically
        width: "100%",
      },
      classNames: ["demo"],
    },
  ];

  // const customRowRender = (row) => {
  //   if (row.type === "date-divider") {
  //     return (
  //       <div style={{ width: "100%", textAlign: "center", fontWeight: "bold" }}>
  //         {row.date}
  //       </div>
  //     );
  //   }
  //   return null; // Return null for normal rows, so they are handled by the table
  // };

  const handleReset = () => {
    if (flatpickrRef.current) {
      flatpickrRef.current.flatpickr.clear();
    }
    setSelectedDate([]);
    setCurrentPage(1);
    setSearchedUserEmail("");
    setSelectedStatusOption("");
  };

  useEffect(() => {
    // setCurrentPage(1);
    if (debouncedQuerySearchUser === searchedUserEmail) {
      getAllEmailLogsData(
        currentPage,
        perPage,
        startDate,
        endDate,
        debouncedQuerySearchUser,
        selectedStatusOption
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    perPage,
    startDate,
    endDate,
    debouncedQuerySearchUser,
    selectedStatusOption,
    currentPage,
  ]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
    // getAllEmailLogsData(
    //   page,
    //   perPage,
    //   startDate,
    //   endDate,
    //   debouncedQuerySearchUser,
    //   selectedStatusOption
    // );
  };

  const handlePerRowsChange = async (newPerPage) => {
    setCurrentPage(1);
    setPerPage(newPerPage);
    // getAllEmailLogsData(1, newPerPage);
  };

  const capitalEachLetterOfWord = (str) => {
    let splitStr = str.toLowerCase().split(" ");
    for (let i = 0; i < splitStr.length; i++) {
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(" ");
  };

  // const columns = [
  //   // {
  //   //   name: "Sr. No",
  //   //   selector: (row, index) => index + 1,
  //   //   width: "100px",
  //   // },
  //   {
  //     name: "Module",
  //     selector: (row) =>
  //       !Object.hasOwn(row, "type") &&
  //       capitalEachLetterOfWord(row.module_name.replaceAll("-", " ")),
  //   },
  //   {
  //     name: "Email Send To",
  //     selector: (row) => !Object.hasOwn(row, "type") && row.toEmail,
  //   },
  //   {
  //     name: "Email preview",
  //     cell: (row) =>
  //       !Object.hasOwn(row, "type") && (
  //         <div
  //           onClick={() => {
  //             setPreviewTempleFlag(true);
  //             setCurrentRowSelected(row);
  //             setModalShow(true);
  //           }}
  //         >
  //           {/* <InfoIcon style={{ cursor: "pointer" }} /> */}
  //           <VisibilityIcon style={{ cursor: "pointer" }} />
  //         </div>
  //       ),
  //     width: "120px",
  //     center: true,
  //   },
  //   {
  //     name: "Attachment Preview",
  //     cell: (row) =>
  //       !Object.hasOwn(row, "type") &&
  //       (row.attachments !== null ? (
  //         <div
  //           onClick={() => {
  //             setCurrentRowSelected(row);
  //             setModalShow(true);
  //           }}
  //         >
  //           <AttachFileIcon style={{ cursor: "pointer" }} />
  //         </div>
  //       ) : (
  //         <div>-</div>
  //       )),
  //     width: "170px",
  //     center: true,
  //   },
  //   {
  //     name: "Status",
  //     selector: (row) =>
  //       !Object.hasOwn(row, "type") &&
  //       (row.status === "SUCCESS" ? (
  //         <Alert severity="success">SUCCESS</Alert>
  //       ) : (
  //         <Alert severity="error">"FAILURE"</Alert>
  //       )),
  //     center: true,
  //   },
  //   {
  //     name: "Email Send At",
  //     selector: (row) =>
  //       !Object.hasOwn(row, "type") &&
  //       formatDateTime(row.createdAt, dateFormat, timeZone, timeFormat).timeZone
  //         .formattedDateFinal +
  //         " - " +
  //         formatDateTime(row.createdAt, dateFormat, timeZone, timeFormat)
  //           .timeZone.formattedTimed,
  //   },
  //   {
  //     name: "Date Divider",
  //     cell: (row) =>
  //       row.type === "date-divider" ? (
  //         <div style={{ width: "100%", textAlign: "center" }}>{row.date}</div>
  //       ) : null, // Render the date if it's a divider row
  //     omit: true, // Prevent this column from showing up as a regular column header
  //   },
  // ];

  const columns = [
    // Regular columns for your actual data rows
    {
      name: "Module",
      selector: (row) =>
        !Object.hasOwn(row, "type") &&
        capitalEachLetterOfWord(row.module_name.replaceAll("-", " ")),
    },
    {
      name: "Email Send To",
      selector: (row) => !Object.hasOwn(row, "type") && row.toEmail,
    },
    {
      name: "Email preview",
      cell: (row) =>
        !Object.hasOwn(row, "type") && (
          <div
            onClick={() => {
              setPreviewTempleFlag(true);
              setCurrentRowSelected(row);
              setModalShow(true);
            }}
          >
            <VisibilityIcon style={{ cursor: "pointer" }} />
          </div>
        ),
      width: "120px",
      center: true,
    },
    {
      name: "Attachment Preview",
      cell: (row) =>
        !Object.hasOwn(row, "type") &&
        (row.attachments !== null ? (
          <div
            onClick={() => {
              setCurrentRowSelected(row);
              setModalShow(true);
            }}
          >
            <AttachFileIcon style={{ cursor: "pointer" }} />
          </div>
        ) : (
          <div>-</div>
        )),
      width: "170px",
      center: true,
    },
    {
      name: "Status",
      selector: (row) =>
        !Object.hasOwn(row, "type") &&
        (row.status === "SUCCESS" ? (
          <Alert severity="success">SUCCESS</Alert>
        ) : (
          <Alert severity="error">FAILURE</Alert>
        )),
      center: true,
    },
    {
      name: "Email Send At",
      selector: (row) =>
        !Object.hasOwn(row, "type") &&
        formatDateTime(row.createdAt, dateFormat, timeZone, timeFormat).timeZone
          .formattedDateFinal +
          " - " +
          formatDateTime(row.createdAt, dateFormat, timeZone, timeFormat)
            .timeZone.formattedTimed,
    },
    // Date Divider Column
    {
      name: "", // No header for this column
      cell: (row) =>
        row.type === "date-divider" ? (
          <div
            style={{
              width: "100%",
              textAlign: "center",
              fontWeight: "bold",
              backgroundColor: "#f0f0f0", // Optional background color
              padding: "10px", // Padding to ensure proper spacing
            }}
          >
            {row.date}
          </div>
        ) : null,
      omit: true, // Prevent this from rendering as a header in the table
    },
  ];

  return (
    <div>
      <Card className="table-main-card">
        <Box className="table-card-header">
          <Box className="left-side">
            {/* Search box */}
            <Box className="search-box">
              <input
                type="text"
                value={searchedUserEmail}
                className="form-control"
                placeholder="Email send to..."
                onChange={(e) => {
                  setCurrentPage(1);
                  setSearchedUserEmail(e.target.value);
                }}
              />
              <i className="ri-search-line search-icon"></i>
            </Box>
            {/* Select tag for status */}
            <FormControl
              fullWidth
              variant="filled"
              sx={{
                width: "150px",
                minWidth: "150px",
              }}
            >
              <Select
                displayEmpty
                fullWidth
                value={selectedStatusOption}
                onChange={(e) => {
                  setCurrentPage(1);
                  setSelectedStatusOption(e.target.value);
                }}
                sx={DropdownStyle}
                MenuProps={MenuHeight}
              >
                <MenuItem disabled value="">
                  Select Action
                </MenuItem>
                {statusOptions.map((option) => (
                  <MenuItem
                    key={option.value}
                    value={option.value}
                    sx={{ whiteSpace: "break-spaces" }}
                  >
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {/* Select date for email logs */}
            <Box className="dateinput">
              <Flatpickr
                placeholder="Select Date"
                ref={flatpickrRef}
                className="form-control"
                options={{
                  dateFormat: `${dateFormat}`,
                  mode: "range",
                }}
                value={selectedDate}
                onChange={handleDateChange}
              />
            </Box>
            {/* Clear filter for email logs */}
            <Button className="btn btn-primary reset-btn" onClick={handleReset}>
              <LoopIcon />
            </Button>
          </Box>
        </Box>
        <Card.Body>
          {loading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "50vh",
              }}
            >
              <Spinner animation="border" />
            </div>
          ) : (
            // <DataTable
            //   // title="Email-Logs"
            //   columns={columns}
            //   // data={emailLogData}
            //   data={dataWithDividers}
            //   progressPending={loading}
            //   pagination
            //   paginationServer
            //   paginationDefaultPage={currentPage}
            //   paginationTotalRows={totalRowCount}
            //   conditionalRowStyles={conditionalRowStyles}
            //   onChangePage={handlePageChange}
            //   noHeader={true}
            //   onChangeRowsPerPage={handlePerRowsChange}
            //   customStyles={CustomTableStyle}
            //   paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
            //   className="table-design"
            // />
            <DataTable
              columns={columns}
              data={emailLogData || dataWithDividers} // Your processed data with date dividers
              progressPending={loading}
              pagination
              paginationServer
              paginationDefaultPage={currentPage}
              paginationTotalRows={totalRowCount}
              conditionalRowStyles={conditionalRowStyles} // Style for date dividers
              onChangePage={handlePageChange}
              noHeader={true}
              onChangeRowsPerPage={handlePerRowsChange}
              customStyles={CustomTableStyle}
              paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
              className="table-design"
            />
          )}
        </Card.Body>

        {modalShow && (
          <ViewEmailLogModal
            show={modalShow}
            onHide={() => {
              setModalShow(false);
              setPreviewTempleFlag(false);
            }}
            isTemplet={previewTempletFlag}
            currentRow={currentRowSelected}
          />
        )}
      </Card>
    </div>
  );
};

export default EmailLogs;
